import React, { useState } from 'react'
import { Header, Button, Transition } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

type CollapsibleSectionProps = {
    title: React.ReactNode
    subtitle: React.ReactNode
    defaultVisible?: boolean
}

export const CollapsibleSection: React.SFC<CollapsibleSectionProps> = ({children, title, subtitle, defaultVisible}) => {
    const [visible, setVisible] = useState(!!defaultVisible);
    return <>
    <Header dividing={visible} onClick={() => setVisible(!visible)}>
        {title}
        <Button type="button" basic size="tiny" floated="right" compact onClick={() => setVisible(!visible)}>
        {visible ? <FormattedMessage id="action.hide" /> : <FormattedMessage id="action.expand" />}
        </Button>
        <Header.Subheader>{subtitle}</Header.Subheader>
    </Header>
    <Transition visible={visible} animation='fade down' duration={350}>
        <div>
            {children}
        </div>
    </Transition>
    </>
}