import React, { useState } from 'react'
import { Segment, Button, Icon, Label, Divider, Message, Header, Transition } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard'
import Alert from 'react-s-alert';
import './IframeCodeDisplay.css'
import { Camera } from '../../../api/players';
import { buildPlayerUrl } from '../../../configuration'

export const getAllowFullscreen = (fullscreen: boolean) => fullscreen ? `
    allowfullscreen webkitallowfullscreen mozallowfullscreen` : ""

function urlForCamera(path?: string) {
    return buildPlayerUrl(`/${path}`)
}

export const generateIframe = ({ id, pathAlias, allowFullscreen }: Camera) => `
<iframe
    src="${urlForCamera(pathAlias || id)}"
    style="border: none; width: 100%; height: 100%;"${getAllowFullscreen(allowFullscreen)}
>
</iframe>
`.trim()


export const IframeCodeDisplay = ({ values, editing }: { values: Camera, editing: boolean }) => {
    const [visible, setVisible] = useState(true);
    const iframeText = generateIframe(values)

    return (
    <>
        <Header dividing={visible}>
            <FormattedMessage id="label.iframe" />
            {editing && <Button type="button" basic size="tiny" floated="right" compact onClick={() => setVisible(!visible)}>
                {visible ? <FormattedMessage id="action.hide" /> : <FormattedMessage id="action.expand" />}
            </Button>}
            <Header.Subheader><FormattedMessage id="label.iframeDescription" /></Header.Subheader>
        </Header>
        {!editing ? <Message info>
            <Icon name="info circle" />
            <FormattedMessage id="message.iframeCodeUnavailableUntilSaved" />
        </Message> :
        <Transition visible={visible} animation='fade down' duration={350}>
        <div>
            <CopyToClipboard
                text={iframeText}
                onCopy={() => Alert.info(<FormattedMessage id="message.iframeCodeCopiedToClipboard" />)}
            >
                <Button type="button" size="mini" compact basic>
                    <Icon name="copy" />
                    <FormattedMessage id="action.copy" />
                </Button>
            </CopyToClipboard>
            <Segment inverted >
                <Label attached='top right' color="blue">HTML</Label>
                <pre style={{userSelect: "all", overflow: "hidden"}}>
                    <code>
                        {iframeText}
                    </code>
                </pre>
            </Segment>
        </div>
        </Transition>}
        <Divider hidden />
    </>
    )
}