
import React, { useState, useEffect, ReactNode } from 'react';

export const DelayChildren: React.SFC<{delay: number}> = ({children, delay}) => {
    const [ finalChildren, setChildren ] = useState<ReactNode>(null)
    useEffect(() => {
        const timer = setTimeout(() => {
            setChildren(children)
        }, delay);
        return () => clearTimeout(timer);
    }, [delay, children, setChildren]);

  return <>{finalChildren || null}</>
};