import * as React from 'react'
import { Label, Icon } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'

export const ValidationMessage: React.SFC<{error?: string}> = ({error}) => {
    if(!error) return null
    return <Label basic className="error" style={{marginTop: ".4em", marginLeft: 0}}>
        <Icon name="exclamation circle" />
        <FormattedMessage id={error} />
    </Label>
}