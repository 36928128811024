import * as React from 'react'
import { Divider, Button, Icon, Card, Dropdown, Label } from 'semantic-ui-react'
import { FormattedMessage as FM } from 'react-intl'
import { NoCamerasPlaceholder } from './NoCamerasPlaceholder'
import { Camera } from '../../api/players'
import { Link } from 'react-router-dom'
import { CameraItem } from './CameraItem';
import Alert from 'react-s-alert';
import { LoadingIndicator } from '../common/LoadingIndicator';
import GroupDataProvider from '../common/GroupsProvider'
import { Group } from '../../api/groups'
import { intersectionBy } from 'lodash'
import UserAccessCondition from '../auth/UserAccessCondition'
import { UserType } from '../../api/usersAuth'

type Props = {
    cameras: Camera[],
    isLoading: boolean
    fetchPlayersAction: () => void
}

const NewCameraButton: React.SFC = () => {
    return <Button as={Link} to="/players/new" color="green">
        <Icon name="tv" />
        <FM id="action.newCamera" />
    </Button>
}

const PlayerFilters: React.SFC<{groups: Group[], filters: Group[], setFilters: (groups: Group[]) => void}> =
    ({groups, setFilters, filters}) => {
        const selectableGroups = groups
            .filter(g => !filters.find(fg => g.id === fg.id))
    return <>
      {selectableGroups.length > 0 && <Dropdown
            text='Filtruj'
            icon='filter'
            floating
            labeled
            button
            className='icon'
    >
        <Dropdown.Menu>
        <Dropdown.Header>
            <FM id="label.group" />
        </Dropdown.Header>
        <Dropdown.Divider />
        {selectableGroups.map(option => <Dropdown.Item
                onClick={() => {
                    const newGroup = groups.find(g => g.id === option.id)!!;
                    if(!filters.find(f => f.id === newGroup.id)) {
                    setFilters([...filters, newGroup])
                    }
                }}
            >
                {option.name}
            </Dropdown.Item>)}
        </Dropdown.Menu>
    </Dropdown>}
    {filters.map(f => <Label basic>
        <Icon name="filter" />{" "}{f.name}
        <i aria-hidden="true" className="close icon" onClick={() => setFilters(filters.filter(ff => ff.id !== f.id))}></i>
    </Label>)}
    </>
}

type State = {
    filteredGroups: Group[]
}

const assignedToGroups = (groups: Group[]) => (player: Camera) => {
    if (groups.length === 0 ) {
        return true;
    }
    return intersectionBy(groups, player.groups, (g) => g.id).length > 0;
}

export class CameraList extends React.Component<Props, State> {

    state: State = {
        filteredGroups: []
    }

    componentDidMount() {
        try {
            this.props.fetchPlayersAction()
        } catch(err) {
            Alert.error(<FM id="message.playersFetchError" />)
        }
    }

    setFilters = (groups: Group[]) => {
        this.setState({
            filteredGroups: groups
        })
    }

    render() {
        const { cameras, isLoading } = this.props
        const { filteredGroups } = this.state;
        const displayableCameras = cameras.filter(assignedToGroups(filteredGroups))
        return (
            <>
                <UserAccessCondition showOnlyTo={UserType.Admin}>
                    <NewCameraButton />
                    <GroupDataProvider >
                        {(groups) => <>
                            <PlayerFilters
                                groups={groups}
                                setFilters={this.setFilters}
                                filters={filteredGroups}
                            />
                        </>}
                    </GroupDataProvider>
                </UserAccessCondition>
                <Divider hidden fitted />
                {isLoading && <LoadingIndicator />}
                {!isLoading && displayableCameras.length === 0 && <NoCamerasPlaceholder
                    cleanFilters={filteredGroups.length > 0 ? () => this.setState({filteredGroups: []}) : undefined}
                />}
                {!isLoading && displayableCameras.length > 0 && <>
                    <Card.Group>
                    {displayableCameras
                        .map(camera => (
                            <CameraItem
                                key={camera.id}
                                player={camera}
                            />
                    ))}
                    </Card.Group>
                    <Divider hidden />
                </>
                }
            </>
        )
    }
}