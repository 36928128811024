import * as React from 'react'
import { Form, Header, Button, Icon, Grid, Divider, Label } from 'semantic-ui-react'
import { Field, Formik, FormikProps, FormikErrors, getIn } from 'formik'
import Alert from 'react-s-alert';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import { FormattedMessage as FM } from 'react-intl';
import Axios from 'axios';
import { set } from 'lodash'
import querystring from 'querystring';
import { RouteComponentProps } from 'react-router';
import { PlayerDropdown } from '../common/PlayerDropdown';
import { DateTimeSelector } from '../form/DateTimeSelector';
import { checkbox } from '../form/Inputs'
import { LoadingIndicator } from '../common/LoadingIndicator';
import { ScheduledBroadcast } from '../../api/scheduledBroadcast';
import { ErrorMessage } from '../common/Error';

type ScheduledBroadcastFormValues = {
    id?: string
    player?: {
        id: string
    }
    recordStream: boolean
    startTime: Date
    disablePlayerOnEnd: boolean
    endTime: Date
    showScheduleInPlayer: boolean
    state?: string
}

const SaveFormButton: React.SFC<{isSubmitting: boolean}> = ({isSubmitting}) => {
    return <Button positive type="submit" loading={isSubmitting} disabled={isSubmitting}>
        <Icon name="save" />
        <FM id="action.save" />
    </Button>
}

function getInitialValues (playerIdFromQuery?: string): ScheduledBroadcastFormValues {
    const now = new Date()
    now.setSeconds(0);
    now.setMilliseconds(0);
    return {
        player: { id: playerIdFromQuery || "" },
        recordStream: false,
        disablePlayerOnEnd: true,
        startTime: moment(now).add(1, "hour").toDate(),
        endTime: moment(now).add(2, "hour").toDate(),
        showScheduleInPlayer: true
    }
}

function validateSchedule(values: ScheduledBroadcastFormValues) {
    const errors: FormikErrors<ScheduledBroadcastFormValues> = {};
    if(!values.player || !values.player.id || values.player.id === '') {
        set(errors, "player.id",  "validation.requiredField");
    }
    if(values.endTime.getTime() < values.startTime.getTime()) {
        errors.endTime = "validation.endTimeCannotBeEarlierThanStartTime";
    }

    return errors;
}

type State = {
    schedule?: ScheduledBroadcast
    loading: boolean
    error?: Error
}

export class ScheduledBroadcastEditForm extends React.Component<RouteComponentProps<{id: string}>, State> {
    state: State = {
        loading: true
    }
    async componentDidMount() {
        try {
            const response = await Axios.get(`/api/scheduled-broadcast/${this.props.match.params.id}`, {
                transformResponse: (res) => {
                    const  dateParser = (key: string, value: any) =>
                        key === "startTime" || key === "endTime" ? new Date(value) : value;
                    return JSON.parse(res, dateParser)
                }
            })
            this.setState({
                schedule: response.data
            })
        } catch(error) {
            this.setState({
                error
            })
        }
        this.setState({
            loading: false
        })
    }
    render() {
        if(this.state.loading) {
            return <LoadingIndicator />
        }
        if(this.state.error) {
            return <ErrorMessage error={this.state.error} messageId={"message.systemErrorFailedToLoadData"} />
        }
        return <ScheduledBroadcastForm {...this.props} schedule={this.state.schedule} />
    }
}

export const ScheduledBroadcastForm: React.SFC<RouteComponentProps & {schedule?: ScheduledBroadcast}>  = ({history, schedule}) => {
    var query = querystring.parse(window.location.search.substring(1));
    const playerIdQuery = query["playerId"];
    let playerIdFromQuery: string
    if(Array.isArray(playerIdQuery)) {
        playerIdFromQuery = playerIdQuery[0];
    } else {
        playerIdFromQuery = playerIdQuery;
    }
    return <>
        <Formik
            onSubmit={async (values, formikActions) => {
                try {
                    if(!values.id) {
                        await Axios.post<ScheduledBroadcast>("/api/scheduled-broadcast", values);
                    } else {
                        await Axios.put<ScheduledBroadcast>(`/api/scheduled-broadcast/${values.id}`, values);
                    }
                    if(playerIdFromQuery) {
                        history.push(`/players/${playerIdFromQuery}/dashboard`)
                    } else {
                        history.push("/schedules")
                    }
                } catch(error) {
                    if(getIn(error, "response.status") === 406) {
                        Alert.warning(<FM id="message.scheduledBroadcastCreateConflict" />)
                    } else {
                        Alert.error(<FM id="message.systemErrorActionFailed" />)
                    }
                }
                formikActions.setSubmitting(false);
            }}
            initialValues={schedule as ScheduledBroadcastFormValues || getInitialValues(playerIdFromQuery)}
            validate={validateSchedule}
            render={({values, setFieldValue, handleSubmit, isSubmitting, errors, touched, setTouched }: FormikProps<ScheduledBroadcastFormValues>) => {
            return <>
            <Header>
                <FM id="title.newScheduledBroadcast" />
            </Header>
            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <PlayerDropdown
                        name="player.id"
                        setFieldValue={setFieldValue}
                        selectDefault
                        disabled={!!playerIdFromQuery || (!!values.id && values.state === 'started')}
                        player={values.player}
                    />
                </Form.Field>
                <Grid stackable style={{marginBottom: 0}}>
                    <Grid.Column width={8}>
                <Form.Field>
                    <label><FM id="label.broadcastStart" /></label>
                    {values.state !== 'started' && <DateTimeSelector
                        value={values.startTime}
                        hasEndTime
                        disabled={!!values.id && values.state === 'started'}
                        setValue={(startTime) => {
                            setFieldValue("startTime", startTime)
                            if(startTime.getTime() >= values.endTime.getTime()) {
                                setFieldValue("endTime", moment(startTime).add(2, "hour").toDate())
                            }
                            setTouched({startTime: true, endTime: true})
                        }}
                        error={getIn(touched, "startTime") && getIn(errors, "startTime")}
                    />}
                    {values.state === 'started' && <Label basic style={{marginTop: ".4em", marginLeft: 0, fontWeight: "normal"}} >
                        <Icon name="clock outline" />
                        <FM id="label.scheduleAlreadyStarted" />
                    </Label>}
                </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form.Field>
                        <label>
                            <FM id="label.broadcastEnd" />
                        </label>
                        <DateTimeSelector
                            value={values.endTime}
                            setValue={(value) => {
                                setFieldValue("endTime", value)
                                setTouched({startTime: true, endTime: true})
                            }}
                            disabledDays={{
                                before: values.startTime
                            }}
                            error={getIn(touched, "endTime") && getIn(errors, "endTime")}
                        />
                    </Form.Field>
                </Grid.Column>
                </Grid>
                <Field
                    component={checkbox}
                    name="disablePlayerOnEnd"
                    tooltip={values.disablePlayerOnEnd ? <FM id="message.allPlayerClientsWillBeDisconnected" /> : undefined}
                />
                <Field
                    component={checkbox}
                    name="recordStream"
                    tooltip={values.recordStream ?
                        <FM id="message.recordingWillBePausedAfterExceedingQuota" /> :
                        undefined
                    }
                />
                {values.state !== 'started' && <Field
                    component={checkbox}
                    name="showScheduleInPlayer"
                />}
                <Divider />
                <SaveFormButton isSubmitting={isSubmitting} />
            </Form>
            </>}}
        />
    </>
}