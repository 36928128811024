import Axios from "axios";

export enum RecorderStatus {
    InProgress = "InProgress",
    WaitingForStream = "WaitingForStream",
    NotRecording = "NotRecording",
    Unknown = "Unknown"
}
export type RecorderStatusResponse = {
    status: RecorderStatus
    bytesInRate: number
    currentFileSize: number
    duration: number
    recordingStartTime: string
    quota: {
        quotaAvailableBytes: number
        quotaUsedBytesInProgess: number
        quotaUsedBytes: number
        recordingsDeleteDaysThreshold: number
    }
}

export type RecordingsResponse = {
    recordingsDeleteDaysThreshold: number,
    recordings: {
        fileGroupId: string,
        sizeSum: number,
        durationSecondsSum: number,
        files: {
            partId: string,
            durationSeconds: number,
            size: number,
            creationTime: string,
            fileNameWithoutPrefix: string
        }[]
    }[]
}

export const startRecording = (streamId: string) => Axios.post(`/api/streams/${streamId}/recorder-start`)
export const stopRecording = (streamId: string) => Axios.post(`/api/streams/${streamId}/recorder-stop`)

export const recorderStatus = (streamId: string) => Axios.get<RecorderStatusResponse>(`/api/streams/${streamId}/recorder-status`)

export const recordingsList = (streamId: string) => Axios.get<RecordingsResponse>(`/api/streams/${streamId}/recordings-list`)

export const deleteRecording = (streamId: string, recordingFileName: string) => Axios.delete(`/api/streams/${streamId}/recording/${recordingFileName}`)