import * as React from 'react'
import { FormattedMessage } from 'react-intl';
import { Header, Message, Icon, Button } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

export const NotFound: React.SFC = () => <>
<Header><FormattedMessage id="title.pageNotFound" /></Header>
<Message>
    <Icon name="info circle"/>
    <FormattedMessage id="message.pageNotFound" />
</Message>
<NavLink to="/">
    <Button
        color="blue"
        icon="reply"
        content={<FormattedMessage id="action.goBackToMainPage" />}
    />
</NavLink>
</>
