import * as React from 'react'
import { connect } from 'react-redux'
import CameraForm from './CameraForm';
import { createCameraAction, fetchPlayersAction, modifyCameraAction } from '../../../actions/players'
import { Camera, getPlayerInitialValues } from '../../../api/players';
import { RouteComponentProps, Redirect } from 'react-router';
import { ApplicationState } from '../../../reducers';
import { fetchStreamsAction } from '../../../actions/stream';
import { fetchVodsAction } from '../../../actions/vods';
import { LoadingIndicator } from '../../common/LoadingIndicator';
import { Stream } from '../../../api/streams';
import { UserType } from '../../../api/usersAuth';
import { Message, Icon, Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

type Props = {
    fetchPlayers: () => void
    fetchStreams: () => void
    fetchVods: () => void
    createCamera: (camera: Camera) => void
    modifyCamera: (camera: Camera) => void
    camera?: Camera
    streams: Stream[]
    isLoading: boolean
    user: { type: UserType } | null
} & RouteComponentProps<{id?: string}>

type ErrorType = "ACCESS_ERROR"

type State = {
    isLoading: boolean
    initialValues?: Camera
    error?: ErrorType
}

class CameraFormComponent extends React.Component<Props, State> {
    state: State = {
        isLoading: true
    }

    createCamera = async (camera: Camera) => {
        // ...
        const cameraResponse =  await this.props.createCamera(camera) as unknown as Camera
        if (cameraResponse.id) {
            this.props.history.push(`/players`)
        }
    }

    modifyPlayer = async (camera: Camera) => {
        await this.props.modifyCamera(camera)
        this.props.history.push(`/players`)
    }
    async componentDidMount() {
        this.props.fetchStreams()
        this.props.fetchVods()
        if (this.props.match.params.id) {
            //TODO Should only fetch player by id.
            try {
                this.props.fetchPlayers()
            } catch(err) {

            }
            this.setState({
                isLoading: false
            })
        } else {
            try {
                const initialValues = await getPlayerInitialValues()
                this.setState({
                    isLoading: false,
                    error: undefined,
                    initialValues
                })
            } catch(error) {
                if(error.response.status === 403) {
                    this.setState({
                        isLoading: false,
                        error: "ACCESS_ERROR"
                    })
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            }
        }
    }
    render() {
        if(this.props.isLoading || this.state.isLoading) {
            return <LoadingIndicator />
        }

        if(this.props.match.params.id && !this.props.camera) {
            return <Redirect to="/404" />
        }

        const userType = this.props.user && this.props.user.type

        if (userType !== UserType.Admin && this.state.error === "ACCESS_ERROR") {
            return <><Message error>
                <Message.Header>
                <Icon name="minus circle" />
                <FormattedMessage id="message.playerAccessError" />
                </Message.Header>
                <Message.Content>
                    <FormattedMessage id="message.noStreamsDefined" />
                </Message.Content>
            </Message>
            <NavLink to="/">
                <Button
                    color="blue"
                    icon="reply"
                    content={<FormattedMessage id="action.goBackToMainPage" />}
                />
            </NavLink>
            </>
        }

        return (<CameraForm
            createCamera={this.createCamera}
            modifyCamera={this.modifyPlayer}
            camera={this.props.camera}
            initialValues={this.state.initialValues}
        />)
    }
}
function mapStateToProps(state: ApplicationState, ownProps: Props) {
    const id = ownProps.match.params.id
    return {
        isLoading: !state.streams.initiallyLoaded || state.players.isLoading || state.streams.isLoading || !state.vods.initiallyLoaded || state.vods.isLoading,
        camera: id ? state.players.data.find(c => c.id === id) : undefined,
        streams: state.streams.data,
        user: state.auth.data
    }
}
export default connect(mapStateToProps, {
    createCamera: createCameraAction,
    modifyCamera: modifyCameraAction,
    fetchPlayers: fetchPlayersAction,
    fetchStreams: fetchStreamsAction,
    fetchVods: fetchVodsAction
})(CameraFormComponent)