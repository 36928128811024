import * as React from 'react'
import { Switch, Route, Redirect } from "react-router";
import { Container } from 'semantic-ui-react';

import { Heading } from "./Heading"
import CameraFormComponent from './players/form/CameraFormComponent';
import UnauthorizedInterceptor from '../auth/UnauthorizedInterceptor';
import { NotFound } from './NotFound';
import StreamFormComponent from './streams/StreamFormComponent';
import UserFormComponent from './users/UserFormComponent';
import StreamRecordingComponent from './streams/StreamRecordingComponent';
import { userIsAdminRedir } from '../auth/utils';
import { ScheduledBroadcastForm, ScheduledBroadcastEditForm } from './schedules/ScheduledBroadcastForm';
import { ConfigurationComponent } from './ConfigurationComponent';
import PlayerDashboardComponent from './players/PlayerDashboardComponent';
import { StreamStatusRoute } from './streams/StreamStatusRoute';
import { ScheduledBroadcastListPage } from './schedules/ScheduledBroadcastListPage';
import GroupFormComponent from './groups/GroupFormComponent';
import { AdministrationComponent } from './AdministrationComponent';

export const Main = () => {
    return (
        <>
        <UnauthorizedInterceptor />
        <Heading />
        <Container style={{ paddingTop: '4.2em' }}>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/players" />
                </Route>
                {/** FIXME react-auth-wrapper type definition */}
                <Route exact path="/users" component={userIsAdminRedir(AdministrationComponent as any)} />
                <Route exact path="/groups" component={userIsAdminRedir(AdministrationComponent as any)} />
                <Route exact path="/groups/new" component={userIsAdminRedir(GroupFormComponent as any)} />
                <Route exact path="/groups/:id" component={userIsAdminRedir(GroupFormComponent as any)} />
                <Route exact path="/streams/new" component={userIsAdminRedir(StreamFormComponent as any)} />
                <Route exact path="/users/new" component={userIsAdminRedir(UserFormComponent as any)} />
                <Route exact path="/users/:id" component={userIsAdminRedir(UserFormComponent as any)} />
                <Route exact path="/players" component={ConfigurationComponent} />
                <Route exact path="/players/new" component={CameraFormComponent} />
                <Route exact path="/players/:id" component={userIsAdminRedir(CameraFormComponent as any)} />
                <Route exact path="/players/:id/dashboard" component={PlayerDashboardComponent} />
                <Route exact path="/media" component={ConfigurationComponent} />
                <Route exact path="/streams" component={ConfigurationComponent} />
                <Route exact path="/streams/:id/recording" component={StreamRecordingComponent} />
                <Route exact path="/streams/:id" component={StreamFormComponent} />
                <Route exact path="/streams/:id/status" component={StreamStatusRoute} />
                <Route exact path="/vod" component={ConfigurationComponent} />
                <Route exact path="/schedules" component={ScheduledBroadcastListPage} />
                <Route exact path="/schedules/new" component={ScheduledBroadcastForm} />
                <Route exact path="/schedules/:id" component={ScheduledBroadcastEditForm} />
                <Route path="/404" component={NotFound} />
                <Route>
                    <Redirect to="/404" />
                </Route>
            </Switch>
        </Container>
        </>
    )
}