import * as React from 'react'

import { Icon, Form, Header, Button, Segment, Image, Divider, Message } from "semantic-ui-react";
import { Field, FieldArray, ArrayHelpers } from 'formik';
import { checkbox, textInput, dropdown, inlineDropdown } from '../../form/Inputs';
import { Camera, PlayerSecurityType } from '../../../api/players';
import { FormattedMessage as FM } from 'react-intl';

import { NoImageSelected } from '../../images/NoImageSelected';
import { ImageWithPlaceholder } from '../../images/ImageWithPlaceholder';
import { ImageChooser } from '../../images/ImageChooser';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { Stream } from '../../../api/streams';
import LoggedInUserExtractor from '../../auth/LoggedInUserExtractor';
import { UserType } from '../../../api/usersAuth';

import blackStripsImage from './player_black_strips.jpg'
import fillImage from './player_fill.jpg'
import UserAccessCondition from '../../auth/UserAccessCondition';
import { PlayerAccessesModal } from '../PlayerAccessesModal';
import { PlayerAccessesTokensModal } from '../PlayerAccessesTokensModal';
import { Vod } from '../../../api/vods';

type StateProps = {
    streams: Stream[]
    vods: Vod[]
}

type PlaybackPaneProps = {
    accessModalOpen: boolean
    values: Camera,
    setFieldValue: (fieldName: string, fieldValue: any) => void
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        streams: state.streams.data,
        vods: state.vods.data
    }
}

const PlaybackPane: React.SFC<PlaybackPaneProps & StateProps> = ({values, setFieldValue, streams, vods, accessModalOpen}) => {
    const streamOptions = streams.map(s => ({
        value: s.id,
        text: <>{s.outputSecurity && <Icon style={{marginRight: "0.1em"}}name="lock" />} {s.name}</>
    }));

    const vodOptions = vods.map(v => ({
        value: v.id,
        text: <><Icon name="film" /> {v.name}</>
    }));

    const newPlayerSource = () => {
        if (streams.length > 0) {
            return {
                type: "stream",
                streamId: streams[0].id,
                progressControls: false,
                // useCachingServers should not be possible to set on secured stream (and does not work)
                useCachingServers: true
            };
        } else if (vods.length > 0) {
            return {
                type: "vod",
                vodId: vods[0].id,
                progressControls: true
            };
        } else {
            return {
                type: "custom-url",
                hlsStreamUrl: "",
                progressControls: false,
                useCachingServers: true
            };
        }
    }

    const playerSourceTypeOptions = [
        {
            text: <FM id="label.playerSourceType.stream" />,
            value: 'stream',
            disabled: streams.length === 0
        },
        {
            text: <FM id="label.playerSourceType.vod" />,
            value: 'vod',
            disabled: vods.length === 0
        },

        {
            text: <FM id="label.playerSourceType.custom-url" />,
            value: 'custom-url'
        }
    ]

    return (
    <>
        <Header dividing>
            <FM id="label.playbackSettings" />
        </Header>
        <FieldArray
            name="sources"
            render={(arrayHelpers: ArrayHelpers) => {
                return <>
                 {/** Disabled until we support more sources in player.
                      Enabled when zero for backwards-compatibility*/}
                    {values.sources.length === 0 && <Form.Field>
                        <Button
                            type="button"
                            size="mini"
                            positive
                            onClick={() => arrayHelpers.push(newPlayerSource())}
                        >
                            <Icon name="plus" />
                            <FM id="action.addNewPlayerSource" />
                        </Button>
                    </Form.Field>}
                    <Segment.Group>
                    {values.sources.map((s, index) => {
                        return <Segment key={index}>
                        <Form.Field>
                            <FM
                                id="label.sourceOfType"
                                values={{sourceIndex: index+1}}
                            />{" "}
                            <LoggedInUserExtractor>
                            {user =>
                            <Field
                                name={`sources[${index}].type`}
                                component={inlineDropdown}
                                options={user.type === UserType.Admin ?
                                    playerSourceTypeOptions :
                                    playerSourceTypeOptions.filter(p => p.value !== "custom-url")
                                }
                                onChange={(newValue: string) => {
                                    if (newValue === "stream") {
                                        setFieldValue(`sources[${index}].streamId`, streams[0].id)
                                        setFieldValue(`sources[${index}].vodId`, undefined)
                                        setFieldValue(`sources[${index}].progressControls`, false)
                                    } else if (newValue === "vod") {
                                        setFieldValue(`sources[${index}].vodId`, vods[0].id)
                                        setFieldValue(`sources[${index}].streamId`, undefined)
                                        setFieldValue(`sources[${index}].progressControls`, true)
                                    } else {
                                        setFieldValue(`sources[${index}].name`, "")
                                        setFieldValue(`sources[${index}].hlsStreamUrl`, "")
                                    }
                                }}
                            />}
                            </LoggedInUserExtractor>
                            {values.sources.length > 1 &&
                            <Button
                                negative
                                basic
                                compact
                                floated="right"
                                size="mini"
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                            >
                                <Icon name="remove" />
                                <FM id="action.removeSource" />
                            </Button>}
                        </Form.Field>
                        {s.type === "stream" &&
                        <Form.Field>
                        <Field
                            label="stream"
                            name={`sources[${index}].streamId`}
                            component={dropdown}
                            options={streamOptions}
                        />
                         </Form.Field>
                        }
                       {s.type === "vod" &&
                        <Form.Field>
                        <Field
                            label="vod"
                            name={`sources[${index}].vodId`}
                            component={dropdown}
                            options={vodOptions}
                        />
                         </Form.Field>
                        }
                        {s.type === "custom-url" && <>
                        <Field
                            label="source.name"
                            name={`sources[${index}].name`}
                            component={textInput}
                        />
                        <Field
                            label="source.hlsStreamUrl"
                            name={`sources[${index}].hlsStreamUrl`}
                            component={textInput}
                        />
                        <Field
                            label="source.progressControls"
                            name={`sources[${index}].progressControls`}
                            component={checkbox}
                        />
                        </>
                    }
                    {s.type === 'custom-url' && <>
                            <Field
                                label="source.useCachingServers"
                                name={`sources[${index}].useCachingServers`}
                                component={checkbox}
                                tooltip={<FM id="message.cachingServersInfo"  />}
                            />
                    </>}
                    </Segment>
                    })}
                    </Segment.Group>
                </>
            }}
        />
        <Field
            label={values.enabled ? "enabledPlayer" : "disabledPlayer"}
            name="enabled"
            component={checkbox}
        />
        <UserAccessCondition showOnlyTo={UserType.Admin}>
        <>
        <Field
                name="securityType"
                component={dropdown}
                options={[{
                    value: PlayerSecurityType.None,
                    text: "Bez zabezpieczeń"
                },
                {
                    value: PlayerSecurityType.Password,
                    text: "Użytkownik i hasło"
                },
                {
                    value: PlayerSecurityType.Token,
                    text: "Tokeny"
                }]}
            />
            {values.securityType !== PlayerSecurityType.None && !values.id && <>
                <Message info>
                    <Icon name="info circle"/>
                    <FM id="message.savePlayerToDefineAccesses" />
                </Message>
            </>}
            {values.securityType === PlayerSecurityType.Password && values.id && <Form.Field>
                <PlayerAccessesModal
                    modalOpen={accessModalOpen}
                    playerId={values.id}
                />
            </Form.Field>}
            {values.securityType === PlayerSecurityType.Token && values.id && <Form.Field>
                <PlayerAccessesTokensModal
                    modalOpen={accessModalOpen}
                    playerId={values.id}
                />
            </Form.Field>}
            </>
        </UserAccessCondition>
        <Form.Field>
            <label><FM id="label.field.disabledPlayerImageUrl" /></label>
            {!values.disabledPlayerImageUrl && <NoImageSelected />}
            {values.disabledPlayerImageUrl && <ImageWithPlaceholder
                src={values.disabledPlayerImageUrl}
                className="ui small left aligned image bordered mb"
            />}
            <ImageChooser
                onImageChoose={image => setFieldValue("disabledPlayerImageUrl", image.fileUrl)}
            />
            {values.disabledPlayerImageUrl && <>
            <FM id="label.or" />{" "}
            <div className="ui mini red basic icon button" onClick={() => setFieldValue("disabledPlayerImageUrl", null)}>
                <Icon name="x" /><FM id="action.removeImage" />
            </div>
            </>}
        </Form.Field>
        <div className="ui stackable grid">
            <div className="eight wide column">
            <Field
                name="videoFill"
                component={dropdown}
                options={[{
                    value: "fill",
                    text: "Wypełnij"
                },
                {
                    value: "black-strips",
                    text: "Czarne pasy"
                }]}
            />
            <Field
                name="autoplay"
                component={checkbox}
                tooltip={<FM id="message.autoplayInfo"  />}
            />
            <Field
                name="allowFullscreen"
                component={checkbox}
            />
            <Field
                name="hasSound"
                component={checkbox}
                warning={values.autoplay && values.hasSound && <FM id="message.soundMutedWhenAutoplay" />}
            />
            </div>
            <div className="eight wide column mobile-hide">
                <Divider hidden fitted />
                <p><FM id={`label.field.videoFill.${values.videoFill}`} /></p>
                {values.videoFill==='black-strips' && <Image src={blackStripsImage} bordered />}
                {values.videoFill === 'fill' && <Image src={fillImage} bordered  />}
            </div>
        </div>
    </>
)}

export const PlaybackPaneComponent = connect(mapStateToProps)(PlaybackPane)