import React from 'react'
import Axios from 'axios';
import { UserAuth } from '../../api/usersAuth';
import { Field } from 'formik';
import { dropdown } from '../form/Inputs';
import { FormattedMessage } from 'react-intl';

type Props = {
    name: string
}

type State = {
    users: UserAuth[],
    loading: boolean
}

export class OperatorDropdown extends React.Component<Props, State> {
    state: State = {
        users: [],
        loading: true
    }

    async componentDidMount() {
        try {
            const response = await Axios.get("/api/users")
            this.setState({
                users: response.data, loading: false
            })
        } catch(error) {
            
        }
        this.setState({
            loading: false
        })
    }
    render() {
        const options = [
            ...this.state.users.map(u => ({
            text: u.username,
            value: u.id
        }))]

        return <Field
            placeholder={<FormattedMessage id="label.noOperator"/>}
            name={this.props.name}
            options={options}
            component={dropdown}
            clearable
            loading={this.state.loading}
        />
    }
}