import Axios from "axios";
import querystring from 'querystring'

export enum UserType {
	Admin = 'admin',
	User = 'user',
}

export type UserAuth = {
    id?: string
    username: string
    password?: string
    type: UserType
}

export const loginUser = async (username: string, password: string) => {
    return await Axios.post<UserAuth>("/api/login", querystring.stringify({
        username, password
    }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export const getLoggedInUser = async () => {
    return Axios.get<UserAuth>("/api/user").then(response => response.data)
}

export const logoutUser = async () => {
    return Axios.get("/api/logout")
}