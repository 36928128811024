import Axios from 'axios'
import { UserAuth } from './usersAuth'
import { Group } from './groups'

export type Widget = {
    id?: string
} & AnyWidget

type AnyWidget = ImageWidget | HtmlWidget | PopupWidget

export type ImageWidget = {
    type: "image"
    imageUrl: string
    position: "top-right" | "top-left" | "bottom-right" | "bottom-left"
    imageSize: "tiny" | "small" | "medium"
    hasLink: boolean
    linkUrl?: string
    linkTitle?: string
}

type HtmlWidget = {
    type: "link"
    url: string
    title: string
}

type PopupWidget = {
    type: "popup"
    text: string
}

export type PlayerSource = {
    type: "custom-url"
    name: string
    hlsStreamUrl: string
    progressControls: boolean
    useCachingServers: boolean
} | {
    type: "stream"
    streamId: string
    progressControls: boolean
    useCachingServers: boolean
} | {
    type: "vod"
    vodId: string
    progressControls: boolean
}

export enum PlayerSecurityType {
    Password = "password",
    Token = "token",
    None = "none"
}

export type Camera = {
    id?: string
    title: string
    description: string
    showHeader: boolean
    enabled: boolean
    securityType: PlayerSecurityType;
    autoplay: boolean
    pathAlias?: string
    sources: PlayerSource[]
    thumbnailUrl?: string
    disabledPlayerImageUrl?: string
    locationEnabled?: string
    locationName?: string
    longitude?: number
    latitude?: number
    videoFill: "fill" | "black-strips"
    allowFullscreen: boolean
    hasSound: boolean
    widgets: Widget[]
    createdBy?: string
    created?: string
    modifiedBy?: string
    modified?: string
    operator?: UserAuth
    groups: Group[]
};

export const fetchCameras = async () => {
    const response = await Axios.get<Camera[]>("/api/camera")
    return response.data;
}

export const fetchPlayer = async (id: string) => {
    const response = await Axios.get<Camera>(`/api/camera/${id}`)
    return response.data
}

export const createCamera = async (camera: Camera) => {
    const response = await Axios.post<Camera>("/api/camera", camera)
    return response.data
}

export const modifyCamera = async (camera: Camera) => {
    const response = await Axios.put<Camera>(`/api/camera/${camera.id}`, camera)
    return response.data
}

export const deleteCamera = async (id: string) => {
    const response =  await Axios.delete(`/api/camera/${id}`)
    return  response.status === 204
}

export const getPlayerInitialValues = async () => {
    const response = await Axios.get<Camera>("/api/player/form-initial-values")
    return response.data
}

export const getPlayerByAlias = async (alias: string) => {
    const response = await Axios.get<Camera>("/api/player-by-alias/" + alias)
    return response.data;
}