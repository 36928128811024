import * as React from 'react'
import { Button, Icon, Divider, Table } from 'semantic-ui-react';
import { FormattedMessage as FM } from 'react-intl';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { UserAuth, UserType } from '../../api/usersAuth';
import Alert from 'react-s-alert';
import { LoadingIndicator } from '../common/LoadingIndicator';

type Props = {
}

type State = {
	users: UserAuth[],
	loading: boolean,
	error: boolean
}

const NewUserButton: React.SFC = () => {
	return <Button as={Link} to="/users/new" color="green">
		<Icon name="user" />
		<FM id="action.newUser" />
	</Button>
}

export class UsersList extends React.Component<Props, State> {
	state: State = {
		loading: true,
		users: [],
		error: false
	}
	async componentDidMount() {
		try {
			const response = await Axios.get<UserAuth[]>("/api/users")
			this.setState({
				users: response.data,
				loading: false,
				error: false
			})
		} catch(err) {
			this.setState({
				loading: false,
				error: true
			})
			Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
		}
	}
	render() {
		return (
			<>
				<NewUserButton />
				{this.state.loading && <LoadingIndicator />}
				{!this.state.loading && <>
				<Table striped className="very compact">
				<Table.Header>
						<Table.Row>
						<Table.HeaderCell>
							<FM id="label.username" />
						</Table.HeaderCell>
						<Table.HeaderCell>
							<FM id="label.field.userAuth.type" />
						</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
					{this.state.users.map((user, i)=> <Table.Row key={i}>
						<Table.Cell>
							<Link to={`/users/${user.id}`}>
								{user.username}
							</Link>
						</Table.Cell>
						<Table.Cell>
							{user.type === UserType.Admin ? <Icon name="key" /> : <Icon name="shield" />}
							<FM id={`label.userType.${user.type}`} />
						</Table.Cell>
						<Table.Cell textAlign="right">
							<Button as={Link} to={`/users/${user.id}`} size="mini" primary>
								<Icon name="pencil" />
								<FM id="action.edit" />
							</Button>
						</Table.Cell>
					</Table.Row>)}
					</Table.Body>
				</Table>
				<Divider hidden />
				</>}
			</>
		)
	}
}
