import { Camera } from "../api/players";
import { PlayersActionType } from "../actions/players";

export type PlayersState = {
    data: Camera[]
    isLoading: boolean
}

const initialState: PlayersState = {
    data: [],
    isLoading: false
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PlayersActionType.fetchRequest:
            return {
                ...state,
                isLoading: true
            }
        case PlayersActionType.fetchError:
            return {
                ...state,
                isLoading: false
            }
        case PlayersActionType.fetchSuccess:
            return {
                ...state,
                data: action.cameras,
                isLoading: false
            }
        case PlayersActionType.deleteSuccess:
            return {
                ...state,
                data: state.data.filter(c => c.id !== action.id)
            }
        case PlayersActionType.createSuccess:
            return {
                ...state,
                data: [
                    action.camera,
                    ...state.data
                ]
            }
        case PlayersActionType.modifySuccess: 
            return {
                ...state,
                data: [
                    action.camera,
                    ...state.data.filter(p => p.id !== action.camera.id)
                ]
            }
        case PlayersActionType.fetchSingleSuccess:
            const found = state.data.find(s => s.id === action.camera.id)
            return {
                ...state,
                data: found ? state.data.map(camera => {
                    if(camera.id === action.camera.id) {
                        return action.camera
                    } else {
                        return camera
                    }
                }) : [action.camera, ...state.data]
            }
        case PlayersActionType.disableSuccess:
        case PlayersActionType.enableSuccess:
                return {
                    ...state,
                    data: state.data.map(camera => {
                        if(camera.id === action.id) {
                            return {
                                ...camera,
                                enabled: action.type === PlayersActionType.enableSuccess
                            }
                        } else {
                            return camera
                        }
                    })
                }
      default:
        return state
    }
  }

export default reducer