import * as React from 'react'

import { Button, Modal, Icon, Form, Divider, Segment, Header, Grid, List, Message } from 'semantic-ui-react';
import { FormattedMessage as FM } from 'react-intl';
import { Field, Formik, FormikErrors } from 'formik';
import { textInput, PasswordShowHideInput } from '../form/Inputs';
import Axios from 'axios';
import Alert from 'react-s-alert';
import { AsyncActionCaller } from '../common/AsyncActionCaller';
import { LoadingIndicator } from '../common/LoadingIndicator';

type Props = {
    playerId: string
    modalOpen: boolean
}

type PlayerAccess = {
  id: string
  username: string
}

type PlayerAccessFormValue = {
  id?: string
  username: string
  password?: string
}

type State = {
    loading: boolean
    playerAccesses: PlayerAccess[]
    playerAccessEditing?: PlayerAccess
}

function validatePlayerAccess(values: PlayerAccessFormValue) {
  const errors: FormikErrors<PlayerAccessFormValue> = {};
  if (!values.username || values.username.length < 4) {
    errors.username = "validation.atLeast4Characters"
  }
  if(!values.id || values.password) {
    if (!values.password || values.password.length < 8) {
      errors.password = "validation.passwordAtLeast8Characters"
    }
  }
  return errors;
}

const initialValues: PlayerAccessFormValue = {
  username: "",
  password: ""
}

type PlayerAccessFormProps = {
  savePlayerAccess: (playerAccess: PlayerAccessFormValue) => Promise<void>
  playerAccess?: PlayerAccessFormValue
  resetForm: () => void
}

const PlayerAccessForm: React.SFC<PlayerAccessFormProps> =
  ({ savePlayerAccess, playerAccess, resetForm }) => {
  return <>
  <Formik
  initialValues={playerAccess || initialValues}
  validate={validatePlayerAccess}
  enableReinitialize
  onSubmit={async (values, actions) => {
    actions.setSubmitting(true)
    try {
      await savePlayerAccess(values)
      Alert.success(<FM id="message.playerAccessAddSuccess" />)
      actions.resetForm();
    } catch (error) {
      if(error.response && error.response.status === 409){ 
        Alert.error(<FM id="message.playerAccessDuplicateUsername" />)
      } else {
        Alert.error(<FM id="message.systemErrorActionFailed" />)
      }
    }
    actions.setSubmitting(false)
  }}
  >
    {({handleSubmit, values, submitForm, isSubmitting}) =>
    <Form onSubmit={handleSubmit}>
  <Grid columns={3} stackable>
    <Grid.Row>
      <Grid.Column>
      <Form.Field>
          <Field
            name="username"
            component={textInput}
          />
      </Form.Field>
    </Grid.Column>
    <Grid.Column>
    <Form.Field>
        <Field
          name="password"
          component={PasswordShowHideInput}
          tooltip={values.id ? <FM id="label.leavePasswordEmptyToUseOldPassword" /> : undefined}
        />
    </Form.Field>
    </Grid.Column>
    <Grid.Column>
    <Form.Field>
      <Button color="green" style={{marginTop: "1.65em"}} type="button" onClick={submitForm} loading={isSubmitting}>
        <Icon name="plus" />
        {values.id ? 
        <FM id="action.editAccessData" />:
      <FM id="action.addAccessData" /> }
      </Button>
      {values.id && <Button
        size="mini"
        icon="x"
        onClick={resetForm}
        basic
        title={"Wyczyść formularz"}
      >
        {/* <Icon name="x" /> */}
      </Button>}
    </Form.Field>
    </Grid.Column>
    </Grid.Row>
  </Grid>
  </Form>}
  </Formik>
  </>
}

export const PlayerAccessesModal: React.SFC<Props> = ({playerId, modalOpen}) => {
  const [open, setOpen] = React.useState(modalOpen);
  return <>
        <Button type="button" onClick={() => setOpen(true)} basic size="tiny">
            <Icon name="address card" />
            <FM id="label.accessList" />
        </Button>
          <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
          >
            <Modal.Header><FM id="label.accessList" /></Modal.Header>
            <Modal.Content>
                <PlayerAccesses playerId={playerId} />
            </Modal.Content>
          </Modal>
  </>
}

export class PlayerAccesses extends React.Component<{playerId: string}, State> {
    state: State = {
      loading: true,
      playerAccesses: [],
      playerAccessEditing: undefined
    }

    async componentDidMount() {
      try {
        const response = await Axios.get<{username: string, id: string}[]>(`/api/player/${this.props.playerId}/access`)
        this.setState({
          playerAccesses: response.data,
          loading: false
        })
      } catch(err) { 
        Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
        this.setState({
          loading: false
        })
      }

    }

    async addPlayerAccess(values: PlayerAccessFormValue) {
      if(!values.id) {
        const response = await Axios.post<PlayerAccess>(`/api/player/${this.props.playerId}/access`, values);
        this.setState({
          playerAccesses: [
            ...this.state.playerAccesses,
            response.data
          ]
        })
      } else {
        await Axios.put<PlayerAccess>(`/api/player/${this.props.playerId}/access/${values.id}`, values);
        this.setState({
          playerAccesses: this.state.playerAccesses.map(pA => {
            if(pA.id === values.id) {
              return {
                id: values.id,
                username: values.username
              }
            } else {
              return pA
            }
          })
        })
      }
      this.setState({
        playerAccessEditing: undefined
      })

    }

    async deletePlayerAccess(id: string) {
      try {
      await Axios.delete(`/api/player/${this.props.playerId}/access/${id}`)
      this.setState({
        playerAccesses: this.state.playerAccesses.filter(uA => uA.id !== id)
      })
      Alert.success(<FM id="message.playerAccessDeleteSuccess" />)
    } catch(error) {
      Alert.error(<FM id="message.systemErrorActionFailed" />)
    }
    }

    render() {
      return (
        <>
          <PlayerAccessForm savePlayerAccess={async (value) => await this.addPlayerAccess(value)} playerAccess={this.state.playerAccessEditing} resetForm={() => this.setState({
            playerAccessEditing: undefined
          })} />
          <Divider />
          {(!this.state.loading && this.state.playerAccesses.length === 0) &&
          <Segment placeholder >
            <Header icon style={{color: "darkgrey"}}>
                <Icon name='hdd outline' />
                <FM id="placeholder.noDataFound" />
            </Header>
          </Segment>}
          {this.state.loading && <LoadingIndicator />}
          <List relaxed divided>
          {this.state.playerAccesses.map((playerAccess) =>
            <List.Item key={playerAccess.id}>
                <List.Content>
                {(this.state.playerAccessEditing && this.state.playerAccessEditing.id === playerAccess.id) && <small>
                  Edytowanie <Icon name="pencil" />
                </small>}
                {playerAccess.username}{" "}
                <AsyncActionCaller
                action={() => this.deletePlayerAccess(playerAccess.id)}
                >
                  {(onClick, loading) =>
                <Button
                  onClick={onClick}
                  type="button"
                  size="mini"
                  negative
                  basic
                  floated="right"
                  loading={loading}
                >
                  <Icon name="trash" />
                  <FM id="action.delete" />
                </Button>}
                </AsyncActionCaller>
                <Button
                  onClick={() => this.setState({playerAccessEditing: playerAccess})}
                  type="button"
                  size="mini"
                  primary
                  basic
                  floated="right"
                >
                  <Icon name='pencil' />
                  <FM id="action.edit" />
                </Button>
                </List.Content>
              </List.Item>)}
          </List>
          <Message size="tiny" info>
            <Icon name="info circle" />
            <FM id="message.playerAccessOnlyWithDefinedCredentials" />
          </Message>
        </>
      )
    }
}
