import React from 'react'
import { Header, Form, Button, Icon, Divider } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Field, FormikProps, Formik, FormikActions, FormikErrors } from 'formik';
import { textInput } from '../form/Inputs';
import Alert from 'react-s-alert';
import { ConfirmActionButton } from '../common/ConfirmActionButton';
import { withRouter, RouteComponentProps } from 'react-router';
import { Group, deleteGroup, modifyGroup, createGroup } from '../../api/groups';
import { PlayerDropdown } from '../common/PlayerDropdown';

type Props = {
	group?: Group
}

export type GroupFormValues = Group;

const initiateValues = (group?: Group) => {
	const initialValues: Group = {
		name: "",
		players: []
	};
	if (!group) {
		return initialValues
	} else {
		return { ...group }
	}
}

const GroupFormHeader = ({editing}: {editing: boolean}) => {
	return (
		<Header>
			{editing ?
			<FormattedMessage id="title.editingGroup" /> :
			<FormattedMessage id="title.newGroup" />}
		</Header>
	)
}

const SaveFormButton: React.SFC<{isSubmitting: boolean, isEditing: boolean}> = ({isSubmitting,isEditing}) => {
	return <Button positive type="submit" loading={isSubmitting} disabled={isSubmitting}>
		<Icon name="save" />
		{isEditing ? <FormattedMessage id="action.save" /> : <FormattedMessage id="action.createNewGroup" />}
	</Button>
}

const DeleteGroupButton: React.SFC<{id: string, name: string} & RouteComponentProps> = ({id, name, history}) => {
	return <ConfirmActionButton
		onConfirm={async () => {
			try {
				await deleteGroup(id);
				Alert.success(<FormattedMessage id="message.deleteGroupSuccess" />)
				history.push("/groups")
			} catch(err) {
				Alert.error(<FormattedMessage id="message.systemErrorActionFailed" />)
			}
		}}
		actionButtonContent={<><Icon name="trash" /> <FormattedMessage id="action.deleteGroup" /></>}
		floated="right"
		modalHeader={<><Icon name="trash" /> <FormattedMessage id="message.confirm.deleteGroupHeader" values={{name}} /></>}
		modalContent={<>
		<FormattedMessage id="message.confirm.deleteGroup" />
		</>}
  />
}

const DeleteGroupButtonComponent = withRouter(DeleteGroupButton)

const renderUserForm: React.SFC<FormikProps<Group>> = ({ handleSubmit, isSubmitting, values, setFieldValue }) => {

	const isEditing = !!values.id;

	return (
		<div>
			{/* {JSON.stringify(values)} */}
			<GroupFormHeader editing={isEditing} />
			<Form onSubmit={handleSubmit}>
				<Field
					name="name"
					component={textInput}
				/>
				<PlayerDropdown
					name="players"
					setFieldValue={setFieldValue}
					multiple
					closeOnChange
					clearable
				/>
				<Divider />
				<div style={{display: "flex", alignItems: "center"}}>
					<SaveFormButton isEditing={isEditing} isSubmitting={isSubmitting} />
					<div style={{flexGrow: 1}}>
						{values.id && <DeleteGroupButtonComponent id={values.id} name={values.name} />}
					</div>
				</div>
			</Form>
		</div>
	)
}

export const validateGroup = (stream: GroupFormValues) => {
    let errors: FormikErrors<GroupFormValues> = {}
    if(!stream.name) {
        errors.name = "validation.requiredField"
    }
    return errors
}

export const GroupForm: React.SFC<Props>= ({group}) => {
	return <>
	<Formik
		initialValues={initiateValues(group)}
		render={renderUserForm}
		validate={validateGroup}
		enableReinitialize
		onSubmit={async (values, formikActions: FormikActions<Group>) => {
			formikActions.setSubmitting(true)
			if (values.id) {
				try {
					await modifyGroup(values)
					Alert.success(<FormattedMessage id="message.groupModifiedSuccess" />);
				} catch (err) {
					if(err.response.status === 409) {
						Alert.warning(<FormattedMessage id="message.groupAlreadyExists" />);
					} else if(err.response.status === 404) {
						Alert.warning(<FormattedMessage id="message.groupNotFound" />);
					} else {
						Alert.error(<FormattedMessage id="message.systemErrorActionFailed" />);
					}
				}
			} else {
				try {
					await createGroup(values);
					Alert.success(<FormattedMessage id="message.groupCreatedSuccess" />);
				} catch(error) {
					Alert.error(<FormattedMessage id="message.systemErrorActionFailed" />);
				}
			}
			formikActions.setSubmitting(false)
		}}
	/>
</>
}