import React, { useState } from 'react'

export const AsyncActionCaller: React.SFC<{
    action: () => Promise<void>,
    handleError?: (error: Error) => void,
    children: (onClick: () => void, isLoading: boolean) => React.ReactElement<any> 
}> = ({action, children, handleError }) => {

    const [isLoading, setIsLoading] = useState(false);

    const onClick = async () => {
        setIsLoading(true);
        try {
            await action();
        } catch(error) {
            if(handleError) {
                handleError(error);
            }
        }
        setIsLoading(false);
    }
    return children(onClick, isLoading);
}