import { Dispatch } from "redux";
import { fetchImages,  deleteImage, ImageUpload } from "../api/imageUploads";

export enum ImageUploadsActionType {
    fetchRequest = "webcam/images/FETCH_REQUEST",
    fetchSuccess = "webcam/images/FETCH_SUCCESS",
    fetchError = "webcam/images/FETCH_ERROR",
    uploadSuccess = "webcam/images/UPLOAD_SUCCESS",
    deleteSuccess = "webcam/images/DELETE_SUCCESS",
    deleteError = "webcam/images/DELETE_ERROR",
}

export const fetchImagesAction = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ImageUploadsActionType.fetchRequest
        })
        try {
            const { images } = await fetchImages()
            dispatch({
                type: ImageUploadsActionType.fetchSuccess,
                images
            })
        } catch(err) {
            dispatch({
                type: ImageUploadsActionType.fetchError
            })
            throw err
        }
    }
}

export const deleteImageAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await deleteImage(id)
            if(response && response.status === 204) {
                dispatch({
                    type: ImageUploadsActionType.deleteSuccess,
                    id
                })
            } else {
                throw ImageUploadsActionType.deleteError
            }
        } catch(err) {
            dispatch({
                type: ImageUploadsActionType.deleteError,
            })
            throw err
        }
    }
}

export const imageUploaded = (image: ImageUpload) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: ImageUploadsActionType.uploadSuccess,
            image
        })
    }
}