import { combineReducers } from "redux";

import users, { UserState } from './users'
import players, { PlayersState } from "./players";
import images, { ImagesState } from "./images";
import streams, { StreamsState } from "./streams";
import auth, { AuthState } from "./auth";
import groups, { GroupState } from "./groups";
import vods, { VodsState } from "./vods";

export type ApplicationState = {
    auth: AuthState
    users: UserState
    images: ImagesState
    players: PlayersState
    streams: StreamsState
    groups: GroupState
    vods: VodsState
}

export default () => combineReducers<ApplicationState>({
    auth,
    users,
    players,
    images,
    streams,
    groups,
    vods
})