import { User } from "../api/users";
import { UserActionType } from "../actions/user";

export type UserState = {
    data: User[]
    isLoading: boolean
}

const initialState: UserState = {
    data: [],
    isLoading: false
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UserActionType.createSuccess:
            return {
                ...state,
                data: [
                    action.user,
                    ...state.data
                ]
            }
        case UserActionType.modifySuccess:
            return {
                ...state,
                data: [
                    action.user,
                    ...state.data.filter(p => p.userAuth.id !== action.user.userAuth.id)
                ]
            }
        case UserActionType.fetchSuccessSingle:
            return {
                ...state,
                data: [
                    action.user,
                    ...state.data.filter(user => user.userAuth.id !== action.user.userAuth.id)
                ]
            }
      default:
        return state
    }
  }

export default reducer