import * as React from 'react'
import { RouteComponentProps } from 'react-router';
import { GroupForm } from './GroupForm';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { Group, getGroup as fetchGroup } from '../../api/groups';
import Alert from 'react-s-alert';
import { FormattedMessage } from 'react-intl';

type Props = RouteComponentProps<{id?: string}>

type State = {
    isLoading: boolean,
    group?: Group
}

export default class GroupFormComponent extends React.Component<Props, State> {

    state: State = {
        isLoading: false
    }

    async componentDidMount() {
        const { id } = this.props.match.params
        if(id) {
            this.setState({
                isLoading: true
            })
            try {
                const group = await fetchGroup(id);
                this.setState({
                    group
                })
            } catch(err){
                Alert.error(<FormattedMessage id="message.systemErrorFailedToLoadData" />)
            }
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingIndicator />
        }
        return (<GroupForm
            group={this.state.group}
        />)
    }
}