import React from 'react'
import Axios from 'axios';
import { Field } from 'formik';
import { dropdown } from '../form/Inputs';
import { FormattedMessage } from 'react-intl';
import { Camera } from '../../api/players';
import { Form } from 'semantic-ui-react';
import get from 'lodash/get';
import { LoadingIndicator } from './LoadingIndicator';

type Props = {
    name: string
    disabled?: boolean
    player?: {id: string}
    setFieldValue: (fieldName: string, value: string) => void
    selectDefault?: boolean
    multiple?: boolean
    closeOnChange?: boolean
    clearable?: boolean
}

type State = {
    players: Camera[],
    loading: boolean
}

export class PlayerDropdown extends React.Component<Props, State> {
    state: State = {
        players: [],
        loading: true
    }

    async componentDidMount() {
        try {
            let players: Camera[]
            const playerIdSelected = this.props.player && this.props.player.id
            if(this.props.disabled && playerIdSelected) {
                const response = await Axios.get<Camera>(`/api/camera/${this.props.player!!.id}`)
                players = [response.data]
            } else {
                const response = await Axios.get<Camera[]>("/api/camera")
                if(response.data.length > 0) {
                    if(this.props.selectDefault && !playerIdSelected) {
                        this.props.setFieldValue(this.props.name, response.data[0].id!!)
                    }
                }
                players = response.data
            }
            this.setState({
                players: players,
                loading: false
            })
        } catch(error) {
            
        }
        this.setState({
            loading: false
        })
    }

    render() {
        const options = [
            ...this.state.players.map(u => ({
            text: u.title,
            value: u.id
        }))]

        if (this.props.disabled) {
            return <Form.Field>
                <label><FormattedMessage id="label.field.player.id" /></label>
                {(() => {
                    const player = this.state.players.find(c => c.id === get(this.props, "player.id"))
                    return player ? player.title : <LoadingIndicator />
                })()}
            </Form.Field>
        }

        return <Field
            placeholder={<FormattedMessage id="label.noPlayer"/>}
            options={options}
            component={dropdown}
            loading={this.state.loading}
            {...this.props}
        />
    }
}