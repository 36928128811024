import { FormikErrors } from "formik";
import { StreamFormValues } from "./StreamForm";

export const validateStream = (stream: StreamFormValues) => {
    let errors: FormikErrors<StreamFormValues> = {}
    if(!stream.name) {
        errors.name = "validation.requiredField"
    }
    if(!stream.streamUrl) {
        errors.streamUrl = "validation.requiredField"
    }
    if(!stream.streamUsername && stream.streamPassword) {
        errors.streamUsername = "validation.requiredIfPasswordFilled"
    }
    if(stream.streamUsername && !stream.streamPassword) {
        errors.streamPassword = "validation.requiredIfUsernameFilled"
    }
    return errors
}