import * as React from 'react'
import Img from "react-image"
import imagePlaceholder from './image-placeholder.png'
import { FormattedMessage } from 'react-intl';
import { Image } from 'semantic-ui-react';

export const ImageWithPlaceholder: React.SFC<{
    src: string,
    className: string
    download?: string
}> = ({src, className, download}) => {
    return <FormattedMessage id="message.brokenImage" >
    {brokenImage => 
    <Img
        className={className + " checkered"}
        src={src}
        download={download}
        loader={
            <Image
                className={className }
                src={imagePlaceholder}
                
        />}
        unloader={<><img
            className={className}
            src={imagePlaceholder}
            title={brokenImage.toString()}
            alt=""
        />
        </>}
    />}
    </FormattedMessage>
}