import * as React from 'react'
import { Button, Confirm, Image, ModalContent, Divider, Icon, ModalHeader } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

type Props = {
  onDelete: () => void
  imageUrl: string
}
export class ImageDeleteButton extends React.Component<Props, {}> {
    state = { open: false }
  
    show = () => this.setState({ open: true })
    handleConfirm = () => {
      this.setState({ open: false })
      this.props.onDelete()
    }

    handleCancel = () => this.setState({ open: false })

    render() {
      return (
        <>
          <Button basic type="button" size="mini" negative onClick={this.show}>
            <Icon name="trash" />
            <FormattedMessage id="action.removeImage" />
          </Button>
          <Confirm
            cancelButton={() => <Button onClick={this.handleCancel}>
              <FormattedMessage id="action.cancel"/>
            </Button>}
            confirmButton={() => <Button negative onClick={this.handleConfirm}>
              <FormattedMessage id="action.delete"/>
            </Button>}
            open={this.state.open}
            header={<ModalHeader>
              <Icon name="trash" />{" "}
              <FormattedMessage id="message.confirm.deleteImageHeader" />
            </ModalHeader>}
            content={<ModalContent>
              <FormattedMessage id="message.confirm.deleteImage" />
              <Divider hidden fitted/>
              <Image size="small" src={this.props.imageUrl} bordered rounded style={{margin: "0 auto"}}/>
            </ModalContent>}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
          />
        </>
      )
    }
  }
  