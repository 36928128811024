import Axios from "axios";

export type Vod = {
    id: string
    name: string
    originalFileName: string
    fileSizeBytes: number
    metadata: VideoMetadata
    createdBy: string
    created: string
}

export const fetchVods = async () => {
    const response = await Axios.get<Vod[]>("/api/vod")
    return response.data
}

export const deleteVOD = async (id: string) => {
    const response = await Axios.delete(`/api/vod/${id}`)
    return response
}


type VideoMetadata =  {
    durationSeconds: number,
    bitrate: number,
    size: number,
    creationTime: string,
    formatName: string,
    formatNameLong: string,
    streams: Stream[]
};

type Stream = AudioStream | VideoStream;

type AudioStream = {
    type: 'audio'
    sampleRate: number,
    codecName: string,
    bitrate: number
};

export type VideoStream = {
    type: 'video'
    codecName: string,
    width: number,
    height: number,
    bitrate: number
};