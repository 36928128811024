import React, { Component, createRef } from 'react'

import { Button, Icon } from 'semantic-ui-react';
import { Field } from 'formik';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Map, TileLayer, Marker } from 'react-leaflet'
import { LeafletMouseEvent } from 'leaflet'

import { textInput, checkbox } from '../../form/Inputs';
import { CollapsibleSection } from '../../common/CollapsibleSection';
import { Camera } from '../../../api/players';

import './LocationPane.css'

// ~ middle of Poland
const DEFAULT_LNG = 19.05
const DEFAULT_LAT = 52.08

type Position = { lat: number, lng: number }

type State = {
  center: Position,
  marker: Position,
  zoom: number,
  draggable: boolean,
}

type Props = {
  setLatLng: (lat: number, lng: number) => void
  latitude?: number
  longitude?: number
}

class LocationSelectorMap extends Component<Props, State> {
  initialValues: Position = {
    lat: this.props.latitude || DEFAULT_LAT,
    lng: this.props.longitude || DEFAULT_LNG
  }

  state = {
    center: {
      ...this.initialValues
    },
    marker: {
      ...this.initialValues
    },
    zoom: (this.props.latitude && this.props.longitude) ? 12 : 6,
    draggable: true,
  }


  markerRef = createRef<Marker>()

  resetLocationToInitialValues = () => {
    this.setState({marker: { lat: this.initialValues.lat!, lng: this.initialValues.lng!}})
    this.props.setLatLng(this.initialValues.lat , this.initialValues.lng)
  }

  componentDidMount() {
    if(!this.props.latitude && !this.props.longitude) {
      this.props.setLatLng(DEFAULT_LAT, DEFAULT_LNG)
    }
  }

  renderResetToOriginalValues = () => {
    if(this.initialValues.lat !== this.state.marker.lat ||
      this.initialValues.lng !== this.state.marker.lng) {
        return <>
          <Button
            size="mini"
            onClick={this.resetLocationToInitialValues}
            className="reset-location"
          >
            <Icon name="crosshairs" />
            <FormattedMessage id="action.resetLocation" />
          </Button>
        </>
      }
  }

  updatePosition = () => {
    const markerElement = this.markerRef.current
    if (markerElement != null) {
      const marker = markerElement.leafletElement.getLatLng()
      this.setState({
        marker,
      })
      this.props.setLatLng(marker.lat, marker.lng)
    }
  }

  moveMarker = (e: LeafletMouseEvent) => {
    this.setState({marker: e.latlng})
    this.props.setLatLng(e.latlng.lat, e.latlng.lng)
  }

  render() {
    const position: [number, number] = [this.state.center.lat, this.state.center.lng]
    const markerPosition: [number, number] = [this.state.marker.lat, this.state.marker.lng]

    return <>
      <Map
        center={position}
        zoom={this.state.zoom}
        style={{height: "400px", cursor: "pointer"}}
        onClick={this.moveMarker}
        maxZoom={18}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {this.renderResetToOriginalValues()}
        <Marker
          draggable={this.state.draggable}
          ondragend={this.updatePosition}
          position={markerPosition}
          ref={this.markerRef}>
        </Marker>
      </Map>
      <small className="ui text gray">
        <FormattedMessage id="label.coordinates" />{": "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://google.com/maps/?q=${this.state.marker.lat},${this.state.marker.lng}`}
        >
          <FormattedNumber value={this.state.marker.lat} maximumFractionDigits={5} />,
          <FormattedNumber value={this.state.marker.lng} maximumFractionDigits={5} />
          {" "}<Icon name="external" size="small" />
        </a>
      </small>
    </>
  }
}

// TODO use nominatim to reverse search location name on request:
// https://nominatim.openstreetmap.org/reverse?lat=52.08&lon=19.05&format=jsonv2&zoom=17

const LocationPane: React.SFC<{setFieldValue(field: string, value: any): void, values: Camera}> =
  ({setFieldValue, values}) => {
    return <CollapsibleSection
        title={<FormattedMessage id="label.geolocation" />}
        subtitle={<FormattedMessage id="label.geolocationDescription" />}
        defaultVisible={true}
    >
        <Field
            name="locationEnabled"
            component={checkbox}
        />
        {values.locationEnabled && <>
          <Field
              name="locationName"
              component={textInput}
          />
          <LocationSelectorMap
            latitude={values.latitude}
            longitude={values.longitude}
            setLatLng={(lat, lng) => {
              setFieldValue("latitude", lat)
              setFieldValue("longitude", lng)
            }}
          />
        </>}
    </CollapsibleSection>
}

export default LocationPane;