import * as React from 'react'
import { Divider, Grid, Header, Button, Icon } from 'semantic-ui-react'
import { Camera, PlayerSecurityType } from '../../api/players'
import { RouteComponentProps, Link } from 'react-router-dom';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { connect } from 'react-redux'
import { buildPlayerUrl } from '../../configuration'
import { StreamRecordingStatusComponent } from '../streams/StreamRecordingStatusComponent'
import get from 'lodash/get';
import Axios from 'axios'
import Alert from 'react-s-alert';

import './PlayerDashboardComponent.css'
import { stopPlayerAction, startPlayerAction, fetchPlayerAction } from '../../actions/players'
import { ApplicationState } from '../../reducers'
import CameraActionButtons from './PlayerActionButtons'
import { ScheduledBroadcastList } from '../schedules/ScheduledBroadcastList'
import { FormattedMessage as FM } from 'react-intl';

type Props = {
    stopPlayer: (id: string) => Promise<any>
    startPlayer: (id: string) => Promise<any>
    fetchPlayer: (id: string) => Promise<any>
    player?: Camera
    playerId: string
}

export const renderIframe = ({ id, pathAlias, title }: Camera, token?: string) =>  <iframe
    src={buildPlayerUrl("/" + (pathAlias || id)) + "?token=" + token}
    style={{border: "none", width: "100%", height: "400px"}}
    title={title}
>
</iframe>

type State = {
    token?: string
}

export class PlayerDashboardComponent extends React.Component<Props, State> {
    state: State = {
        token: undefined,
    }

    componentDidMount() {
        if(!this.props.player) {
            this.props.fetchPlayer(this.props.playerId)
        }
    }

    async generatePlayerToken(playerId: string) {
        try {
            const tokenResponse = await Axios.post<{tokens: {token: string, expirationTime: string}[]}>(`/api/player/${playerId}/token`,
            {
                oneTimeUse: true
            })
            return tokenResponse.data.tokens[0].token;
        } catch(error) {
            Alert.error(<FM id="message.systemErrorActionFailed" />)
        }
    }

    render() {
        if(!this.props.player) {
            return <LoadingIndicator />
        }
        const streamIds = this.props.player.sources
                        .filter(s => s.type === 'stream')
                        .map(s => get(s, "streamId"))

        const { pathAlias, id } = this.props.player
        const refreshIframeButton = <>
        <Button size="mini" onClick={() => {
            const iframe = document.querySelector("iframe")
            if(iframe) {
                const tmpUrl = iframe.src
                iframe.src = ""
                iframe.src = tmpUrl
            }
        }}>
            <Icon name="refresh" />
            <span className="mobile-hide">
                <FM id="action.refreshIframe" />
            </span>
        </Button>
        {this.props.player.securityType !== PlayerSecurityType.None && <Button size="mini" onClick={async () => {
            if (this.props.player && this.props.player.id) {
                const token = await this.generatePlayerToken(this.props.player.id)
                this.setState({ token })
            }
            
        }}>
            <Icon name="sign in" /> <FM id="action.oneTimeSignIn" />
        </Button>}
        </>
        return <>
            <Header>
                <FM id="action.playerDashboard" />{": "}
                {this.props.player.title} 
            </Header>
            <CameraActionButtons
                player={this.props.player}
                hideButtons={["dashboard", "preview"]} 
                extraContent={refreshIframeButton}
            />
            <Divider hidden fitted />
            <Grid className="equal width" stackable>
                <Grid.Row>
                    <Grid.Column>
                        <div className="facade-minimal" data-url={buildPlayerUrl("/" + (pathAlias || id))}>
                            {renderIframe(this.props.player, this.state.token)}
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        {streamIds.map(streamId => <>
                            <StreamRecordingStatusComponent id={streamId} hideQuota={true} />
                            <Button as={Link} to={`/streams/${streamId}/recording`} size="tiny">
                                <Icon name="folder open" />
                                <FM id="title.recordingsList" />
                            </Button>
                        </>)}
                        <ScheduledBroadcastList hideHistory limit player={this.props.player} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </>;
    }
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{id: string}>) {
    const id = ownProps.match.params.id
    return {
        playerId: id,
        player: id ? state.players.data.find(c => c.id === id) : undefined
    }
}

export default connect(mapStateToProps,
    { stopPlayer: stopPlayerAction, startPlayer: startPlayerAction, fetchPlayer: fetchPlayerAction })
(PlayerDashboardComponent)