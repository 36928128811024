import React from 'react'
import ReactDropzone, { DropEvent } from 'react-dropzone';
import { Icon } from 'semantic-ui-react';
import Axios from 'axios';
import Alert from 'react-s-alert';
import { ImageUpload } from '../../api/imageUploads';
import './ImageUploadInput.css'
import { FormattedMessage } from 'react-intl';
type State = {
    uploading: boolean
}

type Props = {
    onUploadComplete?: (image: ImageUpload) => void
    imageUploaded: (image: ImageUpload) => void
    asButton?: boolean
}

const initialState: State = {
    uploading: false
}

const SUPPORTED_IMAGE_UPLOAD_TYPES = ["image/jpg", "image/jpeg", "image/png"]
const MAX_IMAGE_UPLOAD_SIZE_MBYTES = 4
const MAX_IMAGE_UPLOAD_SIZE_BYTES = MAX_IMAGE_UPLOAD_SIZE_MBYTES * 1024 * 1024

type DropFilesEventHandler = (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => void

export class ImageUploadInput extends React.Component<Props, State> {

    state: State = initialState
    
    onDrop: DropFilesEventHandler = async (files) => {
        const formData = new FormData()
        files.forEach(file => {
            formData.append("image", file)
        })

        if(files.filter(f => f.size > MAX_IMAGE_UPLOAD_SIZE_BYTES).length > 0) {
            Alert.error(<FormattedMessage
                id="message.fileUploadTooLarge"
                values={{maximumSize: MAX_IMAGE_UPLOAD_SIZE_MBYTES + "MB"}}
            />);
            return
        }

        // FIXME this possibly does not work
        if(files.filter(f => !SUPPORTED_IMAGE_UPLOAD_TYPES.find(t => t !== f.type)).length > 0) {
            Alert.error(<FormattedMessage
                id="message.fileUploadUnsupportedFormat"
                values={{supportedFormat: SUPPORTED_IMAGE_UPLOAD_TYPES.join(", ")}}
            />);
            return
        }

        try {
            this.setState({
                uploading: true
            })
            const response = await Axios.post<ImageUpload>("/api/image-upload", formData)
            this.props.imageUploaded(response.data)
            this.props.onUploadComplete && this.props.onUploadComplete(response.data)
            Alert.success(<FormattedMessage
                id="message.imageUploaded"
                values={{fileName: response.data.originalFileName}} />
            );
        } catch(err) {
            Alert.error(<FormattedMessage id="message.imageUploadError" />);
        }
        this.setState({
            uploading: false
        })
    }

    render() {
        const className = this.props.asButton ?
            this.state.uploading ? "ui loading mini primary basic button": "ui mini primary basic button" :
            this.state.uploading ? "ui center aligned segment loading image-upload" : "ui segment image-upload" 
        return <FormattedMessage id="action.uploadImage" >
        {uploadImageText =>
        <ReactDropzone
            multiple={false}
            accept={SUPPORTED_IMAGE_UPLOAD_TYPES.join(", ")}
            onDrop={this.onDrop}
        >
        {({getRootProps, getInputProps}) => {
            return <div
                {...getRootProps()}
                style={{
                    cursor: 'pointer',
                    marginTop: 0
                }}
                className={className}
                title={uploadImageText.toString()}
            >
                <input {...getInputProps()} />
                <Icon name="upload" />
                {uploadImageText}
            </div>
        }}
        </ReactDropzone>
        }
        </FormattedMessage>
    }
}