import * as React from 'react';
import { Header, Icon, Menu } from 'semantic-ui-react';
import { FormattedMessage as FM, FormattedMessage } from 'react-intl';
import { NavLink, Switch, Route } from 'react-router-dom';
import { UsersList } from './users/UsersList';
import { GroupsList } from './groups/GroupsList';

export const AdministrationComponent = () => {
    return <>
    <Header>
        <FM id="title.systemAdministration" />
    </Header>
    <Menu>
        <Menu.Item as={NavLink} to="/users" activeClassName="active" basic="true">
            <Icon name="users" />
            <span className="mobile-hide"><FormattedMessage id="title.usersManagement" /></span>
        </Menu.Item>
        <Menu.Item as={NavLink} to="/groups" activeClassName="active" basic="true">
            <Icon name="folder open" />
            <span className="mobile-hide"><FormattedMessage id="title.groups" /></span>
        </Menu.Item>
    </Menu>
    <Switch>
        <Route exact path="/users" component={UsersList} />
        <Route exact path="/groups" component={GroupsList} />
    </Switch>
    </>
}