import React from 'react'
import { Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux'
import { logout as logoutAction } from '../../actions/auth';

type Props = {
    logout: () => void
    className: string
}

const Logout: React.SFC<Props> = ({logout, className}) => {
    return (
    <div onClick={() => logout()} className={className}>
            <Icon name="log out" />
            <FormattedMessage id="action.logout" />
    </div>
    )
}

export default connect(null, {logout: logoutAction})(Logout)