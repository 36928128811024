import Axios from "axios";
import { connect } from "react-redux";
import { invalidateAuth } from "../actions/auth";

const UnauthorizedInterceptor: React.SFC<{onUnauthorized: () => void}> = ({onUnauthorized}) => {
    /**
     * This interceptor should be ejected when user has clicked the logout button.
     */
    Axios.interceptors.response.use(undefined, err => {
        const error = err.response;
        if (error.status === 401) {
            onUnauthorized()
        }
        return Promise.reject(err);
    });
    return null
}

export default connect(null, { onUnauthorized: invalidateAuth })(UnauthorizedInterceptor)