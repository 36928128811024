import * as React from 'react'
import { Button, Icon, Divider, Table } from 'semantic-ui-react';
import { FormattedMessage as FM } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';
import Alert from 'react-s-alert';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { Group, fetchGroups } from '../../api/groups';

type State = {
	groups: Group[],
	loading: boolean,
	error: boolean
}

const NewGroupButton: React.SFC = () => {
	return <Button as={Link} to="/groups/new" color="green">
		<Icon name="folder open" />
		<FM id="action.newGroup" />
	</Button>
}

export class GroupsList extends React.Component<{}, State> {
	state: State = {
		loading: true,
		groups: [],
		error: false
	}
	async componentDidMount() {
		try {
			const response = await fetchGroups()
			this.setState({
				groups: response,
				loading: false,
				error: false
			})
		} catch(err) {
			this.setState({
				loading: false,
				error: true
			})
			Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
		}
	}
	render() {
		return (
			<>
				<NewGroupButton />
				{this.state.loading && <LoadingIndicator />}
				{!this.state.loading && <>
				<Table striped className="very compact">
					<Table.Header>
						<Table.Row>
						<Table.HeaderCell>
							<FM id="label.field.name" />
						</Table.HeaderCell>
						<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{this.state.groups.map((group, i) => <Table.Row key={i}>
							<Table.Cell>
								<NavLink to={`/groups/${group.id}`}>
									{group.name}
								</NavLink>
							</Table.Cell>
							<Table.Cell textAlign="right">
								<Button as={Link} to={`/groups/${group.id}`} size="mini" primary>
									<Icon name="pencil" />
									<FM id="action.edit" />
								</Button>
							</Table.Cell>
						</Table.Row>)}
					</Table.Body>
				</Table>
				<Divider hidden />
				</>}
			</>
		)
	}
}
