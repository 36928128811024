import * as React from 'react'
import Moment from "react-moment";
import moment from 'moment'

type Resource = {
    modified?: string
    modifiedBy?: string
    created?: string
    createdBy?: string
}

export const ResourceAuthorInfo: React.SFC<{ values: Resource }> = ({ values }) => {
    return (
        <>
            <small>Utworzono: {values.createdBy}{", "}
                <time
                    dateTime={values.created}
                    title={moment(values.created).format('LLL')}
                >
                    <Moment fromNow date={values.created} />
                </time>{values.modified && ";"}
            </small>
            {values.modified &&
            <small> zmodyfikowano: {values.modifiedBy}{", "}
                <time
                    dateTime={values.modified}
                    title={moment(values.modified).format('LLL')}
                >
                    <Moment fromNow date={values.modified} />
                </time>
            </small>}
        </>
    )
}