import React from 'react'
import { Icon, List, Button, Popup, Segment, Message } from 'semantic-ui-react';
import { FormattedMessage as FM } from 'react-intl';
import Alert from 'react-s-alert';
import { LoadingIndicator } from '../common/LoadingIndicator';
import moment from 'moment'
import { RecorderStatusResponse, stopRecording, startRecording, recorderStatus, RecorderStatus } from '../../api/stream-recording';
import throttle from 'lodash/throttle'
import { ErrorMessage } from '../common/Error';
import { QuotaUsedDisplay } from './UsedQuota';
import { StreamStatusComponent } from './StreamStatusComponent';

import './StreamRecordingStatusComponent.css'

type StreamStatusComponentProps = {
    id: string
    hideQuota?: boolean
}

type State = {
    status?: RecorderStatusResponse
    statusFetchError?: Error
    recordingActionLoading: boolean
}

const RECORDER_STATUS_REFRESH_INTERVAL_MS = 10000
const bytesToMB = (bytes: number) => Math.round((bytes / 1024 / 1024) * 100) / 100

export class StreamRecordingStatusComponent extends React.Component<StreamStatusComponentProps, State> {
    state: State  = {
        recordingActionLoading: false
    }

    interval?: number;

    fetchRecordingStatusThrottled = throttle(async () => {
        try {
            const status = await recorderStatus(this.props.id)
            this.setState({
                status: status.data,
                statusFetchError: undefined
            })
        } catch(error) {
            this.setState({
                statusFetchError: error
            })
        }
    }, 10000, { trailing: true })

    invokeImmiediatelyFetchStatus = () => {
        this.fetchRecordingStatusThrottled()
        this.fetchRecordingStatusThrottled.flush()
    }

    async startRecording() {
        this.setState({
            recordingActionLoading: true
        })
        try {
            await startRecording(this.props.id)
            Alert.success(<FM id="message.streamRecordingStarted" />)
        } catch(err) {
            if(err.response && err.response.status === 409) {
                Alert.info(<FM id="message.streamRecordingAlreadyStarted" />)
            } else if(err.response && err.response.status === 429) {
                Alert.error(<FM id="message.streamRecordingQuotaExceeded" />)
            } else {
                Alert.error(<FM id="message.streamRecordingStartError" />)
            }
        }
        this.setState({
            recordingActionLoading: false
        })
        this.invokeImmiediatelyFetchStatus()
    }

    async stopRecording() {
        this.setState({
            recordingActionLoading: true
        })
        try {
            await stopRecording(this.props.id)
            Alert.success(<FM id="message.streamRecordingStopped" />)
        } catch(err) {
            if(err.response && err.response.status === 404) {
                Alert.info(<FM id="message.streamRecordingNotStartedCannotStop" />)
            } else {
                Alert.error(<FM id="message.streamRecordingStopError" />)
            }
        }
        this.setState({
            recordingActionLoading: false
        })
        this.invokeImmiediatelyFetchStatus()
    }

    async componentDidMount() {
        this.invokeImmiediatelyFetchStatus()
        // FIXME setInterval is really poor choice for scheduling requests, in case of system slowdown - requests will pile-up.
        this.interval = window.setInterval(() => this.fetchRecordingStatusThrottled(), RECORDER_STATUS_REFRESH_INTERVAL_MS)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.fetchRecordingStatusThrottled.cancel()
    }

    render() {
        return <>
        {this.renderQuota()}
        <Segment>
            {this.renderErrorMessages()}
            <List>
                <StreamStatusComponent id={this.props.id} />
                {this.renderRecorderStatus()}
            </List>
        </Segment>
        {this.recordingButtons()}
        </>
    }

    isRecordingStartAvailable() {
        if(!this.state.status || this.state.statusFetchError) {
            return false
        }
        const { quota } = this.state.status;
        return quota.quotaUsedBytes < quota.quotaAvailableBytes;
    }

    recordingButtons() {
        if(!this.state.status) {
            return null
        }
        const { status } = this.state.status;
        const recordingStartAvailable = this.isRecordingStartAvailable();
        return <>
        {(recordingStartAvailable && status === RecorderStatus.NotRecording) &&
            <Button size="tiny" basic color="red" onClick={() => this.startRecording()} loading={this.state.recordingActionLoading}>
            <Icon name="circle" color="red" />
            <FM id="action.startRecording" />
        </Button>}
        {(status === RecorderStatus.InProgress || status === RecorderStatus.WaitingForStream) &&
        <Button size="tiny" basic onClick={() => this.stopRecording()} loading={this.state.recordingActionLoading}>
            <Icon name="square" color="black" />
            <FM id="action.stopRecording" />
        </Button>}
        </>
    }

    renderQuota() {
        if(!this.state.status) {
            return null
        }
        const { status, quota } = this.state.status;
        const recordingStartAvailable = this.isRecordingStartAvailable();
        return <>
            {(!this.props.hideQuota) && <QuotaUsedDisplay
                used={quota.quotaUsedBytes}
                available={quota.quotaAvailableBytes}
                recordingInProgress={status === RecorderStatus.InProgress || status === RecorderStatus.WaitingForStream}
            />}
            {(!recordingStartAvailable && status === RecorderStatus.NotRecording && !this.state.statusFetchError) && <Message size={"tiny"} error>
                <Message.Header>
                    <Icon name="exclamation triangle" />
                    <FM id="message.recordingLockedAfterQuotaUsedHeader" />
                </Message.Header>
                <FM id="message.recordingLockedAfterQuotaUsedContent" />
            </Message>}
        </>
    }

    renderErrorMessages() {
        if(this.state.statusFetchError) {
            return <ErrorMessage error={this.state.statusFetchError} messageId="message.failedToFetchRecorderStatus" />
        } else {
            return null;
        }
    }

    renderRecordingDuration(status: RecorderStatusResponse) {
        const { quota } = status
        return <>
        {moment.duration(status.duration, "ms").humanize()}
        {status.bytesInRate > 0 && (() => {
            const bytesLeft = quota.quotaAvailableBytes - quota.quotaUsedBytes;
            const bitrate = status.bytesInRate;
            const secondsLeft = bytesLeft / bitrate;
            if (bytesLeft <= 0) {
                return null;
            }
            return <>{" "}<small>
                (<FM id="message.quotaWillBeEnoughForDuration" values={{duration: moment.duration(secondsLeft, "s").humanize()}} />)
            </small></>
        })()}
        </>
    }
    renderRecorderStatus() {
        if(this.state.statusFetchError) {
            return null;
        }
        if(!this.state.status) {
            return <LoadingIndicator />;
        }
        const status = this.state.status;

        const recordingStartAvailable = this.isRecordingStartAvailable()
    
        if(!recordingStartAvailable && status.status === RecorderStatus.NotRecording) {
            // Error or quota exceeded
            return null;
        }

        return <>
        <List.Item>
            <List.Header>
                <FM id="label.stream.recordingStatus" />
            </List.Header>
            <span>
                <FM id={"label.stream.recordingStatus." + status.status} />
                {status.status === RecorderStatus.InProgress && <>{" "}<Icon name="circle" color="red" className="blink" /></>}
            </span>
        </List.Item>
        {status.status === RecorderStatus.InProgress && <>
        <List.Item>
            <List.Header>
            <FM id="label.stream.recordingStartTime" />
            </List.Header>
            {moment(status.recordingStartTime).format("LLLL")}
        </List.Item>
        <List.Item>
            <List.Header>
            <FM id="label.stream.currentRecordingDuration" />
            </List.Header>
            {this.renderRecordingDuration(status)}
        </List.Item>
        <List.Item>
            <List.Header>
                <FM id="label.stream.recordingSize" />
                {" "}
                <Popup trigger={<Icon name='info circle' style={{cursor: "help"}}/>} position='top center' inverted>
                    <FM id="message.maximumRecordingFileSizeInfo" />
                </Popup>
            </List.Header>
            {bytesToMB(status.currentFileSize)}MB
        </List.Item>
        </>}
        </>;
    }
}