import * as React from 'react'
import { FieldProps, Field, getIn } from 'formik';
import { ImageWidget } from '../../../../api/players';
import { Form, Label } from 'semantic-ui-react';
import { ImageWithPlaceholder } from '../../../images/ImageWithPlaceholder';
import { ImageChooser } from '../../../images/ImageChooser';
import { dropdown, checkbox } from '../../../form/Inputs';
import { FormattedMessage } from 'react-intl';
import { LinkEdit } from './LinkEdit';
import { NoImageSelected } from '../../../images/NoImageSelected';

export const ImageEdit = ({index, widget}: {index: number, widget: ImageWidget}) => {
    return <>
    <Field name={`widgets[${index}.imageUrl]`}>
        {({form: { touched, errors, setFieldValue}, field: {name}}: FieldProps) => {
            const error: string = getIn(errors, name)
            const touchedField = getIn(touched, name)
            return <>
            <Form.Field error={touchedField && !!error}>
                <label><FormattedMessage id="label.widgetType.image" /></label>
                {!widget.imageUrl && <NoImageSelected />}
                {widget.imageUrl && <>
                    <ImageWithPlaceholder
                        src={widget.imageUrl}
                        className="ui small left aligned image mb bordered"
                    />
                </>}
                <ImageChooser
                    onImageChoose={image => setFieldValue(`widgets[${index}].imageUrl`, image.fileUrl)}
                    />
                
                {(touchedField && error) && <p><Label color="red" basic pointing="above">
                    <FormattedMessage id={error} />
                </Label></p>}
            </Form.Field>
            </>
        }}
    </Field>
    <Field
        name={`widgets[${index}].position`}
        label={"widget.position"}
        component={dropdown}
        options={[{
            value: "top-right",
            text: <FormattedMessage id="label.widgetPosition.top-right" />
            }, {
            value: "top-left",
            text: <FormattedMessage id="label.widgetPosition.top-left" />
            }, {
            value: "bottom-right",
            text: <FormattedMessage id="label.widgetPosition.bottom-right" />
            },{
            value: "bottom-left",
            text: <FormattedMessage id="label.widgetPosition.bottom-left" />
        }]}
    />
       <Field
        name={`widgets[${index}].imageSize`}
        label={"widget.imageSize"}
        component={dropdown}
        options={[{
            value: "tiny",
            text: <FormattedMessage id="label.widget.imageSize.tiny" />
            }, {
            value: "small",
            text: <FormattedMessage id="label.widget.imageSize.small" />
            }, {
            value: "medium",
            text: <FormattedMessage id="label.widget.imageSize.medium" />
            }]}
    />
    <Field
        name={`widgets[${index}].hasLink`}
        label={"widget.hasLink"}
        component={checkbox}
    />
    {widget.hasLink && <LinkEdit index={index} />}
    </>
}