import * as React from 'react'

import { Form, Header, Button, Icon, Divider } from "semantic-ui-react";
import { Formik, Field, FormikProps, FormikActions } from 'formik';
import Alert from 'react-s-alert';
import { FormattedMessage as FM } from 'react-intl';

import { textInput, prefixedTextInput, checkbox } from '../../form/Inputs';
import { Camera, getPlayerByAlias } from '../../../api/players';
import { validateCamera } from './validateCamera';
import { IframeCodeDisplay } from './IframeCodeDisplay';
import { PlaybackPaneComponent } from './PlaybackPane';
import { WidgetsPane } from './widgets/WidgetsPane';
import LocationPane from './LocationPane';
import { ImageWithPlaceholder } from '../../images/ImageWithPlaceholder';
import { ImageChooser } from '../../images/ImageChooser';
import { NoImageSelected } from '../../images/NoImageSelected';
import { ResourceAuthorInfo } from '../../common/ResourceAuthorInfo';
import configuration, { buildPlayerUrl } from '../../../configuration';
import { OperatorDropdown } from '../../common/OperatorDropdown';
import UserAccessCondition from '../../auth/UserAccessCondition';
import { UserType } from '../../../api/usersAuth';
import CameraDeleteButton from '../CameraDeleteButton';
import { GroupDropdown } from '../../common/GroupDropdown';
import querystring from 'querystring';

type Props = {
    camera?: Camera
    initialValues?: Camera
    createCamera: (camera: Camera) => void
    modifyCamera: (camera: Camera) => void
}

const CameraFormHeader = ({values}: {values: Camera}) => {
    const editing = !!values.id
    return (
        <Header>
            {editing ?
            <FM id="title.editingCamera" /> :
            <FM id="title.newCamera" />}
            {editing &&
            <Header.Subheader>
                <p style={{margin: 0}}>
                    <ResourceAuthorInfo values={values} />
                </p>
            </Header.Subheader>}
        </Header>
    )
}

const SaveFormButton: React.SFC<{isSubmitting: boolean}> = ({isSubmitting}) => {
    return <Button positive type="submit" loading={isSubmitting} disabled={isSubmitting}>
        <Icon name="save" />
        <FM id="action.save" />
    </Button>
}

const PATH_VALIDATION_REGEX = /^[a-zA-Z0-9]+[a-zA-Z0-9-_]*[a-zA-Z0-9]$/

type CameraRendererProps = React.SFC<FormikProps<Camera>>
const renderPlayerForm: CameraRendererProps =
    (formikProps) => {

    const {handleSubmit, values, isSubmitting, setFieldValue } = formikProps

    const editing = !!values.id

    var query = querystring.parse(window.location.search.substring(1));
    const accessModalOpen = "accesses" in query;

    return (<>
    <CameraFormHeader values={values} />
    <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
            <Field
                name="title"
                component={textInput}
            />
            <Field
                name="description"
                component={textInput}
            />
        </Form.Group>
        <Field
            name="showHeader"
            component={checkbox}
        />
        <UserAccessCondition showOnlyTo={UserType.Admin}>
            <OperatorDropdown name="operator.id" />
        </UserAccessCondition>
        <UserAccessCondition showOnlyTo={UserType.Admin}>
            <GroupDropdown name="groups" />
        </UserAccessCondition>
        <FM id="label.generatedId" >
        {placeholderText =>
        <Field
            name="pathAlias"
            component={prefixedTextInput}
            placeholder={!values.pathAlias && (values.id || placeholderText)}
            tooltip={<FM id="label.pathAliasTooltip" />}
            prefix={configuration.playerDomainName + "/"}
            
            validate={async (value: string) => {
                if(value) {
                    if(value.length < 3) {
                        return "validation.pathMustHaveAtLeast3Characters"
                    }
                    if(!PATH_VALIDATION_REGEX.test(value)) {
                        return "validation.pathMustMatchPattern"
                    }
                    try {
                        const config = await getPlayerByAlias(value)
                        if(config && config.id !== values.id) {
                            return "validation.pathAlreadyUsed"
                        }
                    } catch(err) {
                        if(err.response && err.response.status === 404) {
                            return
                        } else {
                            return "validation.validationErrorOccurred"
                        }
                    }
                }
            }}
        />}
        </FM>
        <Form.Field>
            <label><FM id="label.field.thumbnailUrl" /></label>
            {!values.thumbnailUrl && <NoImageSelected />}
            {values.thumbnailUrl && <ImageWithPlaceholder
                src={values.thumbnailUrl}
                className="ui small left aligned image bordered mb"
            />}
            <ImageChooser
                onImageChoose={image => setFieldValue("thumbnailUrl", image.fileUrl)}
            />
            {values.thumbnailUrl && <>
            <FM id="label.or" />{" "}
            <Button basic size="mini" color="red" onClick={() => setFieldValue("thumbnailUrl", null)}>
                <Icon name="x" /><FM id="action.removeImage" />
            </Button>
            </>}
        </Form.Field>
        <PlaybackPaneComponent
            accessModalOpen={accessModalOpen}
            {...formikProps}
        />
        <LocationPane values={values} setFieldValue={setFieldValue} />
        <WidgetsPane values={values} />
        <IframeCodeDisplay values={values} editing={editing} />
        <Divider />
        <SaveFormButton isSubmitting={isSubmitting} />
        {values.id && <a
            href={buildPlayerUrl(`/${values.pathAlias || values.id}`)}
            className="ui basic button"
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icon name="tv" />
            <FM id="label.cameraLink" />
        </a>}
        {values.id && <CameraDeleteButton id={values.id} />}
        <Divider hidden />
    </Form>
    </>)
}

const CameraForm: React.SFC<Props> = ({createCamera, modifyCamera, camera, initialValues}) => {
    return (
        <Formik
            onSubmit={async (values, formikActions: FormikActions<Camera>) => {
                formikActions.setSubmitting(true)
                try {
                    if(values.operator && values.operator.id === "") {
                        // fix for cleared operator dropdown.
                        values.operator = undefined;
                    }
                    if(camera) {
                        await modifyCamera(values)
                        Alert.success(<FM id="message.playerUpdated"/>)
                    } else {
                        await createCamera(values)
                        Alert.success(<FM id="message.playerCreated" values={{title: values.title}}/>);
                    }
                } catch(err) {
                    Alert.error(<FM id="message.playerSaveError" />);
                }
                formikActions.setSubmitting(false)
            }}
            validate={validateCamera}
            initialValues={camera || initialValues!!}
            render={renderPlayerForm}
            enableReinitialize
        />
    )
}

export default CameraForm