import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../reducers';
import { FormattedMessage } from 'react-intl';
import { Header } from 'semantic-ui-react';
import { Stream } from '../../api/streams';
import { fetchStreamsAction } from '../../actions/stream';
import { StreamRecordingStatusComponent } from './StreamRecordingStatusComponent';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { RecordingsListComponent } from './RecordingsList';

type Props = {
    fetchStreams: () => void
    isLoading: boolean
    stream?: Stream
} & RouteComponentProps<{id?: string}>

class StreamRecordingComponent extends React.Component<Props, {}> {

    componentDidMount() {
        this.props.fetchStreams()
    }

    render() {
        if(this.props.isLoading || !this.props.stream) {
            return <LoadingIndicator />
        }
        return <>
            <Header>
                <FormattedMessage id="label.streamRecording" /> - {this.props.stream.name}
            </Header>
            <StreamRecordingStatusComponent id={this.props.stream.id!!} />
            <RecordingsListComponent id={this.props.stream.id!!} />
        </>
    }
}

function mapStateToProps(state: ApplicationState, ownProps: Props) {
    const id = ownProps.match.params.id
    return {
        isLoading: state.streams.isLoading,
        stream: id ? state.streams.data.find(c => c.id === id) : undefined
    }
}

export default connect(mapStateToProps, {
    fetchStreams: fetchStreamsAction
})(StreamRecordingComponent)