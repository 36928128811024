import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers';
import { Stream } from '../../api/streams';
import { fetchStreamAction } from '../../actions/stream';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { StreamStatusComponent } from './StreamStatusComponent';
import { Header, List } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { StreamOutputInfo } from './StreamOutputInfo';

type Props = {
    fetchStream: (id: string) => void
    stream?: Stream
    id: string
}

class StreamStatusPage extends React.Component<Props, {}> {

    componentDidMount() {
        if(!this.props.stream) {
            this.props.fetchStream(this.props.id)
            // TODO handle 404
        }
    }

    render() {
        if(!this.props.stream) {
            return <LoadingIndicator />
        }
        return <>
            <Header>
                <FormattedMessage id="title.streamStatus" />
                {": "}
                {this.props.stream.name}
            </Header>
            <List>
                <StreamStatusComponent
                    id={this.props.id}
                />
            </List>
            <StreamOutputInfo stream={this.props.stream} defaultVisible={true} />
        </>
    }
}

function mapStateToProps(state: ApplicationState, ownProps: { id: string, preview?: boolean }) {
    const id = ownProps.id
    return {
        stream: id ? state.streams.data.find(c => c.id === id) : undefined
    }
}

export default connect(mapStateToProps, {
    fetchStream: fetchStreamAction
})(StreamStatusPage)