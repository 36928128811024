import React from 'react'
import io from 'socket.io-client';
import { StreamStatus } from '../../api/streams';

type StreamStatusComponentProps = {
    id: string
    children: (status?: StreamStatus, error?: Error) => React.ReactNode
    shortPolling?: boolean
}

type State = {
    status?: StreamStatus
    error?: Error
    socketIoSocket?: SocketIOClient.Socket
}

export class StreamStatusSubscriber extends React.Component<StreamStatusComponentProps, State> {
    state: State  = {}

    componentDidMount() {
        const { shortPolling } =  this.props
        const socket = io({
            path: "/api/socket.io",
            query: {
                streamId: this.props.id,
                shortPolling
            }
        })
        this.setState({
            socketIoSocket: socket
        });
        socket.on('status', (data: string) => {
            this.setState({
                status: JSON.parse(data),
                error: undefined
            })
        })
        socket.on('status_error', () => {
            this.setState({
                error: new Error()
            })
        })
    }

    componentWillUnmount() {
        if(this.state.socketIoSocket) {
            this.state.socketIoSocket.disconnect()
        }
    }

    render() {
        return this.props.children(this.state.status, this.state.error)
    }
}