import React from 'react'
import { Header, Form, Button, Icon, Divider } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { Field, FormikProps, Formik, FormikActions } from 'formik';
import { textInput, PasswordShowHideInput, dropdown } from '../form/Inputs';
import Alert from 'react-s-alert';
import { validateUsers } from './validateUsers';
import './UserForm.css'
import { User, deleteUser } from '../../api/users';
import { UserType } from '../../api/usersAuth';
import LoggedInUserExtractor from '../auth/LoggedInUserExtractor';
import { ConfirmActionButton } from '../common/ConfirmActionButton';
import { withRouter, RouteComponentProps } from 'react-router';

type Props = {
	user?: User
	createUser: (user: User) => Promise<User>
	modifyUser: (user: User) => Promise<User>
}

export type UserFormValues = User;

const initialValues: UserFormValues = {
	userAuth: {
		username: "",
		password: "",
		type: UserType.User
	},
	/**
	accountType: UserAccountType.Personal,
	firstName: "",
	lastName: "",
	zipCode: "",
	street: "",
	city: "",
	country: "",
	companyName: "",
	nip: "", */
}

const initiateValues = (user?: User) => {
	if (!user) {
		return initialValues
	} else {
		const values: UserFormValues = { ...user }
		return values
	}
}

// Form header
const UserFormHeader = ({editing, values}: {editing: boolean, values: UserFormValues}) => {
	return (
		<Header>
			{editing ?
			<FormattedMessage id="title.editingUser" /> :
			<FormattedMessage id="title.newUser" />}
		</Header>
	)
}

// Form section - user authentication data
const UserFormAuthData = ({editing, values}: {editing: boolean, values: UserFormValues}) => {
	return (<>
		<Form.Group widths="equal">
			<Field
				name="userAuth.username"
				component={textInput}
			/>
			<Field
				name="userAuth.password"
				component={PasswordShowHideInput}
				tooltip={values.userAuth.id && <FormattedMessage id="label.leaveEmptyPasswordToDontChange" />}
			/>
		</Form.Group>
		<Field
			name="userAuth.type"
			component={dropdown}
			disabled={!!values.userAuth.id}
			options={[{
				value: UserType.Admin,
				text: <FormattedMessage id="label.userType.admin" />
			},{
				value: UserType.User,
				text: <FormattedMessage id="label.userType.user" />
			}]}
		/>
		</>
	)
}


// // Form section - user personal / company data
// const UserFormPersonalData = ({editing, values, onAccountTypeUpdate}: {editing: boolean, values: UserFormValues, onAccountTypeUpdate: Function}) => {
// 	let companyAdditionalData;

// 	if (values.accountType == 2) {
// 		companyAdditionalData = <>
// 			<Form.Group widths="equal">
// 				<Field
// 					name="companyName"
// 					component={textInput}
// 				/>
// 				<Field
// 					name="nip"
// 					component={textInput}
// 				/>
// 			</Form.Group>
// 		</>;
// 	}

// 	return (<>
// 		<Header dividing>
// 			<FormattedMessage id={ 'label.user.' + (values.accountType == UserAccountType.Personal ? 'personal' : 'company') + '.header' } />
// 		</Header>

// 		{ // TODO use the input component with adaptation for formik inputs
// 		/* <Form.Group>
// 			<Radio
// 				name="accountType"
// 				label={<FormattedMessage id="label.user.personal" />}
// 				value={UserAccountType.Personal}
// 				checked={values.accountType === UserAccountType.Personal}
// 			/>
// 		 	<Radio
// 				name="accountType"
// 				label={<FormattedMessage id="label.user.company" />}
// 				value={UserAccountType.Company}
// 				checked={values.accountType === UserAccountType.Company}
// 			/>
// 		</Form.Group> */}

// 		<Button.Group className="account-type-buttons-group">
// 			<Button
// 				type="button"
// 				disabled={ values.accountType == UserAccountType.Personal }
// 				onClick={() => onAccountTypeUpdate(UserAccountType.Personal)}
// 			>
// 				<FormattedMessage id="label.user.personal" />
// 			</Button>
// 			<Button
// 				type="button"
// 				disabled={ values.accountType == UserAccountType.Company }
// 				onClick={() => onAccountTypeUpdate(UserAccountType.Company)}
// 			>
// 				<FormattedMessage id="label.user.company" />
// 			</Button>
// 		</Button.Group>
// 		{companyAdditionalData}
// 		<Form.Group widths="equal">
// 			<Field
// 				name="firstName"
// 				component={textInput}
// 				label={ (values.accountType == UserAccountType.Personal ? 'personal' : 'company') + '.firstName' }
// 			/>
// 			<Field
// 				name="lastName"
// 				component={textInput}
// 				label={ (values.accountType == UserAccountType.Personal ? 'personal' : 'company') + '.lastName' }
// 			/>
// 		</Form.Group>
// 		<Form.Group widths="equal">
// 			<Field
// 				name="street"
// 				component={textInput}
// 			/>
// 			<Field
// 				name="zipCode"
// 				component={textInput}
// 			/>
// 			<Field
// 				name="city"
// 				component={textInput}
// 			/>
// 		</Form.Group>
// 		</>
// 	)
// }

const SaveFormButton: React.SFC<{isSubmitting: boolean, isEditing: boolean}> = ({isSubmitting,isEditing}) => {
	return <Button positive type="submit" loading={isSubmitting} disabled={isSubmitting}>
		<Icon name="save" />
		{isEditing ? <FormattedMessage id="action.save" /> : <FormattedMessage id="action.createNewUser" />}
	</Button>
}
const DeleteUserButton: React.SFC<{id: string, username: string} & RouteComponentProps> = ({id, username, history}) => {
	return <ConfirmActionButton
		onConfirm={async () => {
			try {
				await deleteUser(id)
				Alert.success(<FormattedMessage id="message.userDeleteSuccess" />)
				history.push("/users")
			} catch(err) {
				// TODO if user is referenced in other tables error with explaination shall be shown
				Alert.error(<FormattedMessage id="message.userDeleteError" />)
			}
		}}
		actionButtonContent={<><Icon name="trash" /> <FormattedMessage id="action.deleteUser" /></>}
		floated="right"
		modalHeader={<><Icon name="trash" /> <FormattedMessage id="message.confirm.deleteUserHeader" values={{username}} /></>}
		modalContent={<>
		<FormattedMessage id="message.confirm.deleteUser" />
		</>}
  />
}
const DeleteUserButtonComponent = withRouter(DeleteUserButton)

const renderUserForm: React.SFC<FormikProps<UserFormValues>> = ({ handleSubmit, isSubmitting, values, setFieldValue }) => {

	const isEditing = !!values.userAuth.id;

	return (
		<div>
			<UserFormHeader editing={isEditing} values={values}/>
			<Form onSubmit={handleSubmit}>
				<UserFormAuthData editing={isEditing} values={values} />
				{/* <UserFormPersonalData editing={isEditing} values={values} onAccountTypeUpdate={setAccountType} /> */}
				<Divider />
				<div style={{display: "flex", alignItems: "center"}}>
					<SaveFormButton isEditing={isEditing} isSubmitting={isSubmitting} />
					<LoggedInUserExtractor>{user =>
					<div style={{flexGrow: 1}}>
					{user.id !== values.userAuth.id && values.userAuth.id && 
						/* FIXME Username must not be taken from form values - but rather from initial values. */
						<DeleteUserButtonComponent id={values.userAuth.id} username={values.userAuth.username} />}
					</div>}
					</LoggedInUserExtractor>
				</div>
			</Form>
		</div>
	)
}

export const UserForm: React.SFC<Props>= ({createUser, modifyUser, user}) => {
	return (<>
		<Formik onSubmit={async (values, formikActions: FormikActions<UserFormValues>) => {
				formikActions.setSubmitting(true)
				if (user) {
					try {
						await modifyUser(values)
						Alert.success(<FormattedMessage id="message.userModifiedSuccess" />);
					} catch (err) {
						if(err.response.status === 409) {
							Alert.warning(<FormattedMessage id="message.userAlreadyExists" />);
						} else if(err.response.status === 404) {
							Alert.warning(<FormattedMessage id="message.userNotFound" />);
						} else {
							Alert.error(<FormattedMessage id="message.userUpdateError" />);
						}
					}
				} else {
					try {
						await createUser(values)
						Alert.success(<FormattedMessage id="message.userCreatedSuccess" />);
					} catch (err) {
						if(err.response.status === 409) {
							formikActions.setErrors({userAuth: {username: "validation.emailAlreadyUsed"}})
							Alert.warning(<FormattedMessage id="message.userAlreadyExists" />);
						} else {
							Alert.error(<FormattedMessage id="message.userCreateError" />);
						}
					}
				}
				formikActions.setSubmitting(false)
			}}
			validate={validateUsers}
			initialValues={initiateValues(user)}
			render={renderUserForm}
			enableReinitialize
		/>
		</>
	)
}