import * as React from 'react'
import { Card, Header } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import { ImageUpload } from '../../api/imageUploads'
import Alert from 'react-s-alert';
import Moment from 'react-moment';
import { ImageDeleteButton } from './ImageDeleteButton';
import { ImageWithPlaceholder } from './ImageWithPlaceholder';
import moment from 'moment'
import { LoadingIndicator } from '../common/LoadingIndicator';
import UserAccessCondition from '../auth/UserAccessCondition';
import { UserType } from '../../api/usersAuth';
import { NoImagesPlaceholder } from './NoImagesPlaceholder'
import { bytesToHumanReadable } from '../../utils/units';

type Props = {
    images: ImageUpload[],
    isLoading: boolean
    fetchImagesAction: () => void
    deleteImageAction: (id: string) => Promise<void>
    onImageChoose?: (image: ImageUpload) => void
}

const ImageMetadataDisplay: React.SFC<{image: ImageUpload}> = ({image}) => {
    return <>
        <small>
        Wgrano przez: {image.createdBy}{", "}
        <time
            dateTime={image.created}
            title={moment(image.created).format('LLL')}
        >
            <Moment fromNow date={image.created}/>
        </time>
        </small>
        <small style={{display: "block"}}>
        Rozmiar: <span title={`${image.sizeBytes} B`}>{bytesToHumanReadable(image.sizeBytes)}</span>
        </small>
        <small style={{display: "block"}}>
        Wymiary: {image.width}x{image.height} px
        </small>
        <small style={{display: "block"}}>
        Typ obrazka: {image.mimeType}
        </small>
    </>
}

export class ImageList extends React.Component<Props, {}> {
    componentDidMount() {
        this.props.fetchImagesAction()
    }

    render() {
        const { images, isLoading } = this.props
        const clickable = typeof this.props.onImageChoose !== 'undefined'
        const onCardClick = clickable ? (
            (image: ImageUpload) => (e: React.MouseEvent<HTMLAnchorElement>) => {
                if(this.props.onImageChoose) {
                    this.props.onImageChoose(image)
                }
        }
        ) : undefined

        return (
            <>
                {isLoading && <LoadingIndicator />}
                {!isLoading && images.length === 0 && <NoImagesPlaceholder />}
                {!isLoading && images.length > 0 && <>
                    <Card.Group itemsPerRow={5} doubling>
                        {images && images.map((image, index) => {
                        return (
                        <Card key={index} onClick={onCardClick && onCardClick(image)} >
                            <ImageWithPlaceholder className="ui image" src={image.fileUrl} download={image.originalFileName} />
                            
                            <Card.Content style={{lineHeight: "1.15em"}}>
                                <Header
                                    className="truncate"
                                    style={{marginBottom: ".2em"}}
                                    title={image.originalFileName}
                                >
                                    {image.originalFileName}
                                </Header>
                                <Card.Meta>
                                    <ImageMetadataDisplay image={image} />
                                </Card.Meta>
                            </Card.Content>
                            <UserAccessCondition showOnlyTo={UserType.Admin}>
                                {!this.props.onImageChoose && <Card.Content extra>
                                    <ImageDeleteButton
                                        onDelete={async () => {
                                                try {
                                                    await this.props.deleteImageAction(image.id)
                                                    Alert.success(<FormattedMessage id="message.imageDeleted" />)
                                                } catch(err) {
                                                    Alert.error(<FormattedMessage id="message.imageDeleteError" />)
                                                }
                                        }}
                                        imageUrl={image.fileUrl}
                                    />
                                </Card.Content>}
                            </UserAccessCondition>
                        </Card>
                        )
                        })}
                    </Card.Group>
                </>
                }
            </>
        )
    }
}