import { FormikErrors, setIn } from "formik"
import { Camera } from "../../../api/players";

export const validateCamera = (values: Camera) => {
    let errors: FormikErrors<Camera> = {}
    if(!values.title) {
        errors.title = "validation.requiredField"
    }
    values.sources.forEach((s, i) => {
        if(s.type === 'stream') {
            if(!s.streamId) {
                errors = setIn(errors, `sources[${i}].streamId`, "validation.requiredField")
            }
        }
        if(s.type === 'custom-url') {
            if(!s.hlsStreamUrl) {
                errors = setIn(errors, `sources[${i}].hlsStreamUrl`, "validation.requiredField")
            }
        }
    })
    if(!values.sources || values.sources.length === 0) {
        // Shall never happen
        errors.sources = [{
            name: "validation.requiredField"
        }]
    }

    values.widgets.forEach((w, i) => {
        if(w.type === 'image') {
            if(!w.imageUrl) {
                errors = setIn(errors, `widgets[${i}].imageUrl`, "validation.widgetImageRequired")
            }
        }
    })

    return errors
}