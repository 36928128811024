import React from 'react'
import { Group } from '../../api/groups';
import { connect } from 'react-redux';
import { ApplicationState } from '../../reducers';
import { fetchGroupsAction } from '../../actions/groups';

type State = {
    loading: boolean
}

type Props = {
    groups: Group[],
    fetchGroups: () => Promise<void>
    children: (groups: Group[]) => React.ReactNode
}

export class GroupDataProvider extends React.Component<Props, State> {
    state: State = {
        loading: true
    }

    async componentDidMount() {
        if( this.props.groups.length === 0 ) {
            await this.props.fetchGroups();
        }
        this.setState({
            loading: false
        })
    }
    render() {
        return this.props.children(this.props.groups);
    }
}

export default connect(({groups}: ApplicationState) => ({ groups: groups.data }), { fetchGroups: fetchGroupsAction })(GroupDataProvider)