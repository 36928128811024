import React, { useState } from 'react'
import { Form, Checkbox, CheckboxProps, Input, TextArea, Dropdown, Icon, Popup } from 'semantic-ui-react'
import { FormattedMessage as FM } from 'react-intl';
import {  FieldProps, getIn } from 'formik'
import classNames from 'classnames'
import './Inputs.css'
import zxcvbn from 'zxcvbn';
import { ValidationMessage } from './ValidationMessage';

export const usernameInput: React.SFC<FieldProps<string>>= ({ field, form: {touched, errors}}) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return (
        <FM id="label.username" >
        {placeholder => (<Form.Field error={!!error}>
            <label>{placeholder}</label>
            <Input
                {...field}
                fluid
                error={!!error}
                autoFocus
            />
            <ValidationMessage error={error} />
        </Form.Field>)}
        </FM>
    )
}

export const passwordInput: React.SFC<FieldProps<string>>= ({ field, form: {errors, touched}, ...rest }) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return (
    <FM id="label.password" >
    {
    placeholder => (<Form.Field error={!!error}>
        <label>{placeholder}</label>
        <Input
            {...field}
            fluid
            type='password'
            error={!!error}
            {...rest}
        />
        <ValidationMessage error={error} />
    </Form.Field>)}
    </FM>
    )
}

const ShowHideIcon: React.SFC<{onClick: () => void, show: boolean}> = ({onClick, show}) => 
<FM id="label.showHidePassword" >
    {text => 
    <Icon   
    name={show ? 'eye slash' : 'eye'}
    onClick={onClick}
    link
    title={text} />
}
</FM>;

/**
 * Password input with show / hide value and calculating the password strength
 */
export const PasswordShowHideInput: React.FC<FieldProps<string> & { tooltip?: React.ReactNode}>= ({ tooltip, field, form: {errors, touched}, ...rest }) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)

    // show / hide password value
    const [show, setShow] = useState(false);
    // password score / password strength
    const [score, setScore] = useState(0 as number || null);

    // calculate the password strength
    const passwordStrength = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === ''){
            setScore(null);
        } else {
            let pw = zxcvbn(event.target.value);
            setScore(pw.score);
        }
    }

    return (
    <FM id="label.password" >
    {
    placeholder => (<Form.Field error={!!error}>
        <div className="field password-input-container">
            <label>{placeholder} {
                tooltip &&<Popup trigger={<Icon name='info circle' style={{cursor: "help"}}/>} position='top center' inverted>{tooltip}</Popup>}</label> 
                <Input
                    name={field.name}
                    fluid
                    type={show ? 'text' : 'password'}
                    error={!!error}
                    placeholder={placeholder}
                    {...field}
                    {...rest}
                    onChange={(event) => {
                        field.onChange(event)
                        passwordStrength(event);
                    }}
                    icon={<ShowHideIcon onClick={() => setShow(!show)} show={show} />}
                />
            {(!error && field.value) && <FM id={`label.passwordStrength.${score}`}>
                {text => <span className="password-strength" title={text.toString()} data-score={score} />}
            </FM>}
            <ValidationMessage error={error} />
        </div>
    </Form.Field>)}
    </FM>
    )
}

export const textInput: React.SFC<FieldProps<string> & { label?: string, disabled?: boolean}> = ({ label, field, disabled, form: {errors, touched}, ...rest }) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return (
    <FM id={`label.field.${label ? label : field.name}`} >
    {
    label => (
        <Form.Field  error={!!error}>
            <label style={{color: disabled ? "grey" : undefined}}>{label}</label>
            <Input
                {...field}
                placeholder={label}
                disabled={disabled}
                {...rest}
            />
            <ValidationMessage error={error} />
        </Form.Field>
    )}</FM>)
}

export const prefixedTextInput: React.SFC<FieldProps<string> & { label?: string, disabled?: boolean, placeholder?: string, prefix: string, tooltip?: React.ReactNode}> =
    ({ label, prefix, field, disabled, placeholder, tooltip, form: {errors, touched}, ...rest }) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return <FM id={`label.field.${label ? label : field.name}`}>
        {labelText =>
    <Form.Field  error={!!error}>
            <label style={{color: disabled ? "grey" : undefined}}>
                {labelText}
                {tooltip &&
                <>{" "}<Popup trigger={<Icon name='info circle' style={{cursor: "help"}}/>} position='top center' inverted>{tooltip}</Popup></>}
            </label>
            <div
                className={classNames("ui labeled input", {"error": !!error})}
            >
                <div className="ui label">
                    {prefix}
                </div>
                <input
                    {...field}
                    type="text"
                    placeholder={placeholder}
                    disabled={disabled}
                    {...rest}
                />
            </div>
            <ValidationMessage error={error} />
        </Form.Field>}
    </FM>
}
export const emailInput: React.SFC<FieldProps<string> & { label?: string}> = ({ label, field, form: {errors, touched}, ...rest }) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return (
    <FM id={`label.field.${label ? label : field.name}`} >
    {label => (
        <Form.Field  error={!!error}>
            <label>{label}</label>
            <Input
                type="email"
                {...field}
                placeholder={label}
                {...rest}
            />
            <ValidationMessage error={error} />
        </Form.Field>
    )}
    </FM>
    )
}

export const numberInput: React.SFC<FieldProps<number> & { label?: string}> = ({ label, field, form: {errors, touched} }) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return (
    <FM id={`label.field.${label ? label : field.name}`} >
    {
    label => (
        <Form.Field  error={!!error}>
            <label>{label}</label>
            <Input
                {...field}
                type="number"
                placeholder={label}
            />
            <ValidationMessage error={error} />
        </Form.Field>
    )}
    </FM>
    )
}

export const simpleNumberInput: React.SFC<FieldProps<number> & { label?: string}> = ({ label, field, form: {errors, touched}, ...rest }) => {
    return (
        <Input
        {...field}
        type="number"
        placeholder={label}
        {...rest}
    />
    )
}


export const textArea: React.SFC<FieldProps<string> & { label?: string}> = ({ label, field, form: {errors, touched} }) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return (
    <FM id={`label.field.${label ? label : field.name}`} >
    {
    label => (
        <Form.Field  error={!!error}>
            <label>{label}</label>
            <TextArea
                {...field}
                style={{height: '150px'}}
                placeholder={label}
            />
            <ValidationMessage error={error} />
        </Form.Field>
    )}
    </FM>
    )
}

export const checkbox: React.SFC<FieldProps<string> & {tooltip?: React.ReactNode, label?: string, warning?: React.ReactNode, toggle: boolean}> =
    ({ tooltip, warning, label, field, form: {setFieldValue}, toggle = true }) => {
    return (
    <FM id={`label.field.${label ? label : field.name}`} >
    {
    label => (
        <Form.Field>
            <Checkbox
                 toggle={toggle}
                 label={label}
                 checked={field.value}
                 onChange={(e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) =>
                    setFieldValue(field.name, data.checked)
                 }
            />
            {
                tooltip &&
                <>
                    {" "}<Popup trigger={<Icon name='info circle' style={{cursor: "help"}}/>} position='top center' inverted>{tooltip}</Popup>
                </>
            }
            {
                warning &&
                <>
                    {" "}<Popup trigger={<Icon name='exclamation triangle' color="yellow" style={{cursor: "help"}}/>} position='top center' inverted>{warning}</Popup>
                </>
            }
        </Form.Field>
    )}
    </FM>
    )
}

export const inlineDropdown: React.SFC<FieldProps<string> & {
    onChange: (newValue?: boolean | number | string | (boolean | number | string)[]) => void,
    options: {
        value: string
        label: string
    }[]
}> = ({ options, field, form: { setFieldValue}, onChange }) => {
    return <Dropdown
        inline
        options={options}
        name={field.name}
        value={field.value}
        onChange={(e, data)  => {
            setFieldValue(field.name, data.value)
            if(onChange) {
                onChange(data.value)
            }
        }}
    />
}

export const dropdown: React.SFC<FieldProps<string> & {
    label?: string,
    options: {
        value: string
        text: string
    }[],
    disabled?: boolean,
    clearable?: boolean,
    loading?: boolean,
    multiple?: boolean
}> = ({ label, options, loading, field, disabled, clearable, multiple, form: { setFieldValue, errors, touched} , ...rest}) => {
    const error = getIn(touched, field.name) && getIn(errors, field.name)
    return   <FM id={`label.field.${label ? label : field.name}`} >
    {
    label => (
    <Form.Field>
        <label style={{color: disabled ? "gray" : undefined}}>{label}</label>
        <Dropdown
            selection
            clearable={clearable}
            options={options}
            name={field.name}
            value={multiple ? (field.value as any[]).map(v => v.id) : field.value}
            onChange={((e, data)  => {
                if(multiple) {
                    const newValue =  (data.value as any[]).map(v => { return {id: v} })
                    setFieldValue(field.name, newValue)
                } else {
                    setFieldValue(field.name, data.value)
                }
            })}
            disabled={disabled}
            loading={loading}
            multiple={multiple}
            {...rest}
        />
        <ValidationMessage error={error} />
    </Form.Field>)}
    </FM>
}