import React from 'react'
import Axios from 'axios';
import { Field } from 'formik';
import { dropdown } from '../form/Inputs';
import { FormattedMessage } from 'react-intl';
import { Group } from '../../api/groups';

type Props = {
    name: string
}

type State = {
    groups: Group[],
    loading: boolean
}

export class GroupDropdown extends React.Component<Props, State> {
    state: State = {
        groups: [],
        loading: true
    }

    async componentDidMount() {
        try {
            const response = await Axios.get("/api/groups")
            this.setState({
                groups: response.data, loading: false
            })
        } catch(error) {
            
        }
        this.setState({
            loading: false
        })
    }
    render() {
        const options = [
            ...this.state.groups.map(u => ({
            text: u.name,
            value: u.id
        }))]

        return <FormattedMessage id="label.noGroups">
            {placeholderText =>
            <Field
                placeholder={placeholderText}
                name={this.props.name}
                options={options}
                component={dropdown}
                clearable
                loading={this.state.loading}
                multiple
                closeOnChange
            />}
        </FormattedMessage>
    }
}