import * as React from 'react';
import Axios from 'axios';
import { FormattedMessage as FM } from 'react-intl';
import { Header, Button, Icon, Card, Label, Popup, Message, Divider } from 'semantic-ui-react';
import { LoadingIndicator } from '../common/LoadingIndicator';
import moment from 'moment';
import Alert from 'react-s-alert';
import { getIn } from 'formik';
import Moment from 'react-moment';
import { buildPlayerUrl } from '../../configuration';
import { AsyncActionCaller } from '../common/AsyncActionCaller';
import { replaceInData } from '../../utils/utils';
import { ScheduledBroadcast, fetchBroadcasts } from '../../api/scheduledBroadcast';
import { ScheduledBroadcastHistoryList } from './ScheduledBroadcastHistoryList';
import { take } from 'lodash'
import { Camera } from '../../api/players';
import { Link } from 'react-router-dom';
import { ConfirmActionButton } from '../common/ConfirmActionButton';

type State = {
    data: ScheduledBroadcast[],
    historyData: ScheduledBroadcast[],
    loading: boolean,
    loadingHistory: boolean
    limit?: number
}

type Props = {
    player?: Camera
    hideHistory?: boolean
    limit?: boolean
}

export function displayBroadcastDuration(schedule: ScheduledBroadcast, fullDate: boolean = false) {
    const startTime = moment(schedule.startTime);
    const endTime = moment(schedule.endTime);
    const endTimeSameDay = startTime.isSame(endTime, 'day')
    const todayStartTime = !fullDate && startTime.isSame(new Date(), 'day') 
    const startTimeString = todayStartTime ? startTime.format("HH:mm") : startTime.format("LLL");
    return startTimeString + " — " + (endTimeSameDay ? endTime.format("HH:mm") : endTime.format("LLL"));
}
export class ScheduledBroadcastList extends React.Component<Props, State> {
    state: State = {
        loading: false,
        loadingHistory: false,
        data: [],
        historyData: [],
        limit: this.props.limit ? 1 : undefined
    }

    async componentDidMount() {
        this.loadData()
        if(!this.props.hideHistory) {
            this.loadHistoryData()
        }
    }

    async loadData() {
        this.setState({
            loading: true
        })
        try {
            let broadcasts;
            if(this.props.player) {
                broadcasts = await fetchBroadcasts({playerId: this.props.player.id})
            } else {
                broadcasts = await fetchBroadcasts()
            }
            this.setState({
                data: broadcasts
            });
        } catch (error) {
            Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
        }
        this.setState({
            loading: false
        });
    }

    async loadHistoryData() {
        this.setState({
            loadingHistory: true
        })
        try {
            const broadcasts = await fetchBroadcasts({history: true})
            this.setState({
                historyData: broadcasts
            });
        } catch (error) {
            Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
        }
        this.setState({
            loadingHistory: false
        });
    }

    async deleteBroadcastSchedule(id: string) {
        try {
            await Axios.delete<ScheduledBroadcast[]>("/api/scheduled-broadcast/" + id )
            this.setState({
                data: this.state.data.filter(s => s.id !== id)
            })
        } catch(error) {
            // TODO 404 vs 500
            Alert.error(<FM id="message.systemErrorActionFailed" />);
        }
    }

    async startEarlyBroadcastSchedule(id: string) {
        try {
            const startedSchedule = await Axios.put<ScheduledBroadcast>("/api/scheduled-broadcast/" + id + "/start" )
            this.setState({
                data: replaceInData(this.state.data, startedSchedule.data)
            })
        } catch(error) {
            if(getIn(error, "response.status") === 406) {
                Alert.warning(<FM id="message.scheduledBroadcastEarlyStartConflict"  />)
            } else {
                Alert.error(<FM id="message.systemErrorActionFailed" />)
            }
        }
    }

    async stopEarlyBroadcastSchedule(id: string) {
        try {
            const stoppedSchedule = await Axios.put<ScheduledBroadcast>("/api/scheduled-broadcast/" + id + "/stop" )
            this.setState({
                data: this.state.data.filter(s => s.id !== stoppedSchedule.data.id),
                historyData: [stoppedSchedule.data, ...this.state.historyData]
            })
        } catch(error) {
            Alert.error(<FM id="message.systemErrorActionFailed" />)
        }
    }

    renderScheduledBroadcasts(broadcasts: ScheduledBroadcast[]) {
        return <Card.Group doubling>
        {broadcasts.map((schedule => {
            const now = moment();
            const late = schedule.state === 'scheduled' && moment(schedule.startTime).isSameOrBefore(now)
        return <Card fluid key={schedule.id}>
                <Card.Content>
                {schedule.player.pathAlias && <Card.Meta className="right floated"><code >/{schedule.player.pathAlias}</code></Card.Meta>}
                    <Header as="h4" style={{display: "inline"}}>
                        <span>
                        {schedule.stateChangeSource === 'forced' && 
                        <Popup trigger={ <Icon name="paper plane" />} content={<FM id="label.forcedSchedule" />} inverted position="top center" />}
                        {schedule.stateChangeSource === 'scheduled' && 
                        <Popup trigger={ <Icon name="clock outline" />} content={<FM id="label.onTimeSchedule" />} inverted position="top center" />}
                        {late &&  <Label size="mini" color="blue" style={{marginLeft: 0, marginRight: "0.4em"}}><FM id="label.startingSoon" /></Label>}
                        <FM id="label.scheduledBroadcast" /> {schedule.state === 'scheduled' && 
                        <>{!late && <Moment  fromNow date={schedule.startTime}/>}</>}
                        {!this.props.player && <>: {schedule.player.title}</>}
                        </span>
                    </Header>
                    <Card.Meta>
                        {displayBroadcastDuration(schedule)}
                        {schedule.state === 'started' && <>
                            {" "}(<FM id="label.endTime" /><Moment date={schedule.endTime} fromNow />)
                        </>}
                    </Card.Meta>
                </Card.Content>
                <Card.Content>
                    {schedule.recordStream && <Label size="tiny">
                        <Icon color="red" name="circle" /> <FM id="label.field.recordStream" />
                    </Label>}
                    {schedule.disablePlayerOnEnd && <Label size="tiny">
                        <Icon color="blue" name="power" /> <FM id="label.field.disablePlayerOnEnd" />
                    </Label>}
                </Card.Content>
                <Card.Content extra>
                <Button size="mini" primary as={Link} to={`/schedules/${schedule.id}?playerId=${schedule.player.id}`} >
                    <Icon name="pencil" />
                    <FM id="action.edit" />
                </Button>
                {schedule.state === 'started' && <>
                <AsyncActionCaller action={() => this.stopEarlyBroadcastSchedule(schedule.id)}>
                    {(onClick, isLoading) =>
                    <Button size="mini" color="red" basic onClick={onClick} loading={isLoading}>
                        <Icon name="stop circle" />
                        <FM id="action.finishEarly" />
                    </Button>}
                </AsyncActionCaller>
                <Button as="a" size="mini" href={buildPlayerUrl(`/${schedule.player.pathAlias || schedule.player.id}`)} target="_blank">
                    <Icon name="tv" />
                    <FM id="label.cameraLink" />
                </Button>
                </>}
                {schedule.state === 'scheduled' && <>
                    <AsyncActionCaller action={() => this.startEarlyBroadcastSchedule(schedule.id)}>
                        {(onClick, isLoading) => <Button size="mini" color="green" onClick={onClick} loading={isLoading} >
                            <Icon name="play circle" />
                            <FM id="action.startEarly" />
                        </Button>}
                    </AsyncActionCaller>
                </>}
                <ConfirmActionButton
                    onConfirm={async () => await this.deleteBroadcastSchedule(schedule.id)}
                    confirmText="action.delete"
                    modalHeader={<FM id="message.deleteScheduleHeader" />}
                    actionButtonContent={<>
                        <Icon name="trash" />
                        <FM id="action.delete" />
                    </>}
                />
                </Card.Content>
            </Card>
        }))}
    </Card.Group>
    }

    render() {
        const runningBroadcasts = this.state.data.filter(s => s.state === 'started');
        const scheduledBroadcasts = this.state.data.filter(s => s.state === 'scheduled');
        const finishedBroadcasts = this.state.historyData;
        const shouldLimit = this.state.limit && scheduledBroadcasts.length > this.state.limit
        const limit = this.state.limit || scheduledBroadcasts.length
        return <>
        {(this.state.loading && !this.state.loadingHistory) && <LoadingIndicator />}
        {(this.state.loading && this.state.loadingHistory) && <LoadingIndicator />}
        {!this.state.loading && runningBroadcasts.length === 0 && scheduledBroadcasts.length === 0 && <>
        <Message info size="tiny">
            <Message.Header><Icon name="info circle" /><FM id="message.noScheduledBroadcastHeader" /></Message.Header>
            <FM id="message.noScheduledBroadcastContent" />
        </Message>
        </>}
        {runningBroadcasts.length > 0 && <>
            <Header as="h4">
                <FM id="title.scheduledBroadcastsStarted" />
            </Header>
            {this.state.data && this.renderScheduledBroadcasts(runningBroadcasts)}
        </>}
        {scheduledBroadcasts.length > 0 && <>
            <Header as="h4">
                <FM id="title.scheduledBroadcastsSoon" />
            </Header>
            {this.state.data && this.renderScheduledBroadcasts(take(scheduledBroadcasts, limit))}
        </>}
        {(!this.state.loading && this.state.loadingHistory) && <LoadingIndicator />}
        {(!this.props.hideHistory && finishedBroadcasts.length > 0) && <>
            <Header as="h4">
                <FM id="title.scheduledBroadcastsFinished" />
            </Header>
            <ScheduledBroadcastHistoryList historyData={finishedBroadcasts} />
        </>}
        {shouldLimit && <>
        <Divider hidden fitted />
        <Button onClick={() => this.setState({limit: scheduledBroadcasts.length})} basic size="tiny">
            <FM id="label.showMoreSchedules" />
        </Button>
        </>}
        </>
    }
}