import { Vod } from "../api/vods";
import { VodActionType } from "../actions/vods";
import { AnyAction, Reducer } from "redux";

export type VodsState = {
    data: Vod[]
    isLoading: boolean
    initiallyLoaded: boolean
}

const initialState: VodsState = {
    data: [],
    isLoading: false,
    initiallyLoaded: false
}

const reducer: Reducer<VodsState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case VodActionType.fetchRequest:
            return {
                ...state,
                isLoading: true
            }
        case VodActionType.fetchError:
            return {
                ...state,
                isLoading: false
            }
        case VodActionType.fetchSuccess:
            return {
                ...state,
                data: action.vods,
                isLoading: false,
                initiallyLoaded: true
            }
        case VodActionType.fetchSingleSuccess:
            const found = state.data.find(s => s.id === action.vod.id)
            return {
                ...state,
                data: found ? state.data.map(vod => {
                    if(vod.id === action.vod.id) {
                        return action.vod
                    } else {
                        return vod
                    }
                }) : [action.vod, ...state.data]
            }
      default:
        return state
    }
  }

export default reducer