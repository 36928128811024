import { FormikErrors } from "formik";
import { UserFormValues } from "./UserForm";

export const validateUsers = (user: UserFormValues) => {
	let errors: FormikErrors<UserFormValues> = {}

	if (!user.userAuth.username) {
		if (!errors.userAuth) errors.userAuth = {};
		errors.userAuth.username = "validation.requiredField";
	}

	// TODO validate username is email

	if(!user.userAuth.id) {
		if(!user.userAuth.password) {
			if (!errors.userAuth) errors.userAuth = {};
			errors.userAuth.password = "validation.requiredField";
		} else if (user.userAuth.password.length < 8) {
			if (!errors.userAuth) errors.userAuth = {};
			errors.userAuth.password = "validation.passwordAtLeast8Characters";
		}
	}

	if(["admin", "user"].indexOf(user.userAuth.type) === -1) {
		if (!errors.userAuth) errors.userAuth = {};
		errors.userAuth.type = "validation.unsupportedValue";
	}

	// if (!user.firstName) {
	// 	errors.firstName = "validation.requiredField"
	// }

	// if (!user.lastName) {
	// 	errors.lastName = "validation.requiredField"
	// }

	// if (!user.street) {
	// 	errors.street = "validation.requiredField"
	// }

	// if (!user.zipCode) {
	// 	errors.zipCode = "validation.requiredField"
	// }

	// if (!user.city) {
	// 	errors.city = "validation.requiredField"
	// }

	// if (user.accountType == UserAccountType.Company && !user.companyName) {
	// 	errors.companyName = "validation.requiredField"
	// }

	// if (user.accountType == UserAccountType.Company && !user.nip) {
	// 	errors.nip = "validation.requiredField"
	// }

	return errors

}