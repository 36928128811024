import * as React from 'react'
import { Field } from 'formik';
import { textArea } from '../../../form/Inputs';

export const PopupEdit = ({index}: {index: number}) => {
    return <>
    <Field
        name={`widgets[${index}].text`}
        component={textArea}
        label="widget.text"
    />
    </>
}
