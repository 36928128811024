import React from 'react'
import { CollapsibleSection } from '../common/CollapsibleSection'
import { FormattedMessage as FM } from 'react-intl'
import { Grid, Divider, Icon, Segment, Message } from 'semantic-ui-react'
import ReactPlayer from 'react-player'
import { Stream } from '../../api/streams'
import UserAccessCondition from '../auth/UserAccessCondition'
import { UserType } from '../../api/usersAuth'

export const StreamOutputInfo: React.SFC<{stream: Stream, defaultVisible?: boolean}> = ({stream, defaultVisible}) => {
    return <CollapsibleSection title={<FM id="label.streamOutput" />} subtitle={<FM id="label.streamOutputDescription"  />} defaultVisible={defaultVisible}>
        <Grid columns='equal' stackable>
        <Grid.Row>
            <Grid.Column>
                <strong><FM id="label.field.streamPreview"/></strong>
                {stream.outputSecurity ?
                <Message info size="tiny">
                    <Message.Header>
                        <Icon name="exclamation circle" />
                        <FM id="message.unavailableForSecuredStream" />
                    </Message.Header>
                    <Message.Content>
                        <FM id="message.previewUnavailableForSecuredStreamContent" />
                    </Message.Content>
                </Message> :
                <ReactPlayer
                    url={stream.hlsPlaylistUrl}
                    controls
                    playing
                    width="100%"
                    style={{background: "black"}}
                />}
            </Grid.Column>
            <Grid.Column>
            <Segment attached="top">
                <strong>
                    <FM id="label.field.hlsPlaylistUrl"/>
                    {stream.outputSecurity && <Icon name="lock" />}
                </strong>
                <pre className="user-selectable-url">
                    {stream.outputSecurity ?
                    <FM id="message.unavailableForSecuredStream" /> :
                    stream.hlsPlaylistUrl}
                </pre>
                </Segment>
                <UserAccessCondition showOnlyTo={UserType.Admin}>
                    <Segment attached="bottom" secondary>
                    {/** debug info */}
                    <strong>Wowza address</strong>
                    <pre className="user-selectable-url">
                        {stream.wowzaInstallationUrl}
                    </pre>
                    <strong>Wowza application</strong>
                    {stream.wowzaDedicatedApplication && <>{" "}<Icon name="info circle" /> Dedicated app</>}
                    <pre className="user-selectable-url">
                        {stream.wowzaApplicationName}
                    </pre>
                    <strong>Wowza stream file name</strong>
                    <pre className="user-selectable-url">
                        {stream.wowzaStreamFileName}
                    </pre>
                    </Segment>
                </UserAccessCondition>
            </Grid.Column>
            </Grid.Row>
        </Grid>
        <Divider hidden />
    </CollapsibleSection>
}
