import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import { ApplicationState } from '../reducers'
import { UserType } from '../api/usersAuth';

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
  authenticatedSelector: (state: ApplicationState) => state.auth.data !== null,
  authenticatingSelector: (state: ApplicationState) => state.auth.isLoading,
  wrapperDisplayName: 'UserIsAuthenticated'
}

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  redirectPath: '/login'
})

export const userIsAdminRedir = connectedRouterRedirect({
  redirectPath: '/404',
  allowRedirectBack: false,
  authenticatedSelector: (state: ApplicationState) => state.auth.data !== null && state.auth.data.type === UserType.Admin,
  wrapperDisplayName: 'UserIsAdmin'
})

const userIsNotAuthenticatedDefaults = {
  authenticatedSelector: (state: ApplicationState) => state.auth.data === null,
  wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)

const REDIRECT_AFTER_LOGIN = "/"

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    ...userIsNotAuthenticatedDefaults,
    redirectPath: (state: ApplicationState, ownProps: any) =>
    locationHelper.getRedirectQueryParam(ownProps) || REDIRECT_AFTER_LOGIN,
    allowRedirectBack: false
})