import React from 'react'
import { Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ConfirmActionButton } from '../common/ConfirmActionButton';
import { RouteComponentProps, withRouter } from 'react-router';
import { deleteCameraAction } from '../../actions/players';
import { connect } from 'react-redux';
import Alert from 'react-s-alert';

type Props = {
  id: string
  deletePlayer: (id: string) => Promise<void>
} & RouteComponentProps

const CameraDeleteButton: React.SFC<Props> = ({id, deletePlayer, history}) => {
  const buttonContent = <>
    <Icon name="trash" />
    <FormattedMessage id="action.removeCamera" />
  </>

  return <ConfirmActionButton
    floated="right"
    onConfirm={async () => {
      try {
        await deletePlayer(id)
        Alert.success(<FormattedMessage id="message.playerDeleteSuccess" />)
        history.push("/players")
      } catch(err) {
        Alert.error(<FormattedMessage id="message.playerDeleteError" />)
      }
    }}
    actionButtonContent={buttonContent}
    modalHeader={<><Icon name="trash" /> <FormattedMessage id="message.confirm.deleteCameraHeader" /></>}
    modalContent={<FormattedMessage id="message.confirm.deleteCamera" />}
  />
}

export default connect(null, { deletePlayer: deleteCameraAction } )(withRouter(CameraDeleteButton))