import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../reducers';
import { createUserAction, modifyUserAction, fetchUserAction } from '../../actions/user';
import { UserForm } from './UserForm';
import { User } from '../../api/users';
import { LoadingIndicator } from '../common/LoadingIndicator';

type Props = {
    createUser: (user: User) => Promise<User>
    modifyUser: (user: User) => Promise<User>
    getUser: (id: string) => Promise<User>
    user?: User
} & RouteComponentProps<{id?: string}>

type State = {
    isLoading: boolean,
    user?: User
}

class UserFormComponent extends React.Component<Props, State> {

    state: State = {
        isLoading: false
    }

    createUser = async (user: User) => {
        const userResponse =  await this.props.createUser(user)
        this.props.history.push(`/users`)
        return userResponse
    }

    modifyUser = async (user: User) => {
        const userResponse =  await this.props.modifyUser(user)
        this.props.history.push(`/users`)
        return userResponse
    }

    async componentDidMount() {
        const { id } = this.props.match.params
        if(id) {
            this.setState({
                isLoading: true
            })
            try {
                const user = await this.props.getUser(id)
                this.setState({
                    user
                })
            } catch(err){

            }
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingIndicator />
        }
        return (<UserForm
            createUser={this.createUser}
            modifyUser={this.modifyUser}
            user={this.state.user}
        />)
    }
}
function mapStateToProps(state: ApplicationState, ownProps: Props) {
    const id = ownProps.match.params.id
    return {
        isLoading: state.users.isLoading,
        user: state.users.data.find(u => u.userAuth && u.userAuth.id === id)
    }
}

export default connect(mapStateToProps, {
    getUser: fetchUserAction,
    createUser: createUserAction,
    modifyUser: modifyUserAction
})(UserFormComponent)