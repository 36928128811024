import Axios from "axios";
import { UserAuth } from "./usersAuth";

export type Stream = {
    id?: string
    name: string
    streamUrl: string
    hlsPlaylistUrl?: string
    enabled: boolean
    outputSecurity: boolean
    createdBy?: string
    created?: string
    modifiedBy?: string
    modified?: string
    operator?: UserAuth

    // TODO maybe separate subsection in this object for wowza info?
    wowzaInstallationUrl?: string
    wowzaApplicationName?: string
    wowzaStreamFileName?: string
    wowzaDedicatedApplication?: boolean
}

export type StreamStatus = {
    alive: boolean
    uptimeSeconds: number,
    currentBitrateIn: number,
    connectionCount: number
}

export const fetchStreams = async () => {
    const response = await Axios.get<Stream[]>("/api/streams")
    return response.data
}

export const fetchStream = async (id: string) => {
    const response = await Axios.get<Stream[]>(`/api/streams/${id}`)
    return response.data
}

export const fetchStreamStatus = async (id: string) => {
    const response = await Axios.get<StreamStatus>(`/api/streams/${id}/status`)
    return response.data
}

export const createStream = (stream: Stream) => {
    return Axios.post<Stream>("/api/stream", stream).then(response => response.data)
}

export const modifyStream = (stream: Stream) => {
    return Axios.put<Stream>(`/api/stream/${stream.id}`, stream).then(response => response.data)
}

export const deleteStream = (id: string) => {
    return Axios.delete(`/api/stream/${id}`).then(response => response.status === 204)
}