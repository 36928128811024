import React, { useState } from 'react'
import { Header, Button, Form, Icon, Divider } from 'semantic-ui-react';
import { FormattedMessage as FM } from 'react-intl';
import { textInput, checkbox } from '../form/Inputs';
import { Field, Formik, FormikProps, FormikActions } from 'formik';
import { Stream } from '../../api/streams';
import Alert from 'react-s-alert';
import { ResourceAuthorInfo } from '../common/ResourceAuthorInfo';
import './StreamForm.css'
import { CollapsibleSection } from '../common/CollapsibleSection';
import Url from 'url-parse'
import { validateStream } from './validateStream';
import UserAccessCondition from '../auth/UserAccessCondition';
import { UserType } from '../../api/usersAuth';
import { withRouter, RouteComponentProps } from 'react-router';
import { OperatorDropdown } from '../common/OperatorDropdown';
import LoggedInUserExtractor from '../auth/LoggedInUserExtractor';

type StreamSecurityPaneProps = {
    onSecurityUpdate: () => void
}

const StreamSecurity: React.SFC<StreamSecurityPaneProps> = ({onSecurityUpdate}) => {
    return <CollapsibleSection
        title={<FM id="label.streamSecurity" />}
        subtitle={<FM id="label.streamSecurityDescription" />}
        defaultVisible={true}
    >
        <Form.Group widths="equal">
            <LoggedInUserExtractor>
                {user =>
                <>
            <Field
                name="streamUsername"
                component={textInput}
                onBlur={() => onSecurityUpdate()}
                disabled={user.type !== UserType.Admin}
            />
            <Field
                name="streamPassword"
                component={textInput}
                onBlur={() => onSecurityUpdate()}
                disabled={user.type !== UserType.Admin}
            />
            </>}
            </LoggedInUserExtractor>
        </Form.Group>
    </CollapsibleSection>
}

const StreamFormHeader = ({editing, values}: {editing: boolean, values: Stream}) => {
    return (
        <Header>
            {editing ?
            <FM id="title.editingStream" /> :
            <FM id="title.newStream" />}
            {editing &&
            <Header.Subheader>
                <p style={{margin: 0}}>
                    <ResourceAuthorInfo values={values} />
                </p>
            </Header.Subheader>}
        </Header>
    )
}

const SaveFormButton: React.SFC<{isSubmitting: boolean}> = ({isSubmitting}) => {
    return <Button positive type="submit" loading={isSubmitting} disabled={isSubmitting}>
        <Icon name="save" />
        <FM id="action.save" />
    </Button>
}

const initialValues: StreamFormValues = {
    name: "My stream",
    streamUrl: "",
    streamUsername: "",
    streamPassword: "",
    enabled: true,
    outputSecurity: false
}

const initiateValues = (stream?: Stream) => {
    if(!stream) {
        return initialValues
    } else {
        const values: StreamFormValues = { ...stream }
        const parsed = Url(stream.streamUrl)
        if(parsed.username && parsed.password) {
            values.streamUsername = parsed.username
            values.streamPassword = parsed.password
        }
        return values
    }
}

const DeleteStreamButton = ({onDelete, history}: {onDelete: () => Promise<void>} & RouteComponentProps) => {
    const [deleting, setDeleting] = useState(false)
    const deleteAction = async () => {
        setDeleting(true)
        try {
            await onDelete()
            Alert.success(<FM id="message.streamDeleteSuccess" />)
            history.push("/streams")
        } catch(err) {
            Alert.error(<FM id="message.streamDeleteError" />)
        }
        setDeleting(false)
    }
    return <Button basic color="red" floated="right" size="tiny" type="button" loading={deleting} onClick={deleteAction} >
        <Icon name="trash" />{" "}
        <FM id="action.deleteStream" />
    </Button>
}

const DeleteStreamButtonComponent = withRouter(DeleteStreamButton)


const renderStreamForm: React.SFC<FormikProps<StreamFormValues>> = ({ handleSubmit, isSubmitting, values, setFieldValue }) => {
    const {streamUsername: username, streamPassword: password, streamUrl} = values
    const parseStreamUrlAndUpdateAuth = () => {
        if(streamUrl) {
            const parsed = Url(streamUrl)
            if(parsed.username && parsed.password) {
                setFieldValue("streamUsername", parsed.username)
                setFieldValue("streamPassword", parsed.password)
            } else if(username && password) {
                const withUser = parsed.set('username', username)
                const withAuth = withUser.set('password', password)
                setFieldValue("streamUrl", withAuth.toString())
            }
        }
    }

    const updateUrlBasedOnAuth = () => {
        if(streamUrl && username && password) {
            const parsed = Url(streamUrl)
            const withUser = parsed.set('username', username)
            const withAuth = withUser.set('password', password)
            setFieldValue("streamUrl", withAuth.toString())
        }
    }

    const isEditing = !!values.id

    return (
        <div>
            <StreamFormHeader editing={isEditing} values={values}/>
            <Form onSubmit={handleSubmit}>
                <Field
                    name="name"
                    component={textInput}
                />
                <UserAccessCondition showOnlyTo={UserType.Admin}>
                    <OperatorDropdown name="operator.id" />
                </UserAccessCondition>
                <LoggedInUserExtractor>
                    {user =>
                    <Field
                        name="streamUrl"
                        component={textInput}
                        onBlur={parseStreamUrlAndUpdateAuth}
                        disabled={user.type !== UserType.Admin}
                    />}
                </LoggedInUserExtractor>
                <LoggedInUserExtractor>
                {user => {
                    // If user cannot modify security and it is not present do not show empty security section.
                    return (user.type === UserType.Admin || (values.streamPassword || values.streamUsername)) ?
                    <StreamSecurity onSecurityUpdate={updateUrlBasedOnAuth}/> : null
                }}
                </LoggedInUserExtractor>
                <Divider />
                <Field
                    name="outputSecurity"
                    component={checkbox}
                    tooltip={<FM id="label.outputSecurityTooltip" />}
                />
                <Divider />
                <SaveFormButton isSubmitting={isSubmitting} />
            </Form>
        </div>
    )
}

type Props = {
    stream?: Stream
    createStream: (stream: Stream) => Promise<Stream>
    modifyStream: (stream: Stream) => Promise<Stream>
    deleteStream: (id: string) => Promise<void>
}

type SyntheticStreamSecurity = {
    streamUsername?: string
    streamPassword?: string
}

export type StreamFormValues = Stream & SyntheticStreamSecurity

export const StreamForm: React.SFC<Props>= ({createStream, modifyStream, stream, deleteStream}) => {
    return (<>
        <Formik
            onSubmit={async (values, formikActions: FormikActions<StreamFormValues>) => {
                formikActions.setSubmitting(true)
                try {
                    if(stream) {
                        await modifyStream(values)
                        Alert.success(<FM id="message.streamModified" values={{name: values.name}} />)
                    } else {
                        await createStream(values)
                        Alert.success(<FM id="message.streamCreated" values={{name: values.name}} />)
                    }
                } catch(err) {
                    console.error(err)
                    Alert.error(<FM id="message.streamSaveError" />)
                }
                formikActions.setSubmitting(false)
            }}
            validate={validateStream}
            initialValues={initiateValues(stream)}
            render={renderStreamForm}
            enableReinitialize
        />
        <UserAccessCondition showOnlyTo={UserType.Admin}>
            {stream && stream.id && <DeleteStreamButtonComponent onDelete={() => deleteStream(stream.id!!)} />}
        </UserAccessCondition>
        </>
    )
}