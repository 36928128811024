import * as React from 'react'
import { Input, Label, Icon } from 'semantic-ui-react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import TimeField from 'react-simple-timefield';
import classnames from 'classnames';
import {padStart} from 'lodash'
import 'react-day-picker/lib/style.css';
import './DateTimeSelector.css'
import { Modifier as DayPickerModfier } from 'react-day-picker/types/common';
import { FormattedMessage } from 'react-intl';

function formatDate(date: Date, format: string, locale: string) {
    return moment(date)
      .locale(locale)
      .format(Array.isArray(format) ? format[0] : format);
}

function parseDate(str: string, format: string, locale: string) {
    const m = moment(str, format, locale, true);
    if (m.isValid()) {
      return m.toDate();
    }
    return undefined;
}

type Props = {
    value: Date,
    hasEndTime?: boolean
    setValue: (value: Date) => void,
    disabledDays?: DayPickerModfier,
    error?: string
    disabled?: boolean
    size?: 'mini' | 'small' | 'large' | 'big' | 'huge' | 'massive'
}

export const DateTimeSelector: React.SFC<Props> = ({value, error, setValue, hasEndTime, disabled, disabledDays = {before: new Date()}, size }) => {
    function onTimeChange(event: React.ChangeEvent, timeValue: string) {
        const [hoursStr, minutesStr] = timeValue.split(":");
        const hour = Number(hoursStr)
        const minute =  Number(minutesStr)
        const startDate = moment(value).set({hour, minute})
        setValue(startDate.toDate())
    }
    const time = `${padStart(value.getHours().toString(), 2, "0")}:${padStart(value.getMinutes().toString(), 2, "0")}`;
    return <>
    <div>
        <DayPickerInput
            value={value}
            dayPickerProps={{
                localeUtils: MomentLocaleUtils,
                locale: "pl",
                disabledDays,
                fromMonth: new Date(),
                selectedDays: value
            }}
            formatDate={formatDate}
            parseDate={parseDate}
            format="LL"
            onDayChange={day => {
                // undefined if user entered some invalid date into input.
                if(day) {
                    day.setHours(value.getHours());
                    day.setMinutes(value.getMinutes());
                    setValue(day);
                }
            }}
            component={(props: any) => <Input disabled={disabled} iconPosition="left" icon="calendar" {...props} size={size} />}
        />
        <TimeField
            className={classnames("TimeField", {"has-end-time": hasEndTime})}
            value={time}
            onChange={onTimeChange}
            input={<Input
                value={time}
                size={size}
                iconPosition="left"
                icon="clock outline"
                disabled={disabled}
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    const count = event.key === 'ArrowUp' ? 1 : event.key === 'ArrowDown' ? -1 : 0;
                    if(count !== 0) {
                        let newValue = moment(value).add(count, 'minute')
                        setValue(newValue.toDate())
                        return false;
                    }
                }}
            />} 
        />
        </div>
        {error && <Label basic color='red' style={{marginTop: ".4em", marginLeft: 0}}>
            <Icon name="exclamation circle" />
            {<FormattedMessage id={error} />}
        </Label>}
    </>
}