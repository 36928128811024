import React from 'react'
import LoginForm from './LoginForm';
import { connect } from 'react-redux';
import { login, checkInitialLogin } from '../../actions/auth'
import { ApplicationState } from '../../reducers';

type Props = {
    login: (username: string, password: string) => void
    checkInitialLogin: () => void
    userError: boolean
    serverError: boolean
    expired: boolean
    isInitiallyLoading: boolean
    loggedOut: boolean
    isLoading: boolean
}

class LoginComponent extends React.Component<Props, {}> {
    async componentDidMount() {
        if(!this.props.expired && !this.props.loggedOut) {
            this.props.checkInitialLogin()
        }
    }

    render() {
        return <LoginForm {...this.props}/>
    }
}

export default connect(
    (state: ApplicationState) => (
        { userError: state.auth.userError,
          serverError: state.auth.serverError,
          expired: state.auth.expired,
          isInitiallyLoading: state.auth.isInitiallyLoading,
          isLoading: state.auth.isLoading,
        loggedOut: state.auth.loggedOut }),
    { login, checkInitialLogin })(LoginComponent)