import thunk from 'redux-thunk'
import { createStore, applyMiddleware, Middleware } from 'redux'
import createRootReducer from './reducers';

export const configureStore = () => {
    const middlewares: Middleware[] = [thunk]
    if (process.env.NODE_ENV === `development`) {
        const { logger } = require(`redux-logger`);
        middlewares.push(logger);
    }
    const store = createStore(
        createRootReducer(),
        applyMiddleware(...middlewares)
    )
    return store
}