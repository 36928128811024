import { Dispatch } from "redux";
import { loginUser, logoutUser, getLoggedInUser } from '../api/usersAuth'

export enum UserAuthActionType {
    createSuccess = "webcam/user/CREATE_SUCCESS",
    createError = "webcam/user/CREATE_ERROR",
    modifySuccess = "webcam/user/MODIFY_SUCCESS",
    modifyError = "webcam/user/MODIFY_ERROR",
    loginStart = "webcam/user/LOGIN_START",
    loginSuccess = "webcam/user/LOGIN_SUCCESS",
    loginUnauthorized = "webcam/user/LOGIN_UNAUTHORIZED",
    loginError = "webcam/user/LOGIN_ERROR",
    initialLoginStart = "webcam/user/INITIAL_LOGIN_START",
    initialLoginSuccess = "webcam/user/INITIAL_LOGIN_SUCCESS",
    initialLoginUnauthorized = "webcam/user/INITIAL_LOGIN_UNAUTHORIZED",
    initialLoginError = "webcam/user/INITIAL_LOGIN_ERROR",
    logoutSuccess = "webcam/user/LOGOUT_SUCCESS",
    invalidateAuth = "webcam/user/INVALIDATE_AUTH"
}

export const login = (username: string, password: string) => {
    return async function(dispatch: Dispatch) {
        try {
            dispatch({
                type: UserAuthActionType.loginStart
            })
            const reponse = await loginUser(username, password)
            dispatch({
                type: UserAuthActionType.loginSuccess,
                user: reponse.data
            })
        } catch(err) {
            if(err.response && err.response.status === 401) {
                dispatch({
                    type: UserAuthActionType.loginUnauthorized
                })
                return
            }
            dispatch({
                type: UserAuthActionType.loginError
            })
        }
    }
}

export const logout = () => {
    return async function(dispatch: Dispatch) {
        try {
            await logoutUser()
            dispatch({
                type: UserAuthActionType.logoutSuccess
            })
        } catch(err) {
            // TODO
        }
    }
}

/**
 * Action called when any of the HTTP calls after user
 * is logged in fail with status code 401.
 */
export const invalidateAuth = () => {
    return {
        type: UserAuthActionType.invalidateAuth
    }
}

export const checkInitialLogin = () => {
    return async function(dispatch: Dispatch) {
        try {
            dispatch({
                type: UserAuthActionType.initialLoginStart
            })
            const user = await getLoggedInUser()
            if(!user.username || !user.type) {
                // TODO should not happen
                dispatch({
                    type: UserAuthActionType.initialLoginError
                })
                return
            }
            dispatch({
                type: UserAuthActionType.initialLoginSuccess,
                user
            })
        } catch(err) {
            if(err.response && err.response.status === 401) {
                dispatch({
                    type: UserAuthActionType.initialLoginUnauthorized
                })
                return
            }
            dispatch({
                type: UserAuthActionType.initialLoginError
            })
        }
    }
}
