import React from 'react'
import { Header, List, Message, Icon, Button, Divider } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment'
import { bytesToHumanReadable, formatDurationSeconds } from '../../utils/units';
import { LoadingIndicator } from '../common/LoadingIndicator';
import Alert from 'react-s-alert';
import { ConfirmActionButton } from '../common/ConfirmActionButton';
import { recordingsList, deleteRecording, RecordingsResponse } from '../../api/stream-recording';
import { ErrorMessage } from '../common/Error';

type State = {
    data?: RecordingsResponse
    loading: boolean
    error?: Error
}

export class RecordingsListComponent extends React.Component<{id: string}, State> {
    state: State = {
        loading: true
    }

    async componentDidMount() {
      this.fetchRecordings()
    }

    fetchRecordings = async () => {
      this.setState({
        loading: true
      })
      try {
        const response = await recordingsList(this.props.id);
        this.setState({
          data: response.data,
          error: undefined,
          loading: false
        })
      } catch (error) {
        this.setState({
          error,
          loading: false
        })
      }
    }

    deleteRecording = async (recordingFileName: string) => {
      try {
        await deleteRecording(this.props.id, recordingFileName);
        this.fetchRecordings()
      } catch(error) {
        Alert.error(<FormattedMessage id="message.recordingDeleteError" />)
      }
    }

    renderList() {
      if(this.state.loading) {
        return <LoadingIndicator />;
      }
      if(this.state.error) {
        return <ErrorMessage error={this.state.error} messageId="message.recordingsListError" onRetry={this.fetchRecordings} />;
      }
      if(this.state.data) {
        const { recordings, recordingsDeleteDaysThreshold} = this.state.data;

        return <>
              {recordings.length === 0 && <Message size="tiny">
                <Message.Header>
                  <Icon name="info circle" /><FormattedMessage id="message.noRecordings" />
                </Message.Header>
                <FormattedMessage id="message.recordingsAreDeleted" values={{ days: recordingsDeleteDaysThreshold }}/>
              </Message>}
              {recordings.length > 0 && <>
        <List>
          {recordings.map((recordingGrop, index) => <List.Item key={index}>
              <List.Icon name="folder" />
              <List.Content>
                <List.Header>
                  <FormattedMessage id="label.recordingFromDate" /> {moment(recordingGrop.fileGroupId).format("LLL")}
                </List.Header>
                <List.Description>
                  <FormattedMessage id="label.stream.recordingSizeSum" /> {bytesToHumanReadable(recordingGrop.sizeSum)}{", "}
                  <FormattedMessage id="label.stream.recordingDuration" /> {formatDurationSeconds(recordingGrop.durationSecondsSum)}
                </List.Description>
                <div className="downloads-list" style={{paddingTop: '.35em'}}>
                  {recordingGrop.files.map((file, index) =>  {
                    const labelTitle = bytesToHumanReadable(file.size) + ", " + formatDurationSeconds(file.durationSeconds);
                  return  <div key={index} className="ui tiny basic buttons"> <Button
                      title={labelTitle}
                      as="a"
                      href={`/api/streams/${this.props.id}/download/${file.fileNameWithoutPrefix}`} target="_blank">
                    <Icon name="download" /> Plik {index+1}
                  </Button>
                  <ConfirmActionButton
                      size="tiny"
                      onConfirm={() =>  this.deleteRecording(file.fileNameWithoutPrefix)}
                      negative={false}
                      icon
                      actionButtonContent={<Icon name="trash" />}
                      modalHeader={<FormattedMessage id="message.deleteRecordingModalHeader" />}
                      modalContent={<FormattedMessage id="message.deleteRecordingModalContent" />}
                  />
                  </div>
                })}
                </div>  
              </List.Content>
            </List.Item>)}
          </List>
          <Message size="tiny">
            <Icon name="info" color="grey" />
            <FormattedMessage id="message.recordingsAreDeleted" values={{ days: recordingsDeleteDaysThreshold }}/>
          </Message>
          <Divider hidden />
          </>}
          </>;
        }
    }

    render() {
        return  <>
          <Header size="small" className="fitted" id="recordings-list">
            <FormattedMessage id="title.recordingsList" />
          </Header>
          {this.renderList()}
        </>
    }
}