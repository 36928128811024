import React from 'react'
import ReactDropzone, { DropEvent } from 'react-dropzone';
import { Icon, Loader, Progress } from 'semantic-ui-react';
import Axios from 'axios';
import Alert from 'react-s-alert';
import { FormattedMessage as FM } from 'react-intl';
import { Vod } from '../../api/vods';

type State = {
    uploading: boolean,
    progressPercent?: number
    uploadedFileName?: string
}

type Props = {
    onUploadComplete: (vod: Vod) => void
}

const initialState: State = {
    uploading: false
}

const SUPPORTED_VOD_UPLOAD_TYPES = ["video/mp4"]
const MAX_VOD_UPLOAD_SIZE_MBYTES = 20240
const MAX_VOD_UPLOAD_SIZE_BYTES = MAX_VOD_UPLOAD_SIZE_MBYTES * 1024 * 1024

type DropFilesEventHandler = (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => void

export class VodUploadInput extends React.Component<Props, State> {

    state: State = initialState
    
    onDrop: DropFilesEventHandler = async (files) => {
        const formData = new FormData();

        if (files.length === 0) {
            Alert.error(<FM
                id="message.noSupportedFileSelected"
            />);
            return
        }

        if (files.length > 1) {
            Alert.error(<FM
                id="message.multipleFilesSelectedError"
            />);
            return
        }

        const file = files[0]


        if(file.size > MAX_VOD_UPLOAD_SIZE_BYTES) {
            Alert.error(<FM
                id="message.fileUploadTooLarge"
                values={{maximumSize: MAX_VOD_UPLOAD_SIZE_MBYTES + "MB"}}
            />);
            return
        }

        if (!SUPPORTED_VOD_UPLOAD_TYPES.find(t => t === file.type)) {
            Alert.error(<FM
                id="message.fileUploadUnsupportedFormat"
                values={{supportedFormat: SUPPORTED_VOD_UPLOAD_TYPES.join(", ")}}
            />);
            return
        }

        formData.append("video", file)

        try {
            this.setState({
                uploading: true,
                uploadedFileName: file.name
            })
            const response = await Axios.post<Vod>("/api/vod-upload", formData, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    console.log(`Upload percent: ${percentCompleted}%`)
                    this.setState({
                        progressPercent: percentCompleted
                    })
                }
            })
            
            Alert.success(<FM id="message.vodUploadComplete" />);
            this.props.onUploadComplete(response.data)
            this.setState({
                progressPercent: 100
            })
        } catch(err) {
            Alert.error(<FM id="message.systemErrorActionFailed" />);
        }
        this.setState({
            uploading: false,
            progressPercent: undefined,
            uploadedFileName: undefined
        })
    }

    render() {
        return <FM id="action.uploadVod" >
        {uploadImageText =>
        <ReactDropzone
            multiple={false}
            accept={SUPPORTED_VOD_UPLOAD_TYPES.join(", ")}
            onDrop={this.onDrop}
            disabled={this.state.uploading}
        >
        {({getRootProps, getInputProps}) => {
            return <div
                {...getRootProps()}
                style={{
                    cursor: 'pointer',
                    marginTop: 0,
                    flex: 1
                }}
                className="ui green segment image-upload"
                title={uploadImageText.toString()}
            >
                <input {...getInputProps()} />
                {!this.state.uploading && <div>
                    <Icon name="upload" />
                    {uploadImageText}
                </div>}
                {(this.state.progressPercent !== undefined && this.state.progressPercent !== 100) &&
                <>
                <FM id="label.uploadingVodFileInProgress" values={{fileName: this.state.uploadedFileName}}>
                    {label =>
                <Progress
                    size="small"
                    percent={this.state.progressPercent}
                    indicating
                    label={label}
                    progress
                />}
                </FM>
                </>}
                {(this.state.uploading && (this.state.progressPercent === 100)) && <div style={{
                     display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Loader active inline/>
                <span style={{marginTop: ".4em"}}>
                    <FM id="message.processingVODMetadata" />...
                </span>
                </div>}
            </div>
        }}
        </ReactDropzone>
        }
        </FM>
    }
}