import * as React from 'react'
import { Button, Icon, Card, Label } from 'semantic-ui-react'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'
import { Stream } from '../../api/streams';
import { ResourceAuthorInfo } from '../common/ResourceAuthorInfo';
import UserAccessCondition from '../auth/UserAccessCondition';
import { UserType } from '../../api/usersAuth';
import { StreamStatusSubscriber } from './StreamStatusSubscriber';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

export const StreamItem: React.SFC<{stream: Stream}> = ({stream}) => {
    const labels = streamDescriptionLabels(stream)
    return <Card fluid key={stream.id}>
    <Card.Content>
        <Card.Header
            as={Link}
            to={`/streams/${stream.id}`}
            title={stream.name}
        >
            {stream.name}
        </Card.Header>
        <Card.Meta>
            <ResourceAuthorInfo values={stream} />
        </Card.Meta>
        <Card.Description style={{clear: "none"}} className="camera-actions">
        <UserAccessCondition showOnlyTo={UserType.Admin}>
            <Button as={Link} to={`/streams/${stream.id}`} color="blue" size="mini">
                <Icon name="pencil" />
                <span className="mobile-hide">
                <FM id="action.edit" />
                </span>
            </Button>
        </UserAccessCondition>
        <Button as={Link} to={`/streams/${stream.id}/recording`} size="mini" basic>
            <Icon name="circle" color="red" />
            <span className="mobile-hide">
                <FM id="label.streamRecording" />
            </span>
        </Button>
        <StreamStatusSubscriber id={stream.id!!}>
        {(status,error) => {
            const broken = status && !status.alive
            const color: SemanticCOLORS = status ? status.alive ? "green" : "red" : "grey"
           return <>
           <Button as={Link} to={`/streams/${stream.id}/status`} size="mini" basic >
            <Icon name="heartbeat" color={color} />
            <span className="mobile-hide">
                <FM id="label.status" />
            </span>
        </Button>
        {broken && <Label pointing="left" size="mini">
            <FM id="label.stream.status.dead" />!
        </Label>}
        </>}}
        </StreamStatusSubscriber>
    </Card.Description>
    </Card.Content>
    <UserAccessCondition showOnlyTo={UserType.Admin}>
        {labels.length > 0 && <Card.Content extra  className="mobile-hide">
            {labels}
        </Card.Content>}
    </UserAccessCondition>
</Card>
}

const streamDescriptionLabels = (stream: Stream) => {
    const labels: JSX.Element[] = [];

    if(stream.operator && stream.operator.id) {
        labels.push(<Label size="tiny" basic>
                <Icon name="user circle" />
            <FM id="label.operator" /><Label.Detail>{stream.operator.username}</Label.Detail>
        </Label>);
    }
    return labels;
}