import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../reducers';
import { Stream } from '../../api/streams';
import { StreamForm } from './StreamForm';
import { createStreamAction, fetchStreamsAction, deleteStreamAction, modifyStreamAction } from '../../actions/stream';
import { LoadingIndicator } from '../common/LoadingIndicator';

type Props = {
    fetchStreams: () => void
    modifyStream: (stream: Stream) => Promise<Stream>
    deleteStream: (id: string) => Promise<void>
    createStream: (stream: Stream) => Promise<Stream>
    stream?: Stream
    isLoading: boolean
} & RouteComponentProps<{id?: string}>

class StreamFormComponent extends React.Component<Props, {}> {
    
    createStream = async (stream: Stream) => {
        const streamResponse =  await this.props.createStream(stream)
        this.props.history.push(`/streams`)
        return streamResponse
    }

    modifyStream = async (stream: Stream) => {
        const streamResponse =  await this.props.modifyStream(stream)
        this.props.history.push(`/streams`)
        return streamResponse
    }

    deleteStream = async (id: string) => {
        await this.props.deleteStream(id)
        this.props.history.push(`/streams`)
    }

    componentDidMount() {
        if(this.props.match.params.id) {
            this.props.fetchStreams()
        }
    }
    render() {
        if(this.props.isLoading) {
            return <LoadingIndicator />
        }
        return (<StreamForm
            createStream={this.createStream}
            modifyStream={this.modifyStream}
            deleteStream={this.deleteStream}
            stream={this.props.stream}
        />)
    }
}
function mapStateToProps(state: ApplicationState, ownProps: Props) {
    const id = ownProps.match.params.id
    return {
        isLoading: state.streams.isLoading,
        stream: id ? state.streams.data.find(c => c.id === id) : undefined
    }
}
export default connect(mapStateToProps, {
    createStream: createStreamAction,
    modifyStream: modifyStreamAction,
    deleteStream: deleteStreamAction,
    fetchStreams: fetchStreamsAction
})(StreamFormComponent)