import Axios from 'axios';

export type ImageUpload = {
    id: string
    fileName: string
    originalFileName: string
    fileUrl: string
    thumbnail: string
    mimeType: string
    sizeBytes: number
    width: number
    height: number
    createdBy: string
    created: string
}

export const fetchImages = async () => {
    const reponse =  await Axios.get<{images: ImageUpload[]}>("/api/image")
    return reponse.data;
}

export const deleteImage = (id: string) => {
    return Axios.delete(`/api/image/${id}`)
}