import * as React from 'react'

import { Label, Icon, Card, Header } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { FormattedMessage as FM } from "react-intl"
import { Camera, PlayerSecurityType } from "../../api/players"
import { ResourceAuthorInfo } from '../common/ResourceAuthorInfo';
import CameraActionButtons from './PlayerActionButtons'
import { UserType } from '../../api/usersAuth'
import UserAccessCondition from '../auth/UserAccessCondition'
import LoggedInUserExtractor from '../auth/LoggedInUserExtractor'

const playerDescriptionLabels = (player: Camera) => {
    const labels: JSX.Element[] = [];

    if (player.operator && player.operator.id) {
        labels.push(<UserAccessCondition showOnlyTo={UserType.Admin}><Label size="tiny" basic key={1}>
                <Icon name="user circle" />
            <FM id="label.operator" /><Label.Detail>{player.operator.username}</Label.Detail>
        </Label></UserAccessCondition>);
    }

    if (player.securityType !== PlayerSecurityType.None) {
        labels.push(<UserAccessCondition showOnlyTo={UserType.Admin}><Label size="tiny" color="blue" key={2} as={Link} to={`/players/${player.id}?accesses`}>
            <Icon name="lock" />
            <FM id="label.playerSecurity" />
        </Label></UserAccessCondition>)
    }

    if (!player.enabled) {
        labels.push(<Label size="tiny" color="grey" key={3}>
            <Icon name="power off" />
            <FM id="label.playerDisabled" />
        </Label>)
    }

    if (player.autoplay) {
        labels.push(<Label size="tiny" key={4}>
            <Icon name="play circle outline" />
            <FM id="label.field.autoplay" />
        </Label>)
    }

    if (player.hasSound) {
        labels.push(<Label size="tiny" key={5}>
            <Icon name="volume up" />
            <FM id="label.field.hasSound" />
        </Label>)
    }

    if (player.widgets && player.widgets.length > 0 ){
        labels.push(<Label size="tiny" key={6}>
            <Icon name="plug" />
            <FM id="label.widgets" />
            <Label.Detail>{player.widgets.length}</Label.Detail>
        </Label>)
    }

    if(player.locationEnabled) {
        labels.push(<Label size="tiny" key={7}>
            <Icon name="globe" />
            <FM id="label.geolocation" />
        </Label>)
    }

    if(player.groups.length > 0) {
        player.groups.forEach((value, index) => 
            labels.push(<Label size="tiny" key={`group-${index}`} tag>
                {value.name}
            </Label>)
        )
    }
    return labels.filter(label => label !== null);
}

type Props = {
    simple?: boolean
    player: Camera
}

export const CameraItem: React.SFC<Props> = ({player, simple}) => {
    const labels = playerDescriptionLabels(player)
    return (
    <Card fluid>
        <Card.Content>
            {player.pathAlias && <Card.Meta className="right floated"><code >/{player.pathAlias}</code></Card.Meta>}
            <LoggedInUserExtractor>
                {user =>
                user.type === UserType.Admin ?
                <Header
                    style={{display: "inline"}}
                    as={Link}
                    to={`/players/${player.id}`}
                    title={player.title}
                >
                    {player.title}
                </Header> :
                <Header
                    style={{display: "inline"}}
                    title={player.title}
                >
                    {player.title}
                </Header>}
            </LoggedInUserExtractor>
            {!simple && <UserAccessCondition showOnlyTo={UserType.Admin}>
                <Card.Meta><ResourceAuthorInfo values={player} />
                    {player.groups.length > 0 && <p className="hidden mobile-only" style={{marginTop: "-3px"}}>
                        <small>
                            Grupy: {player.groups.map(g => g.name).join(", ")}
                        </small>
                    </p>}
            </Card.Meta>
            </UserAccessCondition>}
        </Card.Content>
            <Card.Content>
            <Card.Description style={{clear: "none"}}>
                <CameraActionButtons player={player} hideButtons={["schedule"]} />
            </Card.Description>
        </Card.Content>
        {labels.length > 0 && <Card.Content extra  className="mobile-hide">
            {labels}
        </Card.Content>}
    </Card>)
}