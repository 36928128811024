const configuration = {
    playerDomainName: (window as any)["_env_"].PLAYER_DOMAIN_NAME,
    scheme: (window as any)["_env_"].SCHEME || "https"
}

export default configuration

export function buildPlayerUrl(path: string) {
    const url =  configuration.scheme + "://" + configuration.playerDomainName + path
    return url
}