import { GroupsActionType } from "../actions/groups";
import { Group } from "../api/groups";

export type GroupState = {
    data: Group[]
}

const initialState: GroupState = {
    data: []
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GroupsActionType.fetchSuccess:
            return {
                ...state,
                data: action.groups
            }
      default:
        return state
    }
  }

export default reducer