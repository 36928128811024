import * as React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { Button, Icon, Divider, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { get } from 'lodash'
import { ScheduledBroadcast } from '../../api/scheduledBroadcast';
import { displayBroadcastDuration } from './ScheduledBroadcastList';

type State = {
}

type Props = {
    historyData: ScheduledBroadcast[]
}

export class ScheduledBroadcastHistoryList extends React.Component<Props, State> {
    renderScheduledBroadcastsTable(broadcasts: ScheduledBroadcast[]) {
        return <Table striped >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        <FM id="label.field.player.id" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FM id="label.scheduledBroadcastTime" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {broadcasts.map(schedule => <Table.Row>
                <Table.Cell>
                    {schedule.player.title} {schedule.player.pathAlias && <>(<code>/{schedule.player.pathAlias}</code>)</>}
                </Table.Cell>
                <Table.Cell>
                    {displayBroadcastDuration(schedule, true)}
                </Table.Cell>
                <Table.Cell>
                    {schedule.recordStream && <Button
                        size="tiny"
                        basic
                        as={Link}
                        to={`/streams/${get(schedule, "player.sources[0].streamId")}/recording#recordings-list`}
                    >
                        <Icon name="circle" color="red" />
                        <FM id="action.goToRecordings" />
                    </Button>}
                </Table.Cell>
            </Table.Row>)}
        </Table>
    }

    render() {
        const finishedBroadcasts = this.props.historyData;
        return <>
        {finishedBroadcasts.length > 0 && <>
            {this.renderScheduledBroadcastsTable(finishedBroadcasts)}
        </>}
        <Divider hidden />
        </>
    }
}