import * as React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { Header, Button, Icon } from 'semantic-ui-react';
import { ScheduledBroadcastList } from './ScheduledBroadcastList';
import { Link } from 'react-router-dom';

export class ScheduledBroadcastListPage extends React.Component<{}, {}> {
    render() {
        return <>
            <Header><FM id="title.scheduledBroadcast" /></Header>
            <Button as={Link} to="/schedules/new" color="green">
                <Icon name="clock outline" />
                <FM id="action.newScheduledBroadcast" />
            </Button>
            <ScheduledBroadcastList />
        </>
    }
}