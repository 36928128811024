import * as React from 'react'

import { Header, Button, Segment, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import UserAccessCondition from '../auth/UserAccessCondition';
import { UserType } from '../../api/usersAuth';

export const NoStreamsPlaceholder: React.SFC = () => {
    return (
    <Segment placeholder >
        <Header icon style={{color: "darkgrey"}}>
            <Icon name='hdd outline' />
            <FormattedMessage id="placeholder.noDataFound" />
        </Header>
        <UserAccessCondition showOnlyTo={UserType.Admin}>
            <Button as={Link} to="/streams/new" color="green">
                <Icon name="plus" />
                <FormattedMessage id="action.newStream" />
            </Button>
        </UserAccessCondition>
    </Segment>
    )
}