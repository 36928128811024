import React from 'react'
import { Button, Form, Header, Segment, Icon, Message, Loader } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl';
import { Formik, Field, FormikProps } from 'formik'
import { usernameInput, passwordInput } from '../form/Inputs';
import { validate } from './validateLoginForm';
import { ErrorMessage } from '../common/Error';
import { DelayChildren } from '../common/DelayedChildren';
import './LoginForm.css'

export type LoginValues = {
    username: string
    password: string
}

type Props = {
    login: (username: string, password: string) => void
    userError: boolean
    serverError: boolean
    expired: boolean
    isInitiallyLoading: boolean
    isLoading: boolean
}

const initialValues: LoginValues = {
    username: "",
    password: ""
}

const InitiallyLoading: React.SFC = () => {
    return <Message icon info>
        <Loader inline active style={{marginRight: "1em"}} />
        <Message.Content>
            <Message.Header><FormattedMessage id="message.loggingInHeader" /></Message.Header>
            <FormattedMessage id="message.loggingIn" />
        </Message.Content>
    </Message>
}

const LoginForm: React.SFC<Props> = ({ login, userError, serverError, expired, isInitiallyLoading, isLoading }) => {
    const renderMessage = () => {
        if (userError) return <ErrorMessage messageId="message.incorrectLogin" />

        if (serverError) return <ErrorMessage messageId="message.loginServerError" />

        if (expired) return <Message info>
            <Icon name='info circle' />{" "}
            <FormattedMessage id="message.loginExpired" />
        </Message>

        return null
    }
    return <div className='login-form'>
    {/* Style has to be inline to be
      * applied only to LoginForm page.
      */}
    <style>{`
      body {
        background: #f7f7f7;
      }
      #root, .login-form {
        height: 100%;
      }
      .login-form {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}</style>
    <div style={{ maxWidth: 450, width: '90%' }}>
        {isInitiallyLoading ? <DelayChildren delay={200}><InitiallyLoading /></DelayChildren> : <>
        <FormattedMessage id="title.loginScreen" >
            {title => (
                <Header as='h2' color='blue' style={{fontWeight: "normal", textAlign: "center"}}>
                    <Icon name="video play" color="blue" />
                    {title}
                </Header>
            )}
        </FormattedMessage>
        <Segment stacked style={{padding: "1.8em 1.8em 2em 1.8em"}}>
        <Formik
            onSubmit={async ({ username, password }, { setSubmitting }) => {
                setSubmitting(true)
                try {
                    login(username, password)
                }catch(err){}
                setSubmitting(false)
            }}
            validate={validate}
            initialValues={initialValues}
            render={({
                handleSubmit
            }: FormikProps<LoginValues>) => {
                return (
                    <Form onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e)
                    }} error={userError || serverError}>
                            <Field
                                name="username"
                                component={usernameInput}
                            />
                            <Field
                                name="password"
                                component={passwordInput}
                            />
                            {renderMessage()}
                            <Button color='blue' fluid type="submit" loading={isLoading} disabled={isLoading}>
                                <FormattedMessage id="action.login" />
                            </Button>
                    </Form>
                )
            }}
        />
        </Segment>
        <footer>
            <FormattedMessage id="app.footerContent" values={{currentYear: new Date().getFullYear()}}/>
        </footer>
        </>}
    </div>
    </div>
}

export default LoginForm