import * as React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { IntlProvider, FormattedMessage } from 'react-intl'

import LoginComponent from './components/auth/LoginComponent';
import { Main } from './components/Main';
import { userIsNotAuthenticatedRedir, userIsAuthenticatedRedir } from './auth/utils';
import { Provider } from 'react-redux';
import { configureStore } from './configureStore';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import Alert from 'react-s-alert';
import { configureLocale } from './configureLocale';
import Helmet from 'react-helmet';

const Login = userIsNotAuthenticatedRedir(LoginComponent)
const Protected = userIsAuthenticatedRedir(Main)

const { language, messages } = configureLocale()
const store = configureStore()

export default () => {
    return (
    <>
    <Provider store={store}>
        <IntlProvider locale={language} messages={messages}>
            <>
            <FormattedMessage id="app.title">{titleText =>
                <Helmet>
                    <title>{titleText.toString()}</title>
                </Helmet>
            }</FormattedMessage>
            <Router>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route component={Protected} />
                </Switch>
            </Router>
            <Alert stack={{limit: 3}} effect="scale" position="bottom-right" preserveContext={true} />
            </>
        </IntlProvider>
    </Provider>
    </>
    )
}