import React from 'react'
import {  Icon, Form, Button, Segment, Label } from "semantic-ui-react";
import { Field, FieldArray, ArrayHelpers } from 'formik';
import { Camera, Widget, ImageWidget } from '../../../../api/players';
import { FormattedMessage } from 'react-intl';
import { inlineDropdown } from '../../../form/Inputs';
import { ImageEdit } from './ImageEdit';
import { LinkEdit } from './LinkEdit';
import { PopupEdit } from './PopupEdit';
import { CollapsibleSection } from '../../../common/CollapsibleSection';

const newWidget: () => Widget = () => ({
    type: "link",
    url: "",
    title: "",
    position: "top-right",
    imageUrl: "",
    imageSize: "tiny"
})
 
const widgetTypeOptions = [
    {
        text: <FormattedMessage id="label.widgetType.link" />,
        value: 'link'
    },
    {
        text: <FormattedMessage id="label.widgetType.image" />,
        value: 'image'
    },
    {
        text: <FormattedMessage id="label.widgetType.popup" />,
        value: 'popup'
    }
]

type Props = {
    values: Camera
}

export const WidgetsPane: React.SFC<Props> = ({values}) => {
    const title = <>
        <FormattedMessage id="label.widgets" />
        {!!values.widgets.length && <Label size="mini">{values.widgets.length}</Label>}
    </>
    return (
        <>
            <CollapsibleSection
                title={title}
                subtitle={<FormattedMessage id="label.widgetsDescription" />}
            >
                <FieldArray
                    name="widgets"
                    render={(arrayHelpers: ArrayHelpers) => {
                        return (
                        <>
                            <Form.Field>
                                <Button type="button" size="mini" positive onClick={() => arrayHelpers.push(newWidget())}>
                                    <Icon name="plus" />
                                    <FormattedMessage id="action.addNewWidget" />
                                </Button>
                            </Form.Field>
                            {values.widgets.length > 0 &&<Segment.Group>
                            {values.widgets.map((widget: Widget, index: number) => <Segment key={index}>
                                <Form.Field>
                                    <FormattedMessage id="label.widgetOfType" values={{widgetIndex: index+1}} />{" "}
                                    <Field
                                        name={`widgets[${index}].type`}
                                        component={inlineDropdown}
                                        options={widgetTypeOptions}
                                    />
                                    <Button
                                        negative
                                        basic
                                        compact
                                        floated="right"
                                        size="mini"
                                        type="button"
                                        onClick={() => arrayHelpers.remove(index)}
                                    >
                                        <Icon name="remove" />
                                        <FormattedMessage id="action.removeWidget" />
                                    </Button>
                                </Form.Field>
                                    {widget.type === "popup" && <PopupEdit index={index} />}
                                    {widget.type === "image" && <ImageEdit widget={widget as ImageWidget} index={index} />}
                                    {widget.type === "link" && <LinkEdit index={index} />}
                            </Segment>)}
                            </Segment.Group>}
                        </>
                        )
                    }}
                />
            </CollapsibleSection>
        </>
    )
}