import React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers';
import { UserAuth } from '../../api/usersAuth';

type Props = {
    auth: UserAuth | null
    children: (auth: UserAuth) => React.ReactNode
}

const LoggedInUserExtractor: React.SFC<Props> = ({auth, children}) => {
    return auth === null ? null : <>
    {children(auth)}
    </>
}

export default connect(({auth}: ApplicationState) => {
    return { auth: auth.data}
})(LoggedInUserExtractor)