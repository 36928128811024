import * as React from 'react'
import { Menu, Container, Dropdown, Icon, Label } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import LogoutComponent from './auth/LogoutComponent';
import { NavLink, Link } from 'react-router-dom';
import { LoggedInUsername } from './auth/LoggedInUsername';
import './Heading.css'
import UserAccessCondition from './auth/UserAccessCondition';
import { UserType } from '../api/usersAuth';

const whenPathMatches = (prefixes: string[]) => (_: any, location: { pathname: string}) => {
  return prefixes.map(p => location.pathname.startsWith(p)).find(p => p)
}

export const Heading = () => {
    return (
        <Menu fixed='top' borderless inverted color="blue">
        <Container>
          <Menu.Item as={Link} to="/" header>
            <Icon name="video play"/>
            <FormattedMessage id="app.title" />
            {process.env.NODE_ENV === 'development' && <Label pointing="left" size="mini" color="yellow">dev</Label>}
          </Menu.Item>
          <FormattedMessage id="title.scheduledBroadcast">
            {text =>
            <Menu.Item as={NavLink} to="/schedules" className="mobile-hide" title={text}>
              <Icon name="clock outline" />
              <span className="medium-hide">{text}</span>
            </Menu.Item>}
          </FormattedMessage>
          <FormattedMessage id="title.configuration">
            {text => <Menu.Item
              as={NavLink}
              to="/players"
              className="mobile-hide"
              title={text}
              isActive={whenPathMatches(["/players", "/streams", "/media", "/vod"])}
            >
              <Icon name="cog" />
              <span className="medium-hide">{text}</span>
            </Menu.Item>}
          </FormattedMessage>
          <UserAccessCondition showOnlyTo={UserType.Admin}>
            <FormattedMessage id="title.systemAdministration">
              {text =>
              <Menu.Item
                as={NavLink}
                to="/users"
                className="mobile-hide"
                title={text}
                isActive={whenPathMatches(["/users", "/groups"])}
              >
                <Icon name="key" />
                <span className="medium-hide">{text}</span>
              </Menu.Item>}
            </FormattedMessage>
          </UserAccessCondition>
        <Menu.Menu position='right' >
        <Dropdown item icon='bars' floating>
        <Dropdown.Menu>
            <Dropdown.Item as={NavLink} to="/schedules" className="hidden mobile-only">
              <Icon name="clock outline" />
              <FormattedMessage id="title.scheduledBroadcast" />
            </Dropdown.Item>
          <Dropdown.Item as={NavLink} to="/players" className="hidden mobile-only">
            <Icon name="tv" />
            <FormattedMessage id="title.cameraManagement" />
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to="/streams" className="hidden mobile-only">
          <Icon name="rss" />
            <FormattedMessage id="title.streamManagement" />
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to="/media" className="hidden mobile-only">
          <Icon name="picture" />
            <FormattedMessage id="title.imagesMangement" />
          </Dropdown.Item>
          <UserAccessCondition showOnlyTo={UserType.Admin}>
            <Dropdown.Item as={NavLink} to="/users" className="hidden mobile-only">
            <Icon name="key" />
              <FormattedMessage id="title.systemAdministration" />
            </Dropdown.Item>
          </UserAccessCondition>
          <Dropdown.Divider className="hidden mobile-only" />
          <Dropdown.Header>
            <FormattedMessage id="label.loggedInAs" /> <LoggedInUsername />
          </Dropdown.Header>
          <LogoutComponent className="dropdown item"/>
        </Dropdown.Menu>
      </Dropdown>
      </Menu.Menu>
        </Container>
      </Menu>
    )
}