import * as React from 'react'

import { Button, Modal, Icon, Divider, Segment, Header, List, Message, Label } from 'semantic-ui-react';
import { FormattedMessage as FM } from 'react-intl';
import Axios from 'axios';
import Alert from 'react-s-alert';
import { AsyncActionCaller } from '../common/AsyncActionCaller';
import { LoadingIndicator } from '../common/LoadingIndicator';
import moment from 'moment';
import { DateTimeSelector } from '../form/DateTimeSelector';
import { Field, Formik, FormikErrors } from 'formik';
import { simpleNumberInput } from '../form/Inputs';

type Props = {
    playerId: string
    modalOpen: boolean
}

type PlayerAccessToken = {
  id: string
  token: string
  expirationTime: string
}

type State = {
    loading: boolean
    playerAccesses: PlayerAccessToken[]
}

export const PlayerAccessesTokensModal: React.SFC<Props> = ({playerId, modalOpen}) => {
  const [open, setOpen] = React.useState(modalOpen);
  return <>
        <Button type="button" onClick={() => setOpen(true)} basic size="tiny">
            <Icon name="address card" />
            <FM id="label.accessList" />
        </Button>
          <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
          >
            <Modal.Header><FM id="label.accessList" /></Modal.Header>
            <Modal.Content>
                <PlayerAccesses playerId={playerId} />
            </Modal.Content>
          </Modal>
  </>
}

export class PlayerAccesses extends React.Component<{playerId: string}, State> {
    state: State = {
      loading: true,
      playerAccesses: []
    }

    async componentDidMount() {
      try {
        const response = await Axios.get<PlayerAccessToken[]>(`/api/player/${this.props.playerId}/token`)
        this.setState({
          playerAccesses: response.data,
          loading: false
        })
      } catch(err) { 
        Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
        this.setState({
          loading: false
        })
      }

    }

     generatePlayerAccess = async (count: number, expirationTime: Date) => {
        const response = await Axios.post<{ tokens: PlayerAccessToken[]}>(`/api/player/${this.props.playerId}/token`, {
          oneTimeUse: false,
          expirationTime: expirationTime.toISOString(),
          count: count
        });
        this.setState({
          playerAccesses: [
            ...this.state.playerAccesses,
            ...response.data.tokens
          ]
        })
    }

    async deletePlayerAccess(id: string) {
      try {
      await Axios.delete(`/api/player/${this.props.playerId}/token/${id}`)
      this.setState({
        playerAccesses: this.state.playerAccesses.filter(uA => uA.id !== id)
      })
      Alert.success(<FM id="message.playerAccessDeleteSuccess" />)
    } catch(error) {
      Alert.error(<FM id="message.systemErrorActionFailed" />)
    }
    }

    renderTokenGeneratorForm = () => {
      return <>
      <Formik
        initialValues={{
          count: 1,
          expirationTime: moment().add(1,'week').toDate()
        }}
        onSubmit={async (values, formikAtions) => {
          formikAtions.setSubmitting(true)
          try {
            await this.generatePlayerAccess(values.count, values.expirationTime)
          } catch(error) {
            Alert.error(<FM id="message.systemErrorActionFailed" />)
          }
          formikAtions.setSubmitting(false)
        }}
        validate={(values) => {
          const errors: FormikErrors<{count: number, expirationTime: Date}> = {}

          if (values.count > 100) {
            errors.count = "validation.toLargeCount";
          }
          return errors;
        }}
        render={({values, setFieldValue, setTouched, handleSubmit, submitForm, isSubmitting}) => {
          return <form style={{display: "inline-flex"}} onSubmit={handleSubmit}>
            <Field
              component={simpleNumberInput}
              name="count"
              min="1"
              max="100"
              size="mini"
              style={{width: "5rem"}}
            />
          <span style={{alignSelf: "center", margin: "0 .2em"}}>token ważny do</span>
          <div>
          <DateTimeSelector                             value={values.expirationTime}
                            setValue={(value) => {
                                setFieldValue("expirationTime", value)
                                setTouched({expirationTime: true })
                            }}
                            disabledDays={{
                                before: new Date()
                            }} size="mini" />
          </div>
          <Button type="button" color="green" size="mini" onClick={submitForm} loading={isSubmitting}>
            <Icon name="lock" />
            <FM id="action.generatePlayerAccessToken" />
          </Button>
        </form>
        }}
      />
      </>
      }

    render() {
      return (
        <>
          {this.renderTokenGeneratorForm()}
          <Button as="a" href={`/api/player/${this.props.playerId}/tokens.csv`} basic size="mini" floated="right">
            <Icon name="download" />
            <FM id="action.downloadAllTokensAsFile" />
          </Button>
          <Divider />
          {(!this.state.loading && this.state.playerAccesses.length === 0) &&
          <Segment placeholder >
            <Header icon style={{color: "darkgrey"}}>
                <Icon name='hdd outline' />
                <FM id="placeholder.noDataFound" />
            </Header>
          </Segment>}
          {this.state.loading && <LoadingIndicator />}
          <List relaxed divided>
          {this.state.playerAccesses.map((playerAccess) =>
            <List.Item key={playerAccess.id}>
                <List.Content>
                <span style={{userSelect: "all"}}>{playerAccess.token}</span>{" "}
                <Label size="tiny" color="grey"><FM id="label.expiresAt" /> {moment(playerAccess.expirationTime).format("LLL")}</Label>
                <AsyncActionCaller
                action={() => this.deletePlayerAccess(playerAccess.id)}
                >
                  {(onClick, loading) =>
                <Button
                  onClick={onClick}
                  type="button"
                  size="mini"
                  negative
                  basic
                  floated="right"
                  loading={loading}
                >
                  <Icon name="trash" />
                  <FM id="action.delete" />
                </Button>}
                </AsyncActionCaller>
                </List.Content>
              </List.Item>)}
          </List>
          <Message size="tiny" info>
            <Icon name="info circle" />
            <FM id="message.playerAccessOnlyWithDefinedCredentials" />
          </Message>
        </>
      )
    }
}
