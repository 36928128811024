import { Stream } from "../api/streams";
import { StreamActionType } from "../actions/stream";
import { AnyAction, Reducer } from "redux";

export type StreamsState = {
    data: Stream[]
    isLoading: boolean
    initiallyLoaded: boolean
}

const initialState: StreamsState = {
    data: [],
    isLoading: false,
    initiallyLoaded: false
}

const reducer: Reducer<StreamsState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case StreamActionType.fetchRequest:
            return {
                ...state,
                isLoading: true
            }
        case StreamActionType.fetchError:
            return {
                ...state,
                isLoading: false
            }
        case StreamActionType.fetchSuccess:
            return {
                ...state,
                data: action.streams,
                isLoading: false,
                initiallyLoaded: true
            }
        case StreamActionType.createSuccess: 
            return {
                ...state,
                data: [
                    action.stream,
                    ...state.data
                ]
            }
        case StreamActionType.modifySuccess: 
        case StreamActionType.fetchSingleSuccess:
            const found = state.data.find(s => s.id === action.stream.id)
            return {
                ...state,
                data: found ? state.data.map(stream => {
                    if(stream.id === action.stream.id) {
                        return action.stream
                    } else {
                        return stream
                    }
                }) : [action.stream, ...state.data]
            }
      default:
        return state
    }
  }

export default reducer