import * as React from 'react'

import { Icon, Form } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

export const NoImageSelected: React.SFC = () => {
    return <Form.Field className="no-image-selected">
        <span>
            <Icon name="picture" color="grey"/>
            <FormattedMessage id="label.noImageSelected" />
        </span>
    </Form.Field>
}