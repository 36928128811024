import * as React from 'react'

import { Header, Segment, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

export const NoImagesPlaceholder: React.SFC = () => {
    return (
    <Segment placeholder >
        <Header icon style={{color: "darkgrey"}}>
            <Icon name='hdd outline' />
            <FormattedMessage id="placeholder.noDataFound" />
        </Header>
    </Segment>
    )
}