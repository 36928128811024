import React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers';
import { UserAuth, UserType } from '../../api/usersAuth';
import { isArray } from 'util';

type Props = {
    auth: UserAuth | null
    showOnlyTo: UserType | UserType[]
    children?: React.ReactNode
}

const UserAccessCondition: React.SFC<Props> = ({children, auth, showOnlyTo }) => {
    return (auth === null || (isArray(showOnlyTo) ? showOnlyTo.indexOf(auth.type) === -1 : auth.type !== showOnlyTo)) ? null : <>
    {children}
    </>
}

export default connect(({auth}: ApplicationState) => {
    return { auth: auth.data}
})(UserAccessCondition)