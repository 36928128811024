import * as React from 'react'
import { Divider, Icon, Card, Header, Segment, Image, Label } from 'semantic-ui-react'
import { FormattedMessage as FM } from 'react-intl'
import { LoadingIndicator } from '../common/LoadingIndicator';
import UserAccessCondition from '../auth/UserAccessCondition';
import { UserType } from '../../api/usersAuth';
import Alert from 'react-s-alert';
import { VodUploadInput } from './VodUploadInput';
import { deleteVOD, Vod } from '../../api/vods';
import { ResourceAuthorInfo } from '../common/ResourceAuthorInfo';
import { bytesToHumanReadable, formatDurationSeconds } from '../../utils/units';
import { ConfirmActionButton } from '../common/ConfirmActionButton';
import moment from 'moment';
import { VideoStream } from '../../api/vods';

type Props = {
    isLoading: boolean
    vods: Vod[]
    fetchVodsAction: () => Promise<Vod[]>
    vodAddedAction: (vod: Vod) => void
}

export class VodList extends React.Component<Props, {}> {
    async componentDidMount() {
        try {
            await this.props.fetchVodsAction()
        } catch(error) {
            Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
        }
    }

    render() {
        const { isLoading, vods } = this.props
        return <>
            <UserAccessCondition showOnlyTo={UserType.Admin}>
                <>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <VodUploadInput onUploadComplete={(vod) => this.props.vodAddedAction(vod)}/>
                    </div>
                    <Divider hidden fitted />
                </>
            </UserAccessCondition>
            {isLoading && <LoadingIndicator />}
            {(!isLoading && vods.length === 0) && <Segment placeholder>
                <Header icon style={{color: "darkgrey"}}>
                    <Icon name='hdd outline' />
                    <FM id="placeholder.noDataFound" />
                </Header>
            </Segment>}
            {(!isLoading && vods.length > 0) && <>
                <Card.Group>
                    {vods.map((vod, index) => {
                    return <Card key={index} fluid>
                            <Card.Content>
                                <a href={`/api/vod/${vod.id}/thumbnail`} target="_blank" rel="noopener noreferrer">
                                    <Image floated="right" src={`/api/vod/${vod.id}/thumbnail`} style={{maxHeight: 82, width: "auto"}}/>
                                </a>
                                <Card.Header>{vod.name}</Card.Header>
                                <Card.Meta>
                                <ResourceAuthorInfo values={vod} />
                                <small>
                                    <FM id="label.vodSize" />: {bytesToHumanReadable(vod.fileSizeBytes)}
                                </small>
                                </Card.Meta>
                                <Card.Meta>
                                    <Label size="mini"><FM id="label.duration" />: {formatDurationSeconds(vod.metadata.durationSeconds)}</Label>
                                    {(() => {
                                        // FIXME what about multiple streams?
                                        const video = vod.metadata.streams.find(s => s.type === 'video') as VideoStream;
                                        return video ? <><Label size="mini">
                                            <FM id="label.resolution" />: {video.width}x{video.height}
                                            </Label>
                                            <Label size="mini">
                                            <FM id="label.videoCodec" />: {video.codecName}
                                            </Label>
                                            </>: null
                                    })()}
                                    {!!vod.metadata.streams.find(s => s.type === 'audio') && <Label size="mini" basic>
                                        <Icon name="sound" />
                                    </Label>}
                                    <Label size="mini"><FM id="label.bitrate" />: {bytesToHumanReadable(vod.metadata.bitrate)}it/s</Label>
                                    <Label size="mini"><FM id="label.videoContainerFormat" />: {vod.metadata.formatNameLong}</Label>
                                    <Label size="mini"><FM id="label.creationTime" />: {moment(vod.metadata.creationTime).format("LLL")}</Label>
                                </Card.Meta>
                                <Card.Description style={{clear: "none"}} className="camera-actions">
                                    <ConfirmActionButton
                                        negative
                                        size="mini"
                                        actionButtonContent={
                                            <>
                                            <Icon name="trash" color="red" />
                                            <span className="mobile-hide">
                                                <FM id="action.delete" />
                                            </span>
                                            </>
                                        }
                                        onConfirm={async () => {
                                            try {
                                                await deleteVOD(vod.id);
                                                this.props.fetchVodsAction();
                                            } catch(error) {
                                                if (error?.response?.status === 409) {
                                                    Alert.warning(<FM id="message.cannotDeleteVODUsedInPlayerConfiguration" />);
                                                } else {
                                                    Alert.error(<FM id="message.systemErrorActionFailed" />);
                                                }
                                            }
                                        }}
                                        modalHeader={<FM id="message.confimVODDelete" />}
                                    >
                                    </ConfirmActionButton>
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    })}
                </Card.Group>
                <Divider hidden />
            </>
            }
        </>
    }
}