import React, { useState } from 'react'
import { Button, Confirm, ModalContent, ModalHeader, SemanticFLOATS, SemanticSIZES } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

type ConfirmActionButtonProps = {
    onConfirm: () => any
    onCancel?: () => void
    modalHeader: React.ReactNode
    modalContent?: React.ReactNode
    actionButtonContent: React.ReactNode
    floated?: SemanticFLOATS
    cancelButtonContent?: React.ReactNode
    confirmButtonContent?: React.ReactNode
    size?: SemanticSIZES
    negative?: boolean
    positive?: boolean
    icon?: boolean
    confirmText?: string
}

export const ConfirmActionButton: React.SFC<ConfirmActionButtonProps> = ({icon, size = "mini", onConfirm, confirmText, negative=true, onCancel, actionButtonContent, floated, modalContent, modalHeader}) => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleConfirm = async () => {
      setOpen(false)
      setLoading(true)
      try { 
        await onConfirm()
      }catch(_) {}
      setLoading(false)
    }
    const handleCancel = () => {
      setOpen(false)
      if(onCancel) onCancel()
    }
    return <>
      <Button basic icon={icon} type="button" size={size} negative={negative} onClick={() => setOpen(true)} floated={floated} loading={loading}>
        {actionButtonContent}
      </Button>
      <Confirm
        cancelButton={() => <Button onClick={handleCancel}>
          <FormattedMessage id="action.cancel"/>
        </Button>
        }
        confirmButton={() => <Button negative={negative} primary={!negative} onClick={handleConfirm}>
        <FormattedMessage id={confirmText || "action.delete"}/>
        </Button>
        }
        open={open}
        content={modalContent ? <ModalContent>
          {modalContent}
        </ModalContent> : <></>}
        header={<ModalHeader>
          {modalHeader}
        </ModalHeader>}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </>
  }