import { Camera } from "./players";
import { UserAuth } from "./usersAuth";
import Axios from "axios";

export enum StateChangeSource {
    Forced = 'forced',
    Scheduled = 'scheduled'
}

export type ScheduledBroadcast = {
    id: string
    player: Camera
    operator?: UserAuth
    recordStream: boolean
    startTime: Date
    disablePlayerOnEnd: boolean
    endTime: Date
    state: string
    stateChangeSource?: StateChangeSource
    showScheduleInPlayer: boolean
}

export async function fetchBroadcasts({ playerId, history }: { playerId?: string, history?: boolean } = {}) {
    const response = await Axios.get<ScheduledBroadcast[]>("/api/scheduled-broadcast", {
        params: {
            playerId,
            history
        }
    })
    return response.data
}