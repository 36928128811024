import * as React from 'react';
import { Header, Icon, Menu } from 'semantic-ui-react';
import { FormattedMessage as FM, FormattedMessage } from 'react-intl';
import { NavLink, Switch, Route } from 'react-router-dom';
import CameraListComponent from './players/CameraListComponent';
import StreamsListComponent from './streams/StreamsListComponent';
import { MediaList } from './media/MediaList'
import VodListComponent from './vod/VodListComponent';
import UserAccessCondition from './auth/UserAccessCondition';
import { UserType } from '../api/usersAuth';

export const ConfigurationComponent = () => {
    return <>
    <Header>
        <FM id="title.configuration" />
    </Header>
    <Menu>
        <Menu.Item as={NavLink} to="/players" activeClassName="active" basic="true">
            <Icon name="tv" />
            <span className="mobile-hide"><FormattedMessage id="title.cameraManagement" /></span>
        </Menu.Item>
        <Menu.Item as={NavLink} to="/streams" activeClassName="active" basic="true">
            <Icon name="rss" />
            <span className="mobile-hide"><FormattedMessage id="title.streamManagement" /></span>
        </Menu.Item>
        <UserAccessCondition showOnlyTo={UserType.Admin}>
            <Menu.Item as={NavLink} to="/vod" activeClassName="active" basic="true">
                <Icon name="film" />
                <span className="mobile-hide"><FormattedMessage id="title.vodManagement" /></span>
            </Menu.Item>
        </UserAccessCondition>
        <Menu.Item as={NavLink} to="/media" activeClassName="active" basic="true">
            <Icon name="picture" />
            <span className="mobile-hide"><FormattedMessage id="title.imagesMangement" /></span>
        </Menu.Item>
    </Menu>
    <Switch>
        <Route exact path="/players" component={CameraListComponent} />
        <Route exact path="/media" component={MediaList} />
        <Route exact path="/streams" component={StreamsListComponent} />
        <Route exact path="/vod" component={VodListComponent} />
    </Switch>
    </>
}