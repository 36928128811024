import { fetchVods, Vod} from "../api/vods";
import { Dispatch } from "redux";

export enum VodActionType {
    fetchRequest = "webcam/vods/FETCH_REQUEST",
    fetchSuccess = "webcam/vods/FETCH_SUCCESS",
    fetchError = "webcam/vods/FETCH_ERROR",
    fetchSingleSuccess = "webcam/vods/FETCH_SINGLE_SUCCESS",
}

export const fetchVodsAction = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: VodActionType.fetchRequest
        })
        try {
            const vods = await fetchVods()
            dispatch({
                type: VodActionType.fetchSuccess,
                vods
            })
            return vods;
        } catch(err) {
            dispatch({
                type: VodActionType.fetchError
            })
            throw err
        }
    }
}

export const vodAddedAction = (vod: Vod) => {
    return async (dispatch: Dispatch) => {
            dispatch({
                type: VodActionType.fetchSingleSuccess,
                vod
            })
            return vod;
    }
}