import * as React from 'react'
import { Form } from 'semantic-ui-react';
import { Field } from 'formik';
import { textInput } from '../../../form/Inputs';

export const LinkEdit = ({index}: {index: number}) => {
    return <>
    <Form.Group widths='equal'>
    <Field
        name={`widgets[${index}].linkUrl`}
        component={textInput}
        label="widget.linkUrl"
    />
    <Field
        name={`widgets[${index}].linkTitle`}
        component={textInput}
        label="widget.linkTitle"
    />
    </Form.Group>
    </>
}
