import { LoginValues } from "./LoginForm";
import { FormikErrors } from "formik";

export const validate = (values: LoginValues) => {
    const errors: FormikErrors<LoginValues> = {}

    if(!values.username) {
        errors.username = "validation.requiredField"
    }

    if(!values.password) {
        errors.password = "validation.requiredField"
    }

    return errors
}