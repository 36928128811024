import React from 'react'
import { Icon, List } from 'semantic-ui-react';
import { FormattedMessage as FM } from 'react-intl';
import moment from 'moment'
import { LoadingIndicator } from '../common/LoadingIndicator';
import { ErrorMessage } from '../common/Error';
import { StreamStatusSubscriber } from './StreamStatusSubscriber';
import { bitsToMbits } from '../../utils/units';



export const StreamStatusComponent: React.SFC<{id: string}> = ({id}) => <StreamStatusSubscriber id={id} shortPolling>
    {(status, error) => {
        if (error) {
            return <ErrorMessage messageId="message.failedToFetchStreamStatus" error={error} />;
        }

        if(!status) {
            return <LoadingIndicator />
        }

        return <>
        <List.Item>
            <List.Header>
                <FM id="label.stream.status" />
            </List.Header>
            <span>
                {!!status.alive ? 
                <><FM id="label.stream.status.alive" /> <Icon name="check circle" color="green" /></> :
                <><FM id="label.stream.status.dead" /> <Icon name="exclamation circle" color="red" /></>}
            </span>
        </List.Item>
        {!!status.alive && <>
        <List.Item>
            <List.Header>
                <FM id="label.stream.uptime" />
            </List.Header>
            {moment().subtract(status.uptimeSeconds, "s").format('LLLL').toLocaleLowerCase()}
        </List.Item>
        <List.Item>
            <List.Header>
                <FM id="label.stream.totalConnections" />
            </List.Header>
            {status.connectionCount}
        </List.Item>
        <List.Item>
            <List.Header>
                <FM id="label.stream.bitrateIn" />
            </List.Header>
            {bitsToMbits(status.currentBitrateIn)} Mbit/s
        </List.Item>
        </>}
        </>
    }}
</StreamStatusSubscriber>