import { Reducer, AnyAction } from "redux";
import { UserAuthActionType } from "../actions/auth";
import { UserType } from "../api/usersAuth";

export type AuthState = {
    data: {
        username: string
        type: UserType
    } | null
    isLoading: boolean
    isInitiallyLoading: boolean
    serverError: boolean
    userError: boolean
    expired: boolean
    loggedOut: boolean
}

const initialState: AuthState = {
    data: null,
    isLoading: false,
    isInitiallyLoading: false,
    serverError: false,
    userError: false,
    expired: false,
    loggedOut: false
}

const reducer: Reducer<AuthState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
      case UserAuthActionType.initialLoginStart:
       return { ...initialState, isInitiallyLoading: true}
      case UserAuthActionType.loginStart:
        return { ...initialState, isLoading: true }
      case UserAuthActionType.loginSuccess:
      case UserAuthActionType.initialLoginSuccess:
        return {
            ...initialState,
            data: action.user,
            isLoading: false,
            userError: false,
            expired: false
        }
      case UserAuthActionType.logoutSuccess:
        return {
            ...initialState,
            loggedOut: true
        }
      case UserAuthActionType.invalidateAuth:
        return {
            ...initialState,
            // Can happen after user has logged out.
            // That is, session has not expired if user has clicked the logout button manually.
            expired: !state.loggedOut
        }
      case UserAuthActionType.loginUnauthorized:
        return {
            ...initialState,
            userError: true
        }
      case UserAuthActionType.loginError:
      case UserAuthActionType.initialLoginError:
        return {
            ...initialState,
            serverError: true
        }
      case UserAuthActionType.initialLoginUnauthorized:
        /**
         * As if nothing happened. User is not logged in.
         */
        return {
            ...initialState
        }
      default:
        return state
    }
  }

export default reducer