import { ImageUpload } from "../api/imageUploads";
import { ImageUploadsActionType } from "../actions/imageUploads";
import { Reducer, AnyAction } from "redux";


export type ImagesState = {
    data: ImageUpload[]
    isLoading: boolean
}

const initialState: ImagesState = {
    data: [],
    isLoading: false
}

const reducer: Reducer<ImagesState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case ImageUploadsActionType.fetchRequest:
            return {
                ...state,
                isLoading: true
            }
        case ImageUploadsActionType.fetchError:
            return {
                ...state,
                isLoading: false
            }
        case ImageUploadsActionType.fetchSuccess:
            return {
                ...state,
                data: action.images,
                isLoading: false
            }
        case ImageUploadsActionType.deleteSuccess:
            return {
                ...state,
                data: state.data.filter(i => i.id !== action.id)
            }
        case ImageUploadsActionType.uploadSuccess:
            return {
                ...state,
                data: [
                    action.image,
                    ...state.data
                ]
            }
      default:
        return state
    }
  }

export default reducer