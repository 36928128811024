import Axios from "axios"
import { Camera } from "./players"

export type Group = {
    id?: string
    name: string
    players: Camera[]
}

export const fetchGroups = async () => {
    const response = await Axios.get<Group[]>("/api/groups");
    return response.data
}

export const deleteGroup = async (id: string) => {
    return await Axios.delete(`/api/group/${id}`);
}

export const modifyGroup = async (values: Group) => {
    const response =  await Axios.put(`/api/group/${values.id}`, values)
    return response.data;
}

export const createGroup = async (values: Group) => {
    const response =  await Axios.post(`/api/group`, values)
    return response.data;
}

export async function getGroup(id: string) {
    const response = await Axios.get<Group>(`/api/group/${id}`)
    return response.data
}