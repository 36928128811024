import { Dispatch } from "redux";
import { User, createUser, modifyUser, getUser } from "../api/users";

export enum UserActionType {
    fetchRequestSingle = "webcam/user/FETCH_REQUEST_SINGLE",
    fetchSuccessSingle = "webcam/user/FETCH_SUCCESS_SINGLE",
    fetchErrorSingle = "webcam/user/FETCH_ERROR_SINGLE",
    createSuccess = "webcam/user/CREATE_SUCCESS",
    createError = "webcam/user/CREATE_ERROR",
    modifySuccess = "webcam/user/MODIFY_SUCCESS",
    modifyError = "webcam/user/MODIFY_ERROR"
}

export const fetchUserAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: UserActionType.fetchRequestSingle
        })
        try {
            const user = await getUser(id)
            dispatch({
                type: UserActionType.fetchSuccessSingle,
                user
            })
            return user
        } catch(err) {
            dispatch({
                type: UserActionType.fetchErrorSingle
            })
            throw err
        }
    }
}

export const createUserAction = (user: User) => {
    return async (dispatch: Dispatch) => {
        try {
            const userResponse = await createUser(user)
            dispatch({
                type: UserActionType.createSuccess,
                user: userResponse
            })
            return userResponse
        } catch(err) {
            dispatch({
                type:  UserActionType.createError
            })
            throw err
        }
    }
}

export const modifyUserAction = (user: User) => {
    return async (dispatch: Dispatch) => {
        try {
            const userResponse = await modifyUser(user)
            dispatch({
                type: UserActionType.modifySuccess,
                user: userResponse
            })
            return userResponse
        } catch(err) {
            dispatch({
                type:  UserActionType.modifyError
            })
            throw err
        }
    }
}