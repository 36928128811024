import * as React from 'react'
import { Divider, Button, Icon, Card } from 'semantic-ui-react'
import { FormattedMessage as FM } from 'react-intl'
import { Link } from 'react-router-dom'
import { Stream } from '../../api/streams';
import { LoadingIndicator } from '../common/LoadingIndicator';
import UserAccessCondition from '../auth/UserAccessCondition';
import { UserType } from '../../api/usersAuth';
import { NoStreamsPlaceholder } from './NoStreamsPlaceholder';
import { StreamItem } from './StreamItem';
import Alert from 'react-s-alert';

type Props = {
    isLoading: boolean
    streams: Stream[]
    fetchStreamsAction: () => Promise<Stream[]>
}

const NewStreamButton: React.SFC = () => {
    return <Button as={Link} to="/streams/new" color="green">
        <Icon name="rss" />
        <FM id="action.newStream" />
    </Button>
}

export class StreamsList extends React.Component<Props, {}> {
    async componentDidMount() {
        try {
            await this.props.fetchStreamsAction()
        } catch(error) {
            Alert.error(<FM id="message.systemErrorFailedToLoadData" />)
        }
        
    }
    render() {
        const { isLoading, streams } = this.props
        return <>
            <UserAccessCondition showOnlyTo={UserType.Admin}>
                <>
                    <NewStreamButton />
                    <Divider hidden fitted />
                </>
            </UserAccessCondition>
            {isLoading && <LoadingIndicator />}
            {(!isLoading && streams.length === 0) && <NoStreamsPlaceholder />}
            {(!isLoading && streams.length > 0) && <>
                <Card.Group>
                    {streams.map((stream, idx) => <StreamItem key={idx} stream={stream} />)}
                </Card.Group>
                <Divider hidden />
            </>
            }
        </>
    }
}