import { UserAuth } from "./usersAuth";
import Axios from "axios";

export enum UserAccountType {
	Personal = 1,
	Company = 2,
}

export type User = {
	userAuth: UserAuth
	/**
	accountType: UserAccountType,
	firstName: string,
	lastName: string,
	zipCode: string,
	street: string,
	city: string,
	country: string,
	companyName?: string,
	nip?: string
	*/
}

export const createUser = async (user: User) => {
	const response = await Axios.post<UserAuth>("/api/user", user.userAuth)
	return {
		userAuth: response.data
	} as User
}

export const getUser = async (id: string) => {
	const response = await Axios.get<UserAuth>(`/api/user/${id}`)
	return {
		userAuth: response.data
	} as User
}

export const deleteUser = async (id: string) => {
	return await Axios.delete(`/api/user/${id}`)
}

export const modifyUser = async (user: User) => {
	const response = await Axios.put<UserAuth>(`/api/user/${user.userAuth.id}`, user.userAuth)
	return {
		userAuth: response.data
	} as User
}
