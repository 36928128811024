import * as React from 'react'

import { Button, Modal, Icon } from 'semantic-ui-react';
import { ImageUpload } from '../../api/imageUploads';
import ImageListComponent from './ImageListComponent';
import { FormattedMessage } from 'react-intl';
import ImageUploadComponent from './ImageUploadComponent';

type Props = {
    onImageChoose: (image: ImageUpload) => void
}

type State = {
    open: boolean
}

export class ImageChooser extends React.Component<Props, State> {
    state: State = { open: false }

    close = () => this.setState({ open: false })

    onImageChoose = (image: ImageUpload) => {
        this.props.onImageChoose(image)
        this.close()
    }
  
    render() {
      const { open } = this.state
      return (
        <>
          <ImageUploadComponent asButton={true} onUploadComplete={this.props.onImageChoose} />
            <span style={{marginRight: '.25em'}}><FormattedMessage id="label.or" /></span>
           <Button type="button" size="mini" basic primary onClick={() => this.setState({open: true})}>
                <Icon name="picture" />
                <FormattedMessage id="action.selectImage" />
           </Button>
          <Modal
            open={open}
            onClose={this.close}
          >
            <Modal.Header><FormattedMessage id="action.selectImage" /></Modal.Header>
            <Modal.Content>
                <ImageListComponent onImageChoose={this.onImageChoose}/>
            </Modal.Content>
          </Modal>
        </>
      )
    }
  }