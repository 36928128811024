import { CameraList } from './CameraList'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers';
import { fetchPlayersAction } from '../../actions/players'

export default connect((state: ApplicationState) => ({
    isLoading: state.players.isLoading,
    cameras: state.players.data
}),
{ fetchPlayersAction})
(CameraList)