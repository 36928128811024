import { Dispatch } from "redux";
import { fetchCameras, Camera, createCamera, modifyCamera, deleteCamera, fetchPlayer } from "../api/players";
import Axios from "axios";

export enum PlayersActionType {
    fetchRequest = "webcam/players/FETCH_REQUEST",
    fetchSuccess = "webcam/players/FETCH_SUCCESS",
    fetchError = "webcam/players/FETCH_ERROR",
    fetchSingleSuccess = "webcam/players/FETCH_SINGLE_SUCCESS",
    createSuccess = "webcam/players/CREATE_SUCCESS",
    createError = "webcam/players/CREATE_ERROR",
    modifySuccess = "webcam/players/MODIFY_SUCCESS",
    modifyError = "webcam/players/MODIFY_ERROR",
    deleteSuccess = "webcam/players/DELETE_SUCCESS",
    deleteError = "webcam/players/DELETE_ERROR",
    enableSuccess = "webcam/players/ENABLE_SUCCESS",
    disableSuccess = "webcam/players/DISABLE_SUCCESS",
}

export const fetchPlayersAction = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: PlayersActionType.fetchRequest
        })
        try {
            const cameras = await fetchCameras()
            dispatch({
                type: PlayersActionType.fetchSuccess,
                cameras
            })
        } catch(err) {
            dispatch({
                type: PlayersActionType.fetchError
            })
            throw err
        }
    }
}

export const fetchPlayerAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const camera = await fetchPlayer(id)
            dispatch({
                type: PlayersActionType.fetchSingleSuccess,
                camera
            })
            return camera;
        } catch(err) {
            throw err
        }
    }
}

export const createCameraAction = (camera: Camera) => {
    return async (dispatch: Dispatch) => {
        try {
            const cameraResponse = await createCamera(camera)
            dispatch({
                type: PlayersActionType.createSuccess,
                camera: cameraResponse
            })
            return cameraResponse
        } catch(err) {
            dispatch({
                type: PlayersActionType.createError,
            })
            throw err
        }
    }
}

export const modifyCameraAction = (camera: Camera) => {
    return async (dispatch: Dispatch) => {
        try {
            const cameraResponse = await modifyCamera(camera)
            dispatch({
                type: PlayersActionType.modifySuccess,
                camera: cameraResponse
            })
            return cameraResponse
        } catch(err) {
            dispatch({
                type: PlayersActionType.modifyError,
            })
            throw err
        }
    }
}

export const deleteCameraAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            await deleteCamera(id)
            dispatch({
                type: PlayersActionType.deleteSuccess,
                id
            })
        } catch(err) {
            dispatch({
                type: PlayersActionType.deleteError,
            })
            throw err
        }
    }
}

export const stopPlayerAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const disableResponse = await Axios.put(`/api/player/${id}/disable`)
            dispatch({
                type: PlayersActionType.disableSuccess,
                id
            })
            return disableResponse.data
        } catch(error) {
            throw error
        }
    }
}

export const startPlayerAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const enableResponse = await Axios.put(`/api/player/${id}/enable`)
            dispatch({
                type: PlayersActionType.enableSuccess,
                id
            })
            return enableResponse.data
        } catch(error) {
            throw error
        }
    }
}