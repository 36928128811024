import { Dispatch } from "redux";
import { fetchGroups } from "../api/groups"

export enum GroupsActionType {
    fetchSuccess = "webcam/groups/FETCH_SUCCESS"
}

export const fetchGroupsAction = () => {
    return async (dispatch: Dispatch) => {
        try {
            const groups = await fetchGroups()
            dispatch({
                type: GroupsActionType.fetchSuccess,
                groups
            })
        } catch(err) {
            throw err
        }
    }
}