import * as React from 'react'

import { Icon, Button } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { FormattedMessage as FM } from "react-intl"
import { Camera } from "../../api/players"
import { buildPlayerUrl } from '../../configuration'
import { stopPlayerAction, startPlayerAction } from '../../actions/players'
import { connect } from 'react-redux'
import UserAccessCondition from '../auth/UserAccessCondition'
import { UserType } from '../../api/usersAuth'
import { ConfirmActionButton } from '../common/ConfirmActionButton'

import './PlayerActionButtons.css'

type ButtonName = "dashboard" | "schedule" | "preview"

type Props = {
    player: Camera,
    startPlayer: (id: string) => Promise<any>,
    stopPlayer: (id: string) => Promise<any>
    hideButtons?: ButtonName[]
    extraContent?: React.ReactNode
}

const CameraActionButtons: React.SFC<Props> = ({player, startPlayer, stopPlayer, hideButtons, extraContent}) => {
    function shouldDisplay(buttonName: ButtonName) {
        return !hideButtons || hideButtons.indexOf(buttonName) < 0
    }
    return <div className="camera-actions">
        {shouldDisplay("dashboard") && <Button to={`/players/${player.id}/dashboard`} as={Link} color="teal" size="mini">
            <Icon name="rocket" />
            <FM id="action.playerDashboard" />
        </Button>}
        <UserAccessCondition showOnlyTo={UserType.Admin}>
            <Button to={`/players/${player.id}`} as={Link} color="blue" size="mini">
                <Icon name="pencil" />
                <span className="mobile-hide">
                    <FM id="action.edit" />
                </span>
            </Button>
        </UserAccessCondition>
        {shouldDisplay("schedule") && <Button as={Link} to={`/schedules/new?playerId=${player.id}`} color="green" size="mini">
            <Icon name="clock outline" />
            <span className="mobile-hide">
                <FM id="action.newScheduledBroadcast" />
            </span>
        </Button>}
        {shouldDisplay("preview") &&
        <Button as="a" size="mini" href={buildPlayerUrl(`/${player.pathAlias || player.id}`)} target="_blank">
            <Icon name="tv" />
            <span className="mobile-hide">
                <FM id="label.cameraLink" />
            </span>
        </Button>}
        {player.enabled && <ConfirmActionButton
            onConfirm={async () => stopPlayer(player.id!!)}
            confirmText="action.turnOffPlayer"
            modalHeader={<FM id="message.turnOffPlayerHeader" />}
            actionButtonContent={<>
                <Icon name="pause circle" />
                <span className="mobile-hide">
                    <FM id="action.turnOffPlayer" />
                </span>
            </>}
            negative={false}
        />}
        {!player.enabled && <ConfirmActionButton
            onConfirm={async () => startPlayer(player.id!!)}
            confirmText="action.turnOnPlayer"
            modalHeader={<FM id="message.turnOnPlayerHeader" />}
            actionButtonContent={<>
                <Icon name="play circle" />
                <span className="mobile-hide">
                    <FM id="action.turnOnPlayer" />
                </span>
            </>}
            negative={false}
        />}
        {extraContent}
    </div>
}


export default connect(null, { stopPlayer: stopPlayerAction, startPlayer: startPlayerAction })(CameraActionButtons)