import * as React from 'react'

import { Header, Button, Segment, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export const NoCamerasPlaceholder: React.SFC<{cleanFilters?: () => void}> = ({cleanFilters}) => {
    return (
    <Segment placeholder >
        <Header icon style={{color: "darkgrey"}}>
            <Icon name='hdd outline' />
            <FormattedMessage id="placeholder.noDataFound" />
        </Header>
        {cleanFilters ? 
        <Button basic onClick={cleanFilters}>
            <Icon name="delete" />
            <FormattedMessage id="action.clearFilters" />
        </Button>
        :
        <Button as={Link} to="/players/new" color="green">
            <Icon name="plus" />
            <FormattedMessage id="action.newCamera" />
        </Button>}
    </Segment>
    )
}