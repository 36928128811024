import moment from 'moment'

export const bytesToHumanReadable = (bytes: number) => {
    if(bytes > 999999999) {
        const mbytes = Math.floor((bytes / 1024 / 1024 / 1024) * 10) / 10
        return `${mbytes} GB`
    } else if(bytes > 999999) {
        const mbytes = Math.floor((bytes / 1024 / 1024) * 10) / 10
        return `${mbytes} MB`
    } else if(bytes > 999) {
        const kbytes = Math.floor(bytes / 1024)
        return `${kbytes} kB`
    } else {
        return `${bytes} B`
    }
}

export const bitsToMbits = (bytes: number) => Math.round((bytes / 1000 / 1000) * 100) / 100

export function formatDurationSeconds(durationSeconds: number) {
    // FIXME this will not work if duration is >= 24h
    return moment.utc(moment.duration(durationSeconds, "seconds").asMilliseconds()).format("HH:mm:ss")
  }