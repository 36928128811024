import { VodList } from './VodList'
import { connect } from 'react-redux'
import { ApplicationState } from '../../reducers';
import { fetchVodsAction, vodAddedAction } from '../../actions/vods'

export default connect((state: ApplicationState) => ({
    isLoading: state.vods.isLoading,
    vods: state.vods.data
}),
{ fetchVodsAction, vodAddedAction })
(VodList)