import React from 'react'
import get from 'lodash/get'
import { Message, Icon, Button } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'

type Props = {
  error?: Error
  messageId: string
  onRetry?: () => any
}

export const ErrorMessage: React.SFC<Props> = ({error, messageId, onRetry}) => {
    const errorCode = get(error, 'response.data.code')
    const errorStatus = get(error, 'response.status')
    return <Message negative size="tiny">
        {onRetry && <Button size="mini" basic floated="right" onClick={onRetry}>
          <Icon name="refresh" />
          <FormattedMessage id="action.retryAfterError" />
        </Button>}
        <Message.Header>
          <Icon name="warning sign" />
          <FormattedMessage id={messageId} />
        </Message.Header>
        {(errorCode  || errorStatus) && <><FormattedMessage id="label.errorCode" />: {errorCode}{!!errorCode && "/"}{errorStatus} </>}

    </Message>
  }