import { fetchStreams, createStream, Stream, deleteStream, modifyStream, fetchStream } from "../api/streams";
import { Dispatch } from "redux";

export enum StreamActionType {
    fetchRequest = "webcam/streams/FETCH_REQUEST",
    fetchSuccess = "webcam/streams/FETCH_SUCCESS",
    fetchError = "webcam/streams/FETCH_ERROR",
    fetchSingleSuccess = "webcam/streams/FETCH_SINGLE_SUCCESS",
    createSuccess = "webcam/streams/CREATE_SUCCESS",
    createError = "webcam/streams/CREATE_ERROR",
    modifySuccess = "webcam/streams/MODIFY_SUCCESS",
    modifyError = "webcam/streams/MODIFY_ERROR",
    deleteSuccess = "webcam/streams/DELETE_SUCCESS",
    deleteError = "webcam/streams/DELETE_ERROR",
}

export const fetchStreamsAction = () => {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: StreamActionType.fetchRequest
        })
        try {
            const streams = await fetchStreams()
            dispatch({
                type: StreamActionType.fetchSuccess,
                streams
            })
            return streams
        } catch(err) {
            dispatch({
                type: StreamActionType.fetchError
            })
            throw err
        }
    }
}

export const fetchStreamAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const stream = await fetchStream(id)
            dispatch({
                type: StreamActionType.fetchSingleSuccess,
                stream
            })
            return stream
        } catch(err) {
            throw err
        }
    }
}

export const createStreamAction = (stream: Stream) => {
    return async (dispatch: Dispatch) => {
        try {
            const streamResponse = await createStream(stream)
            dispatch({
                type: StreamActionType.createSuccess,
                stream: streamResponse
            })
            return streamResponse
        } catch(err) {
            dispatch({
                type:  StreamActionType.createError
            })
            throw err
        }
    }
}

export const modifyStreamAction = (stream: Stream) => {
    return async (dispatch: Dispatch) => {
        try {
            const streamResponse = await modifyStream(stream)
            dispatch({
                type: StreamActionType.modifySuccess,
                stream: streamResponse
            })
            return streamResponse
        } catch(err) {
            dispatch({
                type:  StreamActionType.modifyError
            })
            throw err
        }
    }
}


export const deleteStreamAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            await deleteStream(id)
            dispatch({
                type: StreamActionType.deleteSuccess,
                id
            })
        } catch(err) {
            dispatch({
                type: StreamActionType.deleteError,
            })
            throw err
        }
    }
}