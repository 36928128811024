import React from 'react'
import { Progress, Message, Icon } from 'semantic-ui-react';
import { FormattedMessage as FM} from 'react-intl';
import { bytesToHumanReadable } from '../../utils/units';

export const QuotaUsedDisplay: React.SFC<{used: number, available: number, recordingInProgress: boolean}> = ({used, available, recordingInProgress}) => {
    const usedPercent = used / available * 100;
    const warning = usedPercent >= 80;
    const error = usedPercent >= 95;
    const success = usedPercent < 55;
    return <>
      <Progress
        progress="percent"
        precision={0}
        warning={warning}
        error={error}
        success={success}
        content={<><FM id="label.quotaUsed" />: {bytesToHumanReadable(used)} / {bytesToHumanReadable(available)}</>}
        value={used}
        total={available}
      />
      {(warning && recordingInProgress) && <Message size="tiny" warning >
        <Message.Header>
          <Icon name="exclamation triangle" /> <FM id="message.recordingWillBeStoppedWhenQuotaExceeded" />
        </Message.Header>
      </Message>}
    </>
}